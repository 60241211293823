import React from "react";
import { useEffect, useState,useCallback } from "react";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Variables } from "../../../Variations/Variations";
import { PaginatedList } from "react-paginated-list";
import Dropzone from "react-dropzone";

function ProductImage(props) {
  const [Media, setMedia] = useState([]);
  const [show, setShow] = useState(false);
  const [filteredData, setFilteredData] = useState(Media);


  function handleShow(breakpoint) {
    setShow(true);
  }

  function handleImage(id,image) {
 
  props.setProductImage(id)
  props.setShowingProductImage(image)
  setShow(false)
  }



  const handleDrop = async (files) => {
    debugger
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          try {
            const res = await axios.post(
              Variables.API_URL + "UploadMedia/UploadImageToServer",
              formData
            );
            if (res.data !== "") {
              await fetch(Variables.API_URL + "UploadMedia/InsertMedia", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  image: res.data,
                  imageTitle: files[i].name.split(".").slice(0, -1).join("."),
                  altText: "",
                  description: "",
                  caption: "",
                }),
              }).then((Response) => {
                if (Response.ok) {
                  fetchMedia();
                } else {
                  toast.error(Response);
                }
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = Media.filter((child) =>
    child.ImageTitle.toLowerCase().includes(value)
  );
    setFilteredData(result);
  };
  function fetchMedia() {
    fetch(Variables.API_URL + "UploadMedia/GetMedia", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setMedia(Result);
        setFilteredData(Result)
      });
  }
  useEffect(() => {
    fetchMedia();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <b> Product Image</b>
      </div>

      <Button
        className="me-2 btn btn-secondary btn-sm"
        onClick={() => handleShow(true)}
      >
       Add Product Images
      </Button>
     

      <Modal show={show}  onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add images to product gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="Library"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Upload" title="Upload File">
              <div className="row mt-5">
                <div className="row">
                  <Dropzone
                    onDrop={handleDrop}
                    accept="image/jpeg,image/png,image/jpg,image/svg"
                    style={{ height: 500 }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p style={{ height: 500 }}>
                          Drag'n'drop images, or click to select files
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <br />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Library" title="Media Library">
            <div className="col-12">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-3">
                      <input
                        type="search"
                        className="form-control"
                        onChange={(e) => handleSearch(e)}
                        placeholder={"Search media by name"}
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
                  <div className="col-12">
                <div className="row">
                    <PaginatedList
                      list={filteredData}
                      itemsPerPage={18}
                      displayRange={2}
                      showPrev={false}
                      showNext={false}
                      renderList={(list) => (
                        <>
                            <div className="row">
                          {list.map((image, id) => {
                            return (
                              <div className="col-2" key={image.MediaId}>
                              <div className="imagecontainer">
                                 <div className="form-check">
         <button className="btn btn-light" onClick={()=>handleImage(image.MediaId,image.Image)}>
         
         
           <img
                                     alt="media"
                                     className="form-control"
                                     src={Variables.API_PUBLISHED + image.Image}
                                   />
           </button>
         </div>
                                 </div>
                             </div>
                            );
                          })}
                          </div>
                        </>
                      )}
                    />
                  </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductImage;