import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import { Variables } from "../../Variations/Variations";
import { Link, useNavigate } from "react-router-dom";
import CustomSnipper from "../../CustomSnipper";
import {
  MdManageSearch,
} from "react-icons/md";
import { AiOutlineRight, } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
function AuditOrderLogs() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [orders, setOrders] = useState([]);
  const [orderStatus, SetOrderStatus] = useState([]);
  const [ordersLoader, setOrdersLoader] = useState(true);
  const [orderDetailsModal, setOrderDetailsModal] = useState(0);
  const [orderToUpdate, setOrderToUpdate] = useState({
    OrderId: 0,
    OrderStatusId: 0,
  });
  const [state, setState] = useState(false);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [reason, setReason] = useState("");
  const [search, setSearch] = useState("");
  const [isOpenSearchField, setIsOpenSearchField] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [countryName, setCountryName] = useState("");
  const [hide, setHide] = useState(true);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  // Get orders
  const GetOrders = async () => {
    let offset = new Date().getTimezoneOffset();
    let response = await fetch(
      Variables.API_URL + "AuditOrderLog/GetAuditLogs/" + offset,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
    
      result.forEach((element) => {
        if (element.AuditOrderStatusId == 1) {
          setHide(false);
            let OrderId=GetOrderByOrderNumber(element.OrderNumber);
            OrderId.then((value)=>{
                element.OrderId=value;
            })
         
        }else{
            element.OrderId=0;
        }

      });
      setTimeout(() => {
        setOrders(result);
      setFilteredOrder(result);
      setOrdersLoader(false);
      }, 500);
     
    }
  };
  const GetOrderByOrderNumber = async (ordernumber) => {
    let response = await fetch(
      Variables.API_URL + "AuditOrderLog/GetOrderByOrderNumber/" + ordernumber,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
          
     return parseInt(result);
   
    }
    else{
        return 0;
    }
  };

  const columns = [
    {
      name: "#Order Number",
      selector: (row) => row.OrderNumber,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.FirstName + " " + row.LastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) =>
        Util.getSeconds(row.OrderDate) < 60
          ? "Just now"
          : Util.getMinutes(row.OrderDate) < 60
          ? parseInt(Util.getMinutes(row.OrderDate)) === 1
            ? Util.getMinutes(row.OrderDate) + " minute ago"
            : Util.getMinutes(row.OrderDate) + " minutes ago"
          : Util.getHours(row.OrderDate) < 12
          ? parseInt(Util.getHours(row.OrderDate)) === 1
            ? Util.getHours(row.OrderDate) + " hour ago"
            : Util.getHours(row.OrderDate) + " hours ago"
          : row.OrderDate.toString().substring(3, 10),
      sortable: true,
    },
    {
      name: "Total Without Shipping",
      selector: (row) => row.OrderTotalWithoutShipping,
      sortable: true,
    },
    {
      name: "Shipping Rate",
      selector: (row) => row.ShippingRate,
      sortable: true,
    },
    {
      name: "Status ",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Status Message",
      selector: (row) => row.Reason,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {row.AuditOrderStatusId == 1 && (
            <Link
              style={{ marginLeft: "50px" }}
              className="btn btn-outline-secondary btn-sm text-right "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              to={"/Orders/Order/"+row.OrderId}
              data-original-title="Edit"
            >
              <BiEdit />
            </Link>
          )}
        </div>
      ),
      omit: hide,
    },
  ];

  const handleSearch = (event) => {
    var value = event.target.value;
    let result = [];

    if (value === "") {
      result = orders;
    } else {
      result = orders.filter((child) =>
        String(child.OrderNumber).includes(String(value))
      );
    }

    setFilteredOrder(result);
  };

  useEffect(() => {
    GetOrders();
  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12">
            <h2>Orders</h2>
            <ul className="portalPath">
              <li>
                Home <AiOutlineRight />
              </li>
              <li>Orders</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body px-3">
                <div className="row mb-3">
                  <div className="col-8"></div>
                  <div className="col-4 text-end tblSearchBar position-relative">
                    <span>
                      <MdManageSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search By Order Number"
                      className="form-control form-control-sm"
                      onChange={(e) => handleSearch(e)}
                    ></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    <div className="bd-example">
                      {ordersLoader === true ? (
                        <CustomSnipper />
                      ) : (
                        <div className="portalTable">
                          <DataTable
                            columns={columns}
                            data={filteredOrder}
                            pagination
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default AuditOrderLogs;
