import { useEffect, useState ,React} from "react";
import { Variables } from "../../../Variations/Variations";


const ProductBrands = (props) => {
    
    // Get Brands
    const GetBrands = () => {
        const URL = Variables.API_URL + "Brand/GetBrands";
        fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((resp) => resp.json())
            .then((result) => {
                if (result !== null) {
                    setTimeout(() => {
                        props.setBrands(result);
                    }, 200);
                    GetAssignedBrands();
                }
            });
    };

    //Get Assigned Brands
    const GetAssignedBrands = () => {
        const URL =
            Variables.API_URL + "AssignBrandToProduct/GetAssignedBrands/" + props.productID;
        fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((resp) => resp.json())
            .then((result) => {
                props.setAssignedBrands(result);
            });
    };

    // Handle Brands
    function handleBrands(e, id, index) {
        var updatedList = [...props.assignedBrands];
        if (e.target.checked) {
            updatedList = [
                ...props.assignedBrands,
                { AssignBrandToProductID: 0, BrandId: id, ProductID: 0 },
            ];
        } else {
            updatedList = updatedList.filter((item) => item.BrandId != id);
        }
        props.setAssignedBrands(updatedList);
    }

    useEffect(() => {
        GetBrands();
    }, [])
    return (
        <>
            <div className="card-body productBrands">
                <div className="card-items">
                    {props.brands.map((brand, i) => (
                        <div key={brand.BrandId}>
                            <input
                                type="checkbox"
                                id={brand.BrandId}
                                className="mr-3"
                                name={brand.Brand1}
                                value={brand.BrandId}
                                onChange={(e) =>
                                    handleBrands(e, brand.BrandId, i)
                                }
                                checked={
                                    props.assignedBrands.filter(
                                        (value) => value.BrandId === brand.BrandId
                                    ).length !== 0
                                        ? props.assignedBrands.filter(
                                            (value) => value.BrandId === brand.BrandId
                                        )[0].BrandId
                                        : 0
                                }
                            />
                            <label className="ml-3" htmlFor={brand.Brand1}>
                                {brand.Brand1}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default ProductBrands;