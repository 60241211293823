
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Variables } from "../../Variations/Variations";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageSenders = () => {
   const[name,setName]=useState("")
   const[companyName,setCompanyName]=useState("")
   const[countryCode,setCountryCode]=useState("")
   const[address,setAddress]=useState("")
   const[city,setCity]=useState("")
   const[postCode,setPostCode]=useState("")
   const[phone,setPhone]=useState("")
   const navigate=useNavigate()
    let {SenderID}=useParams()
        function IsValid() {
        if (name === "") {
            toast.error("Sender Name Required");
            document.getElementById("name").focus();
            return false;
        }
        return true
    }





function HandleSubmit(e) {
    //debugger;
    e.preventDefault();
    if (IsValid() == true) {
        if(SenderID == 0){
        const URL = Variables.API_URL + "ShipmentSender/AddSender";
        fetch(URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                SenderName:name,
                SenderCompanyName:companyName,
                SenderCountryCode:countryCode,
                SenderAdd1:address,
                SenderAddCity:city,
                SenderAddPostcode:postCode,
                SenderPhone:phone

            })
        }).then(response => {
            if (response.ok) {
                navigate('/Senders');
                
            }
            else {
                toast.error(response);
            }
        })
    }
    else{

        const URL = Variables.API_URL + "ShipmentSender/UpdateSender/"+SenderID;
        fetch(URL, {
            method: 'Put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                SenderName:name,
                SenderCompanyName:companyName,
                SenderCountryCode:countryCode,
                SenderAdd1:address,
                SenderAddCity:city,
                SenderAddPostcode:postCode,
                SenderPhone:phone
            })
        }).then(response => {
            if (response.ok) {
                navigate('/Senders');
                
            }
            else {
                toast.error(response);
            }
        })

    }
}
}
async function GetShipmentSender(id) {
    const URL = Variables.API_URL + "ShipmentSender/GetShipmentSender/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setName(listItems.SenderName)
    setCompanyName(listItems.SenderCompanyName)
    setCountryCode(listItems.SenderCountryCode)
   setAddress(listItems.SenderAdd1)
   setCity(listItems.SenderAddCity)
   setPostCode(listItems.SenderAddPostcode)
   setPhone(listItems.SenderPhone)
}


useEffect(() => {
    if (SenderID != 0) {
        GetShipmentSender(SenderID);
    }
}, [])

  return (
    <div className="rightSide">
  <div className="container-fluid mt-3">
  <div className="row">
      <div className="col-12">
          {/* <form> */}
          <div className="card">
              <div className="card-header">
                  <div className="row">
                      <div className="col-lg-8">
                          <h3>{SenderID == '0' ? 'Add' : 'Update'} Sender</h3>
                      </div>
                      <div className="col-lg-4 text-right">
                          <NavLink to='/Senders' className='btn btn-secondary'>Cancel</NavLink>
                          <button type="submit" className="btn btn-primary ml-5" onClick={HandleSubmit}>Save</button>
                      </div>

                  </div>
              </div>
              <div className="card-body">
                  <div className="row">
                      <div className="col-lg-9">
                          <div className="row">
                              <div className="col-lg-6">
                                  <label> Name <span className="required">*</span></label>
                                  <input className="form-control" type="text" id="name" onChange={(e)=>setName(e.target.value)} value={name}/>
                              </div>
                              <div className="col-lg-6">
                                  <label>Company Name </label>
                                  <input className="form-control" type="text"  onChange={(e)=>setCompanyName(e.target.value)} value={companyName}/>
                              </div>
                              <div className="col-lg-6 mt-3">
                                  <label>Country Code</label>
                                  <input className="form-control" type="text"  onChange={(e)=>setCountryCode(e.target.value)} value={countryCode}/>
                              </div>
                              <div className="col-lg-6 mt-3">
                                  <label>Address</label>
                                  <input className="form-control" type="text"  onChange={(e)=>setAddress(e.target.value)} value={address}/>
                              </div>
                              <div className="col-lg-6 mt-3">
                                  <label>City</label>
                                  <input className="form-control" type="text"  onChange={(e)=>setCity(e.target.value)} value={city}/>
                              </div>
                              <div className="col-lg-6 mt-3">
                                  <label>Postal Code</label>
                                  <input className="form-control" type="text"  onChange={(e)=>setPostCode(e.target.value)} value={postCode}/>
                              </div>
                              <div className="col-lg-6 mt-3">
                                  <label>Phone Number</label>
                                  <input className="form-control" type="text"  onChange={(e)=>setPhone(e.target.value)} value={phone}/>
                              </div>
                            
                          </div>
                      </div>
                  
                  </div>
              </div>
          </div>
      </div>
      </div>
      </div>
      </div>
  )
};

export default ManageSenders;
