import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Link, NavLink } from "react-router-dom"
import { Variables } from "../../../Variations/Variations";
import { BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../../Classes/Util";
import { AiOutlineRight } from "react-icons/ai";
const ListRoles = () => {
    const [roles, setRoles] = useState();
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const columns = [
        {
            name: 'ID',
            selector: row => row.RoleId
        },
        {
            name: 'Role Name',
            selector: row => row.RoleName,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.EnableRole === true ? (<button className="btn btn-outline-secondary btn-sm" onClick={() => ToogleEnableRole(row.RoleId)}>{row.Enabled ? <BiShowAlt /> : <BiHide />} </button>) : null}
                {permissions.ManageRole === true ? (<NavLink to={"/Roles/" + row.RoleId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>) : null}

            </div>,
        }
    ];
    function ToogleEnableRole(id) {
        const URL = Variables.API_URL + "Role/ToogleEnableRole/" + id;
        fetch(URL, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.ok) {
                GetRoles();
                toast.success("Action Done")
            }
            else {
                toast.error("Error, please check with administrator");
            }
        });
    }

    async function GetRoles() {
        const URL = Variables.API_URL + "Role/GetRoles";
        const response = await fetch(URL)
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setRoles(listItems);
    }
    //#region Use Effect 
    useEffect(() => {
        GetRoles();
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>Roles</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li><Link to="/Settings">Settings <AiOutlineRight /></Link></li>
                            <li>Roles</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3 text-right">
                                    {permissions.ManageRole === true ? (<NavLink to='/Roles/0' className='btn btn-primary'>Add Role</NavLink>) : null}
                                </div>
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={roles}
                                        pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default ListRoles;