import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'
import { Button, Modal } from "react-bootstrap";

const ListCategory = () => {
  const [listCategories, setCategories] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [show, setShow] = useState(0)
  async function TooglePublishCategory(id) {
    let response = await fetch(
      Variables.API_URL + "Category/TooglePublishCategory/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetCategories();
    } else {
      toast.error(result);
    }
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.CategoryId,
      sortable: true,
    },
    {
      name: 'Category Name',
      selector: row => row.CategoryName,
      sortable: true,
    },
    {
      name: 'Order',
      selector: row => row.OrderNum,
      sortable: true,
    },
    {
      name: 'Published',
      selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
    },
    {
      name: 'Options',
      selector: row => <div>
        {permissions.PublishCategory === true ? (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishCategory(row.CategoryId)}>{row.IsPublished ? <BiShowAlt /> : <BiHide />} </button>) : null}
        {permissions.ManageCategory === true ? (<NavLink to={"/Category/" + row.CategoryId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>) : null}
        {permissions.DeleteCategory && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.CategoryId)}><BiTrash /></button>)}
        <Modal
          show={row.CategoryId === show}
          onHide={() => setShow(0)}
        >
          <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <p>
                  Are you sure you want to
                  <b> Delete</b> the Category named <b>{row.CategoryName}</b>?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2">
                  <Button
                    variant="primary"
                    className="form-control"
                    onClick={() => DeleteCategory(row.CategoryId)}
                  >
                    Yes
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    variant="secondary"
                    className="form-control"
                    onClick={() => setShow(0)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>,
    }
  ];

  const DeleteCategory = async (CategoryId) => {
    let response = await fetch(
      Variables.API_URL + "Category/DeleteCategory/" + CategoryId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetCategories();
    } else {
      toast.error("Please Check With Administrator");
    }
  };


  async function GetCategories() {
    const URL = Variables.API_URL + "Category/GetListCategories";
    const response = await fetch(URL)
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setCategories(listItems);
  }


  const ExpandedComponent = ({ data }) => <DataTable className="ml-3" columns={columns} data={data.SubCategory} expandableRows
    expandableRowsComponent={ExpandedComponent} noHeader noTableHead />;



  //#region Use Effect 
  useEffect(() => {
    GetCategories();
  }, [])
  //#endregion Use Effect 
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Categories</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Categories</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3>List Categories</h3>
                                </div>
                                <div className="col-4 text-right">
                                    {permissions.ManageCategory===true?(<NavLink to='/Category/0' className="btn btn-primary">New Category</NavLink>):null}
                                </div>
                            </div>

                        </div> */}
              <div className="card-body">
                <div className="mb-3 text-right">
                  {permissions.ManageCategory === true ? (<NavLink to='/Category/0' className="btn btn-primary">New Category</NavLink>) : null}
                </div>
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={listCategories}
                    pagination
                    expandableRows
                    expandableRowsComponent={ExpandedComponent} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick />
      </div>
    </div>
  )
}
export default ListCategory;