import React from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const ProductDetails = (props) => {
function handleDescription(data){
  props.setProductDescription(data);
var array=[...props.variations]
array.forEach((element,i) => {
  let variationToUpdate = array[i];
delete array[i];
variationToUpdate["Description"] = data;
array[i] = variationToUpdate;
});
props.setVariations(array);




}

  return (
    <>
          <div className="col-lg-12">
                      <label>
                        Product Name <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        id="txtProductName"
                        onChange={(e) => props.setProductName(e.target.value)}
                        value={props.productName}
                      />
                    </div>
                    <div className="col-lg-12 mt-3">
                      <label>Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={props.productDescription}
                        value={props.productDescription}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          
                          handleDescription(data);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 mt-3">
                      <label>Small Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={props.smallDescription}
                        value={props.smallDescription}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setSmallDescription(data);
                        }}
                      />
                    </div>  
    </>
  )
}

export default ProductDetails