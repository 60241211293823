import React, { useEffect, useState } from "react";
import Util from "../Classes/Util";
import Dashboard from "../Components/Dashboard";
import ListCategory from "../Components/Category/ListCategory";
import ManageCategory from "../Components/Category/ManageCategory";
import ListProducts from "../Components/Products/ListProducts";
import ManageProducts from "../Components/Products/ManageProducts";
import ListAttributes from "../Components/Attributes/ListAttributes";
import ManageAttributes from "../Components/Attributes/ManageAttributes";
import ManageAttributesValues from "../Components/Attributes/ManageAttributesValues";
import Settings from "../Components/Settings/Settings";
import ListUsers from "../Components/Settings/ListUsers";
import ManageUsers from "../Components/Settings/ManageUsers";
import ListMedia from "../Components/Media/ListMedia";
import AddMedia from "../Components/Media/AddMedia";
import NotFound from "../Components/NotFound";
import Brands from "../Components/Brands/ListBrands";
import ManageBrand from "../Components/Brands/ManageBrands";
import { Route, Routes } from "react-router-dom";
import ListTags from "../Components/Tags/ListTags";
import ManageTags from "../Components/Tags/ManageTags";
import ListCustomers from "../Components/Customers/ListCustomers";
import AllPages from "../Components/Pages/AllPages";
import ManagePages from "../Components/Pages/ManagePages";
import AllMenus from "../Components/Menus/AllMenus";
import ManageMenu from "../Components/Menus/ManageMenu";
import ListSliders from "../Components/Slider/ListSliders";
import ManageSlider from "../Components/Slider/ManageSlider";
import AllSlides from "../Components/Slides/AllSlides";
import ManageSlide from "../Components/Slides/ManageSlide";
import AllWidgets from "../Components/Widgets/AllWidgets";
import ManageWidget from "../Components/Widgets/ManageWidget";
import CustomerDetails from "../Components/Customers/CustomerDetails";
import ListRoles from "../Components/Settings/Roles/ListRoles";
import ManageRole from "../Components/Settings/Roles/ManageRole";
import Groups from "../Components/groups/Groups";
import ManageGroup from "../Components/groups/ManageGroup";
import ManageCoupons from "../Components/Coupon/ManageCoupons";
import Coupons from "../Components/Coupon/Coupons";
import CouponGroups from "../Components/Coupon/CouponGroups";
import ManageCouponGroup from "../Components/Coupon/ManageCouponGroup";
import ListOrder from "../Components/Orders/ListOrder";
import CartPriceRules from "../Components/Cart Price Rule/CartPriceRules";
import ManageCartPriceRule from "../Components/Cart Price Rule/ManageCartPriceRule";
import SendersList from "../Components/Shipment Sender Detail/SendersList";
import ManageSenders from "../Components/Shipment Sender Detail/ManageSenders";
import Materials from "../Components/Material/Materials";
import ManageMaterial from "../Components/Material/ManageMaterial";
import ManageSmtp from "../Components/SMTP/ManageSmtp";
import ListSizeChart from "../Components/SizeChart/ListSizeChart";
import ManageUniversalSizeChart from "../Components/SizeChart/ManageUniversalSizeChart";
import ListCategoriesForSizeChart from "../Components/SizeChart/ListCategoriesForSizeChart";
import Measurements from "../Components/Measurment/Measurements";
import ManageMeasurement from "../Components/Measurment/ManageMeasurement";
import ManageSizeChart from "../Components/SizeChart/ManageSizeChart";
import ListNotification from "../Components/Notification/ListNotification";
import Contents from "../Components/Content/Contents";
import ManageContent from "../Components/Content/ManageContent";
import Countries from "../Components/Country/Countries";
import ManageCountry from "../Components/Country/ManageCountry";
import ContactUs from "../Components/Notification/ContactUs";
import ListBrandsForSizeChart from "../Components/SizeChart/ListBrandsForSizeChart";
import AuditOrderLogs from "../Components/AuditOrderLog/AuditOrderLogs";
// import Login from "../Components/Login";
const AppRoutes = (props) => {
  const [permissions, setPermissions] = useState({
    AttributeValues: true,
    Attributes: true,
    Brands: true,
    Categories: true,
    Contents: true,
    Countries: true,
    CouponGroups: true,
    Coupons: true,
    CustomerDetails: true,
    Customers: true,
    Dashboard: true,
    DeleteAttribute: true,
    DeleteAttributeValue: true,
    DeleteBrand: true,
    DeleteCategory: true,
    DeleteContent: true,
    DeleteCoupon: true,
    DeleteCouponGroup: true,
    DeleteGroup: true,
    DeleteMaterial: true,
    DeleteMeasurement: true,
    DeleteMedia: true,
    DeleteMenu: true,
    DeleteNotification: true,
    DeletePage: true,
    DeletePriceRule: true,
    DeleteProduct: true,
    DeleteSizeChart: true,
    DeleteTag: true,
    DeleteUser: true,
    EnableRole: true,
    Groups: true,
    IncludeAttribute: true,
    IsEnabled: true,
    LockCustomer: true,
    LockUser: true,
    ManageAddressBook: true,
    ManageAttribute: true,
    ManageAttributeValue: true,
    ManageBrand: true,
    ManageCategory: true,
    ManageContent: true,
    ManageCountry: true,
    ManageCoupon: true,
    ManageCouponGroup: true,
    ManageGroup: true,
    ManageMaterial: true,
    ManageMeasurement: true,
    ManageMenu: true,
    ManageOrder: true,
    ManagePage: true,
    ManagePriceRule: true,
    ManageProduct: true,
    ManageRole: true,
    ManageSizeChart: true,
    ManageSlide: true,
    ManageSlider: true,
    ManageTag: true,
    ManageUser: true,
    ManageWidget: true,
    Materials: true,
    Measurements: true,
    Media: true,
    Menus: true,
    Notification: true,
    NotificationMarkAs: true,
    Orders: true,
    Pages: true,
    PriceRules: true,
    Products: true,
    PublishAttribute: true,
    PublishAttributeValue: true,
    PublishBrand: true,
    PublishCategory: true,
    PublishContent: true,
    PublishCountry: true,
    PublishCoupon: true,
    PublishCouponGroup: true,
    PublishGroup: true,
    PublishMaterial: true,
    PublishMeasurement: true,
    PublishMenu: true,
    PublishPage: true,
    PublishPriceRule: true,
    PublishProduct: true,
    PublishSizeChart: true,
    PublishSlide: true,
    PublishSlider: true,
    PublishTag: true,
    PublishWidget: true,
    ReadNotification: true,
    RestoreNotification: true,
    Roles: true,
    SetInStock: true,
    SetOnBackorder: true,
    SetOutOfStock: true,
    Settings: true,
    SizeCharts: true,
    Sliders: true,
    Slides: true,
    Smtp: true,
    StarNotification: true,
    Tags: true,
    UploadMedia: true,
    Users: true,
    Widgets: true,
  });
  useEffect(() => {
    setPermissions(Util.decrypt(localStorage.getItem("Permissions")));
  }, []);
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Dashboard />} />

      {permissions.Categories && (
        <Route path="Category" element={<ListCategory />} />
      )}

      {permissions.Categories && permissions.ManageCategory && (
        <Route path="Category/:CategoryID" element={<ManageCategory />} />
      )}

      {permissions.Products && (
        <Route path="Products" element={<ListProducts />} />
      )}
      {permissions.Products && permissions.ManageProduct && (
        <Route path="Products/:ProductID" element={<ManageProducts />} />
      )}

      {permissions.Customers && (
        <Route path="/Customers" element={<ListCustomers />} />
      )}
      {permissions.Brands && <Route path="Brand" element={<Brands />} />}
      {permissions.Brands && permissions.ManageBrand && (
        <Route path="Brand/:BrandID" element={<ManageBrand />} />
      )}
      {permissions.Attributes && (
        <Route path="Attributes" element={<ListAttributes />} />
      )}

      {permissions.Attributes && permissions.ManageAttribute && (
        <Route path="Attributes/:AttributeID" element={<ManageAttributes />} />
      )}
      {permissions.AttributeValues && (
        <Route
          path="Attributes/Value/:AttributeID"
          element={<ManageAttributesValues />}
        />
      )}
      {permissions.Settings && <Route path="Settings" element={<Settings />} />}
      {permissions.Users && <Route path="Users" element={<ListUsers />} />}
      {permissions.ManageUser && (
        <Route path="Users/:UserID" element={<ManageUsers />} />
      )}
      {permissions.Tags && <Route path="Tags" element={<ListTags />} />}
      {permissions.ManageTag && (
        <Route path="Tags/:TagsID" element={<ManageTags />} />
      )}
      {permissions.Media && <Route path="Media" element={<ListMedia />} />}
      {permissions.UploadMedia && (
        <Route path="Media/:MediaID" element={<AddMedia />} />
      )}

      {permissions.Pages && <Route path="Pages" element={<AllPages />} />}

      {permissions.ManagePage && (
        <Route path="Pages/:PageID" element={<ManagePages />} />
      )}
      {permissions.Menus && <Route path="Menus" element={<AllMenus />} />}
      {permissions.ManageMenu && (
        <Route path="Menus/:MenuID" element={<ManageMenu />} />
      )}
      {permissions.ManageCoupon && (
        <Route path="Coupons/:CouponID" element={<ManageCoupons />} />
      )}
      {permissions.Coupons && <Route path="/Coupons" element={<Coupons />} />}
      {permissions.Sliders && (
        <Route path="Sliders" element={<ListSliders />} />
      )}
      {permissions.Sliders && (
        <Route path="Sliders/:SliderID" element={<ManageSlider />} />
      )}
      {permissions.Slides && <Route path="Slides" element={<AllSlides />} />}
      {permissions.Slides && (
        <Route path="Slides/:SlideID" element={<ManageSlide />} />
      )}
      {permissions.Widgets && (
        <Route path="/Widgets" element={<AllWidgets />} />
      )}
      {permissions.ManageWidget && (
        <Route path="/Widgets/:WidgetID" element={<ManageWidget />} />
      )}
      {permissions.CustomerDetails && (
        <Route path="/ViewCustomer/:CustomerId" element={<CustomerDetails />} />
      )}
      {permissions.Roles && <Route path="/Roles" element={<ListRoles />} />}
      {permissions.ManageRole && (
        <Route path="/Roles/:RoleID" element={<ManageRole />} />
      )}
      {permissions.Groups && <Route path="/Groups" element={<Groups />} />}
      {permissions.ManageGroup && (
        <Route path="/Groups/:GroupID" element={<ManageGroup />} />
      )}
      {permissions.CouponGroups && (
        <Route path="/CouponGroups" element={<CouponGroups />} />
      )}
      {permissions.ManageCouponGroup && (
        <Route
          path="/CouponGroups/:CouponGroupID"
          element={<ManageCouponGroup />}
        />
      )}
      {permissions.Orders && <Route path="/Orders" element={<ListOrder />} />}
      {permissions.Orders && (
        <Route path="/Orders/Order/:OrderId" element={<ListOrder />} />
      )}
      {permissions.Orders && (
        <Route path="/OrderLogs" element={<AuditOrderLogs />} />
      )}

      {permissions.PriceRules && (
        <Route path="/PriceRules" element={<CartPriceRules />} />
      )}

      {permissions.ManagePriceRule && (
        <Route path="/PriceRules/:ID" element={<ManageCartPriceRule />} />
      )}

      {permissions.Materials && (
        <Route path="/Materials" element={<Materials />} />
      )}
      {permissions.ManageMaterial && (
        <Route path="/Materials/:MaterialID" element={<ManageMaterial />} />
      )}
      {permissions.Smtp && <Route path="/Smtps" element={<ManageSmtp />} />}
      {permissions.SizeCharts && (
        <Route exact path="/size-chart" element={<ListSizeChart />} />
      )}
      {permissions.SizeCharts && (
        <Route
          exact
          path="/universal-size-chart/category"
          element={<ListCategoriesForSizeChart />}
        />
      )}
      {permissions.SizeCharts && (
        <Route
          exact
          path="/size-chart/category"
          element={<ListCategoriesForSizeChart />}
        />
      )}
      {permissions.SizeCharts && (
        <Route
          exact
          path="/size-chart/brand"
          element={<ListBrandsForSizeChart />}
        />
      )}
      {permissions.ManageSizeChart && (
        <Route
          exact
          path="/size-chart/:sizechartid/brand/:brandid"
          element={<ManageSizeChart />}
        />
      )}

      {permissions.ManageSizeChart && (
        <Route
          exact
          path="/universal-size-chart/:sizechartid/category/:categoryid"
          element={<ManageUniversalSizeChart />}
        />
      )}
      {permissions.Measurements && (
        <Route path="/Measurements" element={<Measurements />} />
      )}
      {permissions.ManageMeasurement && (
        <Route
          path="/Measurements/:MeasurementID"
          element={<ManageMeasurement />}
        />
      )}
      {permissions.Notification && (
        <Route
          path="/notification"
          element={
            <ListNotification
              notifications={props.notifications}
              setNotifications={props.setNotifications}
            />
          }
        />
      )}
      {permissions.Contents && (
        <Route path="/Contents" element={<Contents />} />
      )}
      {permissions.ManageContent && (
        <Route path="/Contents/:ContentID" element={<ManageContent />} />
      )}
      {permissions.Countries && (
        <Route path="/Countries" element={<Countries />} />
      )}
      {permissions.ManageCountry && (
        <Route path="/Countries/:CountryCode" element={<ManageCountry />} />
      )}
      {
        permissions.Settings && (
          <Route path="/Senders" element={<SendersList />} />
        )
      }
          {
        permissions.Settings && (
          <Route path="/Senders/:SenderID" element={<ManageSenders />} />
        )
      }
          {
        permissions.Settings && (
          <Route
          path="/ContactUs"
          element={
            <ContactUs
              contactUs={props.contactUs}
              setContactUs={props.setContactUs}
            />
          }
        />
        )
      }
      
     

     
    </Routes>
  );
};
export default AppRoutes;
