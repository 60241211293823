import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { Variables } from "../Variations/Variations";
import { useNavigate } from "react-router-dom";



function ForgetPassword() {

  const [email, setEmail] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const navigate = useNavigate();

  //handle email change
  //handle email change
  const handleEmail = (e) => {
    const { name, value } = e.target;
    if (validator.isEmail(value)) {
      setEmailFormatError(false);
    }
    if (value === "") {
      setEmailRequiredError(true);
      setEmailFormatError(false);
    } else {
      setEmailRequiredError(false);
    }
    setEmail(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (email == null || email == "") {
      setEmailRequiredError(true);
      hasError = true;
    } else if (!validator.isEmail(email)) {
      setEmailFormatError(true);
      hasError = true;
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // submit button
  const Submit = async () => {
    var isValid = IsValid();
    if (isValid === true) {
      let response = await fetch(
        Variables.API_URL + "User/ForgetPassword",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Email: email,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
      else{
        toast.error(result, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
      }
    }
  };

  useEffect(() => {
  }, []);

  return (
    <div>

      <div className="container-fluid LoginBackground">
      <div className="row justify-content-center  align-items-center loginRow">
        <div className="col-12  col-md-4 loginBox ">
          <h1 className="text-center"> Forget Password </h1>    
          <div className="col-12 col-md-12 emounAya">
          <form onSubmit={(e)=>e.preventDefault()}> 
          <p className="text-muted mb-4 text-center">
                        A new password will be send to your email
                      </p>
                      <div className="mb-3">
                        <Input
                          id="Email"
                          name="Email"
                          placeholder="Email Address"
                          type="text"
                          className="form-control "
                          value={email}
                          onChange={handleEmail}
                        />
                        {emailFormatError && (
                          <p className="color-red text-center">
                            Invalid Email Address
                          </p>
                        )}
                        {emailRequiredError && (
                          <p className="color-red text-center">
                            Email Required
                          </p>
                        )}
                      </div>
                      <div className="d-grid gap-2 mt-2">
                        <Button
                         className="btn btn-primary d-block text-center text-uppercase mb-2 rounded-pill shadow-sm"

                          onClick={() => Submit()}
                        >
                          Submit
                        </Button>
                      </div>
                        </form>
            </div> 
        </div>
        
      </div>
    </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ForgetPassword;
