import React, { useState, useEffect } from "react";
import { Variables } from "../../Variations/Variations";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { AiOutlineRight } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const ManageUniversalSizeChart = () => {
  var CryptoJS = require("crypto-js");
  const [attribute, setAttribute] = useState("");
  const [attributeId, setAttributeId] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [sizes, setSizes] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [categories, setCategories] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [isAppliedToCategories, setIsAppliedToCategories] = useState(false);
  const [isAppliedToAttributeValues, setIsAppliedToAttributeValues] =
    useState(false);
  const [assignedCategories, setAssignedCategories] = useState([]);
  const [assignedAttributeValues, setAssignedAttributeValues] = useState([]);
  const [notes, setNotes] = useState("")
  const url = useParams();
  const navigate = useNavigate();
  const [sizingAndFitGuide,setSizingAndFitGuide]=useState("");

  const MeasurementChange = (e, sindex, mindex) => {

    let SizeID = sizes[sindex].AttributeValueId;
    let MeasurementID = measurements[mindex].MeasurementId;
    var newSelection = [...selectedSizes];
    const Index = newSelection.findIndex((chart) => {
      return (
        chart.SizeId === SizeID &&
        chart.MeasurementId === MeasurementID
      );
    });



    if (Index === -1) {
      newSelection.push({
        SizeId: SizeID,
        MeasurementId: MeasurementID,
        SizeChartValue: e.target.value,
      });
    } else {
      if (e.target.value !== "") {
        newSelection[Index].SizeId = SizeID;
        newSelection[Index].MeasurementId = MeasurementID;
        newSelection[Index].SizeChartValue = e.target.value;
      }
      else {
        newSelection.splice(Index, 1)
      }
    }
    setSelectedSizes(newSelection);
  };

  const InsertSizeChart = async () => {

    if (isAppliedToCategories && assignedCategories.length === 0) {
      toast.error("Categories must be Assigned");
    } else if (
      isAppliedToAttributeValues &&
      assignedAttributeValues.length === 0
    ) {
      toast.error("Attribute must be Assigned");
    } else if (
      selectedSizes.length < measurements.length
    ) {

      toast.error("Fill At least One Measurement Value");
    } else {
      var sizeCharts = [];
      var sizeChartValues = [];

      if (isAppliedToCategories && isAppliedToAttributeValues) {

        assignedAttributeValues.forEach((attribute) => {

          sizeCharts.push({
            CategoryId: parseInt(url.categoryid),
            AttributeValueId: attribute.value,
            IsAppliedToAttribute: true,

          });

          assignedCategories.forEach((category) => {
            sizeCharts.push({
              categoryId: category.value,
              AttributeValueId: attribute.value,
              IsAppliedToAttribute: true,

            });
          });
        });
      } else if (isAppliedToCategories && !isAppliedToAttributeValues) {
        sizeCharts.push({
          categoryId: parseInt(url.categoryid),
          AttributeValueId: 0,
          IsAppliedToAttribute: false,

        });

        assignedCategories.forEach((category) => {
          sizeCharts.push({
            CategoryId: category.value,
            AttributeValueId: 0,
            IsAppliedToAttribute: false,

          });
        });
      } else if (!isAppliedToCategories && isAppliedToAttributeValues) {
        assignedAttributeValues.forEach((attribute) => {
          sizeCharts.push({
            CategoryId: parseInt(url.categoryid),
            AttributeValueId: attribute.value,
            IsAppliedToAttribute: true,

          });
        });
      }
      else {
        sizeCharts.push({
          categoryId: parseInt(url.categoryid),
          AttributeValueId: 0,
          isAppliedToAttribute: false,

        });
      }

      selectedSizes.forEach((size) => {
        sizeChartValues.push({
          SizeId: size.SizeId,
          MeasurementId: parseInt(size.MeasurementId),
          SizeChartValue: size.SizeChartValue,
        });
      });

      let response = await fetch(
        Variables.API_URL + "SizeChart/UpdateUniversalSizeChart",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
          body: JSON.stringify({
            BrandId: 1,
            IsUniversal: true,
            SizeCharts: sizeCharts,
            SizechartValues: sizeChartValues,
            Notes: notes,
            SizingAndFitGuide:sizingAndFitGuide
          }),
        }
      );
      if (response.ok === false) {
        let result = await response.json();
        toast.error(result);
      } else {
        let result = await response.json();
        toast.success("Successfully added");
        navigate("/size-chart");
      }
    }
  };

  const UpdateSizeChart = async () => {

    if (isAppliedToCategories && assignedCategories.length === 0) {
      toast.error("Categories must be Assigned");
    } else if (
      isAppliedToAttributeValues &&
      assignedAttributeValues.length === 0
    ) {
      toast.error("Attribute must be Assigned");
    } else if (
      selectedSizes.length < measurements.length
    ) {

      toast.error("Fill At least One Measurement Value");
    } else {
      var sizeCharts = [];
      var sizeChartValues = [];

      if (isAppliedToCategories && isAppliedToAttributeValues) {
        assignedAttributeValues.forEach((attribute) => {
          sizeCharts.push({
            categoryId: parseInt(url.categoryid),
            AttributeValueId: attribute.value,
            isAppliedToAttribute: true,
          });

          assignedCategories.forEach((category) => {
            sizeCharts.push({
              categoryId: category.value,
              AttributeValueId: attribute.value,
              isAppliedToAttribute: true,
            });
          });
        });
      } else if (isAppliedToCategories && !isAppliedToAttributeValues) {
        sizeCharts.push({
          categoryId: parseInt(url.categoryid),
          AttributeValueId: 1,
          isAppliedToAttribute: false,
        });

        assignedCategories.forEach((category) => {
          sizeCharts.push({
            categoryId: category.value,
            AttributeValueId: 1,
            isAppliedToAttribute: false,
          });
        });
      } else if (!isAppliedToCategories && isAppliedToAttributeValues) {
        assignedAttributeValues.forEach((attribute) => {
          sizeCharts.push({
            categoryId: parseInt(url.categoryid),
            AttributeValueId: attribute.value,
            isAppliedToAttribute: true,
          });
        });
      } else {
        sizeCharts.push({
          categoryId: parseInt(url.categoryid),
          AttributeValueId: 1,
          isAppliedToAttribute: false,
        });
      }

      selectedSizes.forEach((size) => {
        sizeChartValues.push({
          SizeId: size.SizeId,
          MeasurementId: parseInt(size.MeasurementId),
          SizeChartValue: size.SizeChartValue,
        });
      });
      let response = await fetch(
        Variables.API_URL + "SizeChart/UpdateUniversalSizeChart",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
          body: JSON.stringify({
            BrandId: 1,
            IsUniversal: true,
            SizeCharts: sizeCharts,
            SizechartValues: sizeChartValues,
            Notes: notes,
            SizingAndFitGuide:sizingAndFitGuide
          }),
        }
      );
      if (response.ok === false) {
        response = await response.json();
        toast.error(response);
      } else {
        response = await response.json();
        toast.success("Successfully updated");
        navigate("/size-chart");
      }
    }
  };

  const GetSizeChart = async () => {
    let response, result;
    if (parseInt(url.sizechartid) !== 0) {
      response = await fetch(
        Variables.API_URL +
        "SizeChart/GetSizeChartDetailsById/" +
        url.sizechartid
      );
      result = await response.json();
      setAttribute(result.ValueName);
      setAttributeId(result.AttributeValueId);
      setSelectedSizes(result.SizeChartValues);
      setNotes(result.Notes);
      setSizingAndFitGuide(result.SizingAndFitGuide);

    }

    response = await fetch(
      Variables.API_URL + "Category/GetCategory/" + url.categoryid
    );
    result = await response.json();
    setCategoryName(result.CategoryName);

    response = await fetch(
      Variables.API_URL +
      "SizeChart/GetAssignedSizes/Category/" +
      url.categoryid
    );
    result = await response.json();
    setSizes(result);

    response = await fetch(
      Variables.API_URL +
      "SizeChart/GetAssignedMeasurements/Category/" +
      url.categoryid
    );
    result = await response.json();
    setMeasurements(result);

    response = await fetch(
      Variables.API_URL +
      "SizeChart/GetSameMeasurementCategories/Category/" +
      url.categoryid
    );
    result = await response.json();
    result = result.map(({ CategoryId: value, CategoryName: label }) => ({
      value,
      label,
    }));
    setCategories(result);

    response = await fetch(
      Variables.API_URL + "SizeChart/GetAttributesWithValues"
    );
    result = await response.json();
    let array = [];
    result.forEach((attribute) => {
      attribute.AttributeValueModel.forEach((value) => {
        array.push({
          AttributeValueID: value.AttributeValueID,
          ValueName: attribute.AttributeName + " - " + value.ValueName,
        });
      });
    });
    array = array.map(({ AttributeValueID: value, ValueName: label }) => ({
      value,
      label,
    }));
    setAttributeValues(array);
  };

  useEffect(() => {
    GetSizeChart();
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Size Chart: {categoryName}{" "}
              {attribute !== "" && " - " + attribute}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to={
                parseInt(url.sizechartid) === 0
                  ? "/universal-size-chart/category"
                  : "/size-chart"
              }>Size Chart <AiOutlineRight /></Link></li>
              <li>Manage Universal Size Chart</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h5 className="card-title">
                      {categoryName}{" "}
                      {attribute !== "" && " - " + attribute}

                    </h5>
                  </div>
                  <div className="col-6 text-right">
                    <NavLink
                      className="btn btn-secondary"
                      to={
                        parseInt(url.sizechartid) === 0
                          ? "/universal-size-chart/category"
                          : "/size-chart"
                      }
                    >
                      Back
                    </NavLink>
                    <Button
                      variant="primary"
                      className="ms-2"
                      onClick={
                        parseInt(url.sizechartid) === 0
                          ? InsertSizeChart
                          : UpdateSizeChart
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>

              <div className="card-body">
                {measurements.length > 0 && sizes.length > 0 ? (
                  <div className="pe-4 ps-4">
                    <div className="form-group row">
                      <div className="form-check col-3 pl-38">
                        <label
                          className="form-check-label"
                          htmlFor="IsAppliedToCategories"
                        >
                          Applied To Categories
                        </label>
                        <input
                          type="checkbox"
                          id="IsPublished"
                          className="form-check-input"
                          defaultValue={isAppliedToCategories}
                          checked={isAppliedToCategories}
                          onChange={(e) => {
                            setIsAppliedToCategories(
                              e.target.value === "true" ? false : true
                            );
                            setAssignedCategories([]);
                          }}
                        />
                      </div>
                      <div className="col-3">
                        {isAppliedToCategories && (
                          <Select
                            isMulti
                            value={assignedCategories}
                            options={categories}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#FAFAFA",
                                primary: "#092a29",
                              },
                            })}
                            name="Category"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => setAssignedCategories(e)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-check col-3 pl-38">
                        <label
                          className="form-check-label"
                          htmlFor="IsAppliedToCategories"
                        >
                          Applied To Attribute Values
                        </label>
                        <input
                          type="checkbox"
                          id="IsAppliedToAttributeValues"
                          className="form-check-input"
                          defaultValue={isAppliedToAttributeValues}
                          checked={isAppliedToAttributeValues}
                          onChange={(e) => {
                            setIsAppliedToAttributeValues(
                              e.target.value === "true" ? false : true
                            );
                            setAssignedAttributeValues([]);
                          }}
                        />
                      </div>
                      <div className="col-3">
                        {isAppliedToAttributeValues && (
                          <Select
                            isMulti
                            value={assignedAttributeValues}
                            options={attributeValues}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#FAFAFA",
                                primary: "#092a29",
                              },
                            })}
                            name="AttributeValue"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => setAssignedAttributeValues(e)}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <table className="size-table">
                        <thead>
                          <tr>
                            <th>Size</th>
                            {measurements.map((measurement) => {
                              return (
                                <th
                                  className="table-header"
                                  key={measurement.MeasurementId}
                                >
                                  {measurement.MeasurementValue}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        {sizes.map((size, sindex) => {
                          return (
                            <tr key={sindex}>
                              <th className="cell-width">{size.AttributeValueName}</th>
                              {measurements.map((measurement, mindex) => {
                                return (
                                  <th key={mindex}>
                                    {selectedSizes.some(
                                      (selected) =>
                                        selected.SizeId ===
                                        size.AttributeValueId &&
                                        selected.MeasurementId ===
                                        measurement.MeasurementId
                                    ) ? (
                                      <input
                                        type="text"
                                        id="sizechart"
                                        className="form-control searchWrapper"
                                        required={true}
                                        value={
                                          selectedSizes.filter(
                                            (selected) =>
                                              selected.SizeId ===
                                              size.AttributeValueId &&
                                              selected.MeasurementId ===
                                              measurement.MeasurementId
                                          )[0].SizeChartValue
                                        }
                                        onChange={(e) =>
                                          MeasurementChange(e, sindex, mindex)
                                        }
                                      ></input>
                                    ) : (
                                      <input
                                        type="text"
                                        id="sizechart"
                                        className="form-control searchWrapper"
                                        required={true}
                                        value=""
                                        onChange={(e) =>
                                          MeasurementChange(e, sindex, mindex)
                                        }
                                      ></input>
                                    )}
                                  </th>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <label className="mb-2"> Notes </label>
                        <textarea type="text" className="form-control" value={notes} onChange={(e) => setNotes(e.target.value)} />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <label className="mb-2"> Sizing And Fit Guide </label>
                        <CKEditor
                                                    editor={ClassicEditor}
                                                    data={sizingAndFitGuide}
                                                    value={sizingAndFitGuide}
                                                    onChange={(event, editor) => {
                                                      const data =
                                                        editor.getData();
                                                      setSizingAndFitGuide(data);
                                                    }}
                                                  />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      There is no assigned measurements or sizes to this
                      category{" "}
                      <NavLink
                        to={"/Category/" + url.categoryid}
                        className="icon"
                      >
                        {" "}
                        Click here{" "}
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUniversalSizeChart;
