import React, { useState, useEffect, useRef } from "react";
import { Variables } from "../../Variations/Variations";
import { useParams } from "react-router";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Loader from "../../Layout/Loader";
import { MdManageSearch, MdOutlineAssignmentReturned } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

const ManageCoupons = () => {
  const navigate = useNavigate();
  let { CouponID } = useParams();

  //#region Coupon
  const [name, setName] = useState("");
  const [priority, setPriority] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [freeShipping, setFreeShipping] = useState(false);
  const [couponTypeId, setCouponTypeId] = useState(0);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isAppliedToRangeOfPrices, setIsAppliedToRangeOfPrices] =
    useState(false);
  const [startTotal, setStartTotal] = useState(0);
  const [endTotal, setEndTotal] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [limit, setLimit] = useState(0);
  const [usage, setUsage] = useState(0);
  const [amount, setAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [isAppliedToAttributes, setIsAppliedToAttributes] = useState(false);
  const [isAppliedToCountries, setIsAppliedToCountries] = useState(false);
  const [isAppliedToGroups, setIsAppliedToGroups] = useState(false);
  const [isAppliedToCategories, setIsAppliedToCategories] = useState(false);
  const [isAppliedToProducts, setIsAppliedToProducts] = useState(false);
  let loader = useRef(true);
  let unassignedProductLoader = useRef(true);
  let assignedProductLoader = useRef(true);
  const [isAppliedWithOtherCoupon, setIsAppliedWithOtherCoupon] =
    useState(false);
  const [couponTypes, setCouponTypes] = useState([]);
  const [action, setAction] = useState(0);
  const fetchCouponTypes = async () => {
    let response, result;
    response = await fetch(Variables.API_URL + "CouponType/GetCouponTypes");
    result = await response.json();
    setCouponTypes(result);
  };
  const fetchCoupon = async () => {
    let response, result;
    response = await fetch(Variables.API_URL + "Coupon/GetCoupon/" + CouponID);
    result = await response.json();
    setName(result.CouponName);
    setPriority(result.CouponPriority);
    setStartDate(result.StartDate);
    setExpiryDate(result.ExpiryDate);
    setCouponTypeId(parseInt(result.CouponTypeId));
    setIsPublished(result.IsPublished);
    setFreeShipping(result.IsFreeShipping);
    if (result.StartTotal != 0) {
      setStartTotal(result.StartTotal);
      setEndTotal(result.EndTotal);
      setIsAppliedToRangeOfPrices(result.IsAppliedToRangeOfPrices);
    }
    setIsAppliedWithOtherCoupon(result.IsAppliedWithOtherCoupon);
    setAmount(result.Amount);
    setPercentage(result.Percentage);
    setIsAppliedToAttributes(result.IsAppliedToAttributes);
    setIsAppliedToCategories(result.IsAppliedToCategories);
    setIsAppliedToCountries(result.IsAppliedToCountries);
    setIsAppliedToGroups(result.IsAppliedToGroups);
    setIsAppliedToProducts(result.IsAppliedToProducts);

    if (result.Amount !== 0) {
      setAction(1);
    } else {
      if (result.Percentage !== 0) {
        setAction(2);
      } else {
        setAction(0);
      }
    }

    setIsCoupon(result.IsCoupon);
    setCouponCode(result.CouponCode);
    setLimit(result.UserLimit);
    setUsage(result.UsageTries);
  };
  function handleExpiryDate(e) {
    if (e.target.value < startDate) {
      document.getElementById("ExpiryDate").focus();
      setExpiryDate("");
      toast.error("End date should be higher than the Start date");
    } else {
      setExpiryDate(e.target.value);
    }
  }

  function handleLimit(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setLimit(0);
    } else {
      setLimit(e.target.value);
    }
  }

  function handleAction(value) {
    setAction(value);
    if (value == 0) {
      setAmount(0);
      setPercentage(0);
    }
    if (value == 1) {
      setPercentage(0);
    }
    if ((value = 2)) {
      setAmount(0);
    }
  }
  function handleIsCoupon(e) {
    setIsCoupon(e.target.checked);
    if (e.target.checked === false) {
      setLimit(0);
      setUsage(0);
      setCouponCode("");
    }
  }
  function handleAmount(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setAmount(0);
    } else {
      setAmount(e.target.value);
      var result = [];
      if (e.target.value === "") {
        result = filteredUnAssignedProducts;
      } else {
        unassignedProducts.forEach((element) => {
          var variations = [];
          element.Product.Variation.forEach((v) => {
            if (v.RegularPrice > e.target.value) {
              variations.push(v);
            }
          });
          if (variations.length != 0) {
            var obj = {
              ProductId: element.Product.ProductId,
              ProductName: element.Product.ProductName,
              Sku: element.Product.Sku,
              Variation: variations,
            };
            result.push(obj);
          }
        });
      }
      setfilteredUnAssignedProducts(result);
    }
  }

  function IsValid() {
    if (name === "") {
      toast.error("Please Fill All Required Fields");
      return false;
    }
    if (isAppliedToCountries === true && assignedCountries.length == 0) {
      toast.error("Please Choose A Country");
      return false;
    }
    if (isAppliedToAttributes === true && assignedAttributeValues.length == 0) {
      toast.error("Please Choose An Attribute Value");
      return false;
    }
    if (isAppliedToCategories === true && assignedCategories.length == 0) {
      toast.error("Please Choose A Category");
      return false;
    }
    if (isAppliedToGroups === true && assignedGroups.length == 0) {
      toast.error("Please Choose A Group");
      return false;
    }
    if (isAppliedToProducts === true && assignedProducts.length == 0) {
      toast.error("Please Choose A product");
      return false;
    }

    if (couponTypeId == 0) {
      toast.error("Please Choose A Coupon Type");
      return false;
    }
    if (amount == 0 && percentage == 0 && !freeShipping) {
      toast.error("Please Fill All required Fields");
      return false;
    }

    if (isAppliedToRangeOfPrices === true) {
      if (startTotal == 0 && endTotal == 0) {
        toast.error("Please Fill All required Fields");
        return false;
      } else {
        if (startTotal > endTotal) {
          toast.error("Please choose an end total higher than the start ");
          return false;
        }
      }
    }

    if (startDate === "" || expiryDate === "") {
      toast.error("Please Choose A Start And End Date");
      return false;
    }

    if (couponTypeId == 1) {
      if (isCoupon === true) {
        if (couponCode === "") {
          toast.error("Please Fill All required Fields");
          return false;
        }
        if (limit == 0) {
          toast.error("Please Fill All required Fields");
          return false;
        }
        if (usage == 0) {
          toast.error("Please Fill All required Fields");
          return false;
        }
      }
    }

    if (priority === "") {
      toast.error("Please Fill All Required Fields");
      return false;
    }
    if (percentage > 100) {
      toast.error("You cannot choose a percentage higher than 100");
      return false;
    }

    return true;
  }

  function HandleSubmit(e) {
    if (IsValid()) {
      var amountToPost = amount;
      var percentageToPost = percentage;
      var limitToPost = limit;
      var usageToPost = usage;
      var startTotalToPost = startTotal;
      var endTotalToPost = endTotal;
      var priorityToPost = priority;
      if (priority === "") {
        priorityToPost = 0;
      }
      if (limit === "") {
        limitToPost = 0;
      }
      if (usage === "") {
        usageToPost = 0;
      }
      if (startTotal === "") {
        startTotalToPost = 0;
      }
      if (endTotal === "") {
        endTotalToPost = 0;
      }
      if (action == 2) {
        amountToPost = 0;
      }
      if (action == 1) {
        percentageToPost = 0;
      }

      e.preventDefault();
      if (CouponID == 0) {
        const addCoupon = async () => {
          let response = await fetch(Variables.API_URL + "Coupon/AddCoupon", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.API_URL,
            },
            body: JSON.stringify({
              CouponName: name,
              CouponTypeId: couponTypeId,
              Amount: amountToPost,
              Percentage: percentageToPost,
              IsFreeShipping: freeShipping,
              isAppliedToRangeOfPrices: isAppliedToRangeOfPrices,
              startTotal: startTotalToPost,
              endTotal: endTotalToPost,
              startDate: startDate,
              expiryDate: expiryDate,
              IsPublished: isPublished,
              IsCoupon: isCoupon,
              CouponCode: couponCode,
              CouponPriority: priorityToPost,
              UsageTries: usageToPost,
              UserLimit: limitToPost,
              isAppliedToAttributes: isAppliedToAttributes,
              isAppliedToCategories: isAppliedToCategories,
              isAppliedToCountries: isAppliedToCountries,
              isAppliedToGroups: isAppliedToGroups,
              isAppliedToProducts: isAppliedToProducts,
              isAppliedWithOtherCoupon: isAppliedWithOtherCoupon,
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            navigate("/Coupons/" + result);
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await addCoupon())();
        }, 100);
      } else {
        loader.current = true;
        const updateCoupon = async () => {
          let response = await fetch(
            Variables.API_URL + "Coupon/UpdateCoupon/" + CouponID,
            {
              method: "Put",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": Variables.API_URL,
              },
              body: JSON.stringify({
                CouponName: name,
                CouponTypeId: couponTypeId,
                Amount: amountToPost,
                Percentage: percentageToPost,
                IsFreeShipping: freeShipping,
                isAppliedToRangeOfPrices: isAppliedToRangeOfPrices,
                startTotal: startTotalToPost,
                endTotal: endTotalToPost,
                startDate: startDate,
                expiryDate: expiryDate,
                IsPublished: isPublished,
                IsCoupon: isCoupon,
                CouponCode: couponCode,
                CouponPriority: priorityToPost,
                UsageTries: usageToPost,
                UserLimit: limitToPost,
                isAppliedToAttributes: isAppliedToAttributes,
                isAppliedToCategories: isAppliedToCategories,
                isAppliedToCountries: isAppliedToCountries,
                isAppliedToGroups: isAppliedToGroups,
                isAppliedToProducts: isAppliedToProducts,
                isAppliedWithOtherCoupon: isAppliedWithOtherCoupon,
                AssignedCountries:assignedCountries,
                AssignedGroups:assignedGroups,
                AssignedCategories:assignedCategories,
                AssignedAttributeValues:assignedAttributeValues,
                AssignedProducts:assignedProducts
              }),
            }
          );
          let result = await response.json();

          if (response.status === 200) {
            toast.success(result);
            navigate("/Coupons");
            loader.current = false;
          } else {
            toast.error(result);
            loader.current = false;
          }
        };
        setTimeout(() => {
          (async () => await updateCoupon())();
        }, 100);
      }
    }
  }
  function handlePriority(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setPriority(0);
    } else {
      setPriority(e.target.value);
    }
  }
  function handlePriceRange(e) {
    setIsAppliedToRangeOfPrices(e.target.checked);
    if (e.target.checked === false) {
      setStartTotal(0);
      setEndTotal(0);
    }
  }
  //#endregion

  //#region Countries
  const [unassignedCountries, setUnassignedCountries] = useState([]);
  const [assignedCountries, setAssignedCountries] = useState([]);
  const [isOpenUnassignedCountries, setIsOpenUnassignedCountries] =
    useState(false);
  const [filteredAssignedCountries, setFilteredAssignedCountries] = useState(
    []
  );
  const [filteredUnAssignedCountries, setFilteredUnAssignedCountries] =
    useState([]);
  const fecthUnAssignedCountries = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCountriesToCoupon/GetUnAssignedCountriesForCoupon/" +
        CouponID
    );
    result = await response.json();

    var array = [];
    for (var i = 0; i < result.length; i++) {
      array.push(result[i]);
    }
    setUnassignedCountries(array);
    setFilteredUnAssignedCountries(array);
  };
  const fetchAssignedCountries = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCountriesToCoupon/GetAssignedCountries/" +
        CouponID
    );
    result = await response.json();
    if (response.status == 200) {
      var array = [];
      for (var i = 0; i < result.length; i++) {
        array.push(result[i]);
      }
      setAssignedCountries(array);
      setFilteredAssignedCountries(array);
      loader.current = false;
    }
  };

  const unassignedCountriesColumns = [
    {
      name: "ID",
      selector: (row) => row.CountryId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.CountryName,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.CountryCode,
      sortable: true,
    },
    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignCountry(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedCountriesColumns = [
    {
      name: "ID",
      selector: (row) => row.CountryId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.CountryName,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.CountryCode,
      sortable: true,
    },
    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignCountry(row)}
        >
          <BiTrash />
        </button>
      ),
    },
  ];

  async function AssignCountry(row) {
    var array = [...assignedCountries];
    array.push(row);

    var unassigned = [...unassignedCountries];
    unassigned.forEach((element, i) => {
      if (element == row) {
        unassigned.splice(i, 1);
      }
    });
    array.sort((a, b) => a.CountryId - b.CountryId);
    unassigned.sort((a, b) => a.CountryId - b.CountryId);
    setFilteredUnAssignedCountries(unassigned);
    setUnassignedCountries(unassigned);
    setAssignedCountries(array);
    setFilteredAssignedCountries(array);
  }

  async function AssignAllCountries() {
    var array = [...assignedCountries];
    var unassigned = [...unassignedCountries];
    unassigned.forEach((element, i) => {
      array.push(element);
    });
    array.sort((a, b) => a.CountryId - b.CountryId);
    unassigned.sort((a, b) => a.CountryId - b.CountryId);
    setAssignedCountries(array);
    setFilteredAssignedCountries(array);
    setUnassignedCountries([]);
    setFilteredUnAssignedCountries([]);
    setIsOpenUnassignedCountries(false);
  }

  async function UnAssignCountry(row) {
    var unassigned = [...unassignedCountries];
    var assigned = [...assignedCountries];
    unassigned.push(row);
    assigned.forEach((element, i) => {
      if (row == element) {
        assigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.CountryId - b.CountryId);
    unassigned.sort((a, b) => a.CountryId - b.CountryId);
    setAssignedCountries(assigned);
    setFilteredAssignedCountries(assigned);
    setUnassignedCountries(unassigned);
    setFilteredUnAssignedCountries(unassigned);
  }

  function handleSearchAssignedCountries(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      setFilteredAssignedCountries(filteredAssignedCountries);
    } else {
      result = assignedCountries.filter((data) => {
        return data.CountryName.toLowerCase().search(value) != -1;
      });
      setFilteredAssignedCountries(result);
    }
  }
  function handleSearchUnAssignedCountries(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      setFilteredUnAssignedCountries(filteredUnAssignedCountries);
    } else {
      result = unassignedCountries.filter((data) => {
        return data.CountryName.toLowerCase().search(value) != -1;
      });
      setFilteredUnAssignedCountries(result);
    }
  }

  //#endregion

  //#region Groups
  const [unassignedGroups, setUnassignedGroups] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);
  const [isOpenUnassignedGroups, setIsOpenUnassignedGroups] = useState(false);
  const [assignedGroupsLoading, setAssignedGroupsLoading] = useState(true);
  const [filteredUnAssignedGroups, setFilteredUnAssignedGroups] = useState([]);
  const [filteredAssignedGroups, setFilteredAssignedGroups] = useState([]);
  const fecthUnAssignedGroups = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCouponToGroup/GetUnAssignedGroupsForCoupon/" +
        CouponID
    );
    result = await response.json();

    var array = [];
    for (var i = 0; i < result.length; i++) {
      array.push(result[i]);
    }
    setUnassignedGroups(array);
    setFilteredUnAssignedGroups(array);
  };

  const fetchAssignedGroups = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignCouponToGroup/GetAssignedGroups/" + CouponID
    );
    result = await response.json();
    if (response.status == 200) {
      setAssignedGroups(result);
      setFilteredAssignedGroups(result);
      loader.current = false;
    }
  };

  function handleSearchAssignedGroups(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedGroups.filter((data) => {
      return data.GroupName.toLowerCase().search(value) != -1;
    });
    setFilteredAssignedCountries(result);
  }
  function handleSearchUnAssignedGroups(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedGroups.filter((data) => {
      return data.GroupName.toLowerCase().search(value) != -1;
    });
    setFilteredUnAssignedCountries(result);
  }
  const unassignedGroupsColumns = [
    {
      name: "ID",
      selector: (row) => row.CouponGroupId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignGroup(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedGroupsColumns = [
    {
      name: "ID",
      selector: (row) => row.CouponGroupId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignGroup(row)}
        >
          <BiTrash />
        </button>
      ),
    },
  ];
  async function AssignGroup(row) {
    var assigned = [...assignedGroups];
    var unassigned = [...unassignedGroups];
    assigned.push(row);
    unassigned.forEach((element, i) => {
      if (element == row);
      unassigned.splice(i, 1);
    });
    assigned.sort((a, b) => a.CouponGroupId - b.CouponGroupId);
    unassigned.sort((a, b) => a.CouponGroupId - b.CouponGroupId);
    setFilteredAssignedGroups(assigned);
    setAssignedGroups(assigned);
    setFilteredUnAssignedGroups(unassigned);
    setUnassignedGroups(unassigned);
  }
  async function UnAssignGroup(row) {
    var assigned = [...assignedGroups];
    var unassigned = [...unassignedGroups];
    unassigned.push(row);
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.CouponGroupId - b.CouponGroupId);
    unassigned.sort((a, b) => a.CouponGroupId - b.CouponGroupId);
    setAssignedGroups(assigned);
    setFilteredAssignedGroups(assigned);
    setUnassignedGroups(unassigned);
    setFilteredUnAssignedGroups(unassigned);
  }
  async function AssignAllGroups() {
    var unassigned = [...unassignedGroups];
    var assigned = [...assignedGroups];
    unassigned.forEach((element) => {
      assigned.push(element);
    });
    assigned.sort((a, b) => a.CouponGroupId - b.CouponGroupId);
    unassigned.sort((a, b) => a.CouponGroupId - b.CouponGroupId);
    setFilteredUnAssignedGroups([]);
    setUnassignedGroups([]);
    setFilteredAssignedGroups(assigned);
    setAssignedGroups(assigned);
    setIsOpenUnassignedGroups(false);
  }

  //#endregion

  //#region Categories
  const [isOpenUnAssignedCategories, setIsOpenUnAssignedCategories] =
    useState(false);
  const [unassignedCategories, setUnAssignedCategories] = useState([]);
  const [assignedCategories, setAssignedCategories] = useState([]);
  const [filteredUnAssignedCategories, setFilteredUnAssignedCategories] =
    useState([]);
  const [filteredAssignedCategories, setFilteredAssignedCategories] = useState(
    []
  );

  async function AssignAllCategories() {
    var assigned = [...assignedCategories];
    var unassigned = [...unassignedCategories];
    unassigned.forEach((element) => {
      assigned.push(element);
    });
    assigned.sort((a, b) => a.CategoryId - b.CategoryId);
    setAssignedCategories(assigned);
    setFilteredAssignedCategories(assigned);
    setFilteredUnAssignedCategories([]);
    setUnAssignedCategories([]);
    setIsOpenUnAssignedCategories(false);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assignedAttributeValues,
      assigned
    );
  }
  const unassignedCategoriesColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: " Category Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignCategory(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedCategoriesColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: " Category Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignCategory(row)}
        >
          <BiTrash />
        </button>
      ),
    },
  ];
  const fecthUnAssignedCategories = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCouponToCategory/GetUnAssignedCategoriesForCoupon/" +
        CouponID
    );
    result = await response.json();

    var array = [];
    for (var i = 0; i < result.length; i++) {
      array.push(result[i]);
    }
    setUnAssignedCategories(array);
    setFilteredUnAssignedCategories(array);
  };

  const fetchAssignedCategories = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCouponToCategory/GetAssignedCategories/" +
        CouponID
    );
    result = await response.json();
    if (response.status == 200) {
      setAssignedCategories(result);
      setFilteredAssignedCategories(result);
      fetchUnAssignedProducts(
        isAppliedToAttributes,
        isAppliedToCategories,
        assignedAttributeValues,
        result
      );
      loader.current = false;
    }
  };

  async function AssignCategory(row) {
    var assigned = [...assignedCategories];
    var unassigned = [...unassignedCategories];
    assigned.push(row);
    unassigned.forEach((element, i) => {
      if (element == row) {
        unassigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.CategoryId - b.CategoryId);
    unassigned.sort((a, b) => a.CategoryId - b.CategoryId);
    setAssignedCategories(assigned);
    setFilteredAssignedCategories(assigned);
    setFilteredUnAssignedCategories(unassigned);
    setUnAssignedCategories(unassigned);
    setIsAppliedToProducts(false);
    setfilteredAssignedProducts([]);
    setassignedProducts([]);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assignedAttributeValues,
      assigned
    );

  
  }
  async function UnAssignCategory(row) {
    var assigned = [...assignedCategories];
    var unassigned = [...unassignedCategories];
    unassigned.push(row);
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.CategoryId - b.CategoryId);
    unassigned.sort((a, b) => a.CategoryId - b.CategoryId);
    setAssignedCategories(assigned);
    setFilteredAssignedCategories(assigned);
    setFilteredUnAssignedCategories(unassigned);
    setUnAssignedCategories(unassigned);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assignedAttributeValues,
      assigned
    );
  }

  function handleSearchAssignedCategories(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedCategories.filter((data) => {
      return data.CategoryName.toLowerCase().search(value) != -1;
    });
    setFilteredAssignedCategories(result);
  }
  function handleSearchUnAssignedCategories(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedCategories.filter((data) => {
      return data.CategoryName.toLowerCase().search(value) != -1;
    });
    setFilteredUnAssignedCategories(result);
  }

  //#endregion

  //#region Attribute Values

  const [isOpenUnAssignedAttributeValues, setisOpenUnAssignedAttributeValues] =
    useState(false);
  const [assignedAttributeValues, setassignedAttributeValues] = useState([]);
  const [filteredAssignedAttributeValues, setfilteredAssignedAttributeValues] =
    useState([]);
  const [unassignedAttributeValues, setunassignedAttributeValues] = useState(
    []
  );
  const [
    filteredUnAssignedAttributeValues,
    setfilteredUnAssignedAttributeValues,
  ] = useState([]);
  const unassignedAttributeValuesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value Name ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignAttributeValue(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedAttributeValuesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value Name",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignAttributeValue(row)}
        >
          <BiTrash />
        </button>
      ),
    },
  ];
  const fecthUnAssignedAttributeValues = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCouponToAttributeValue/GetUnAssignedAttributeValuesForCoupon/" +
        CouponID
    );
    result = await response.json();
    if (response.status === 200) {
      setunassignedAttributeValues(result);
      setfilteredUnAssignedAttributeValues(result);
    }
  };
  const fetchAssignedAttributeValues = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCouponToAttributeValue/GetAssignedAttributeValues/" +
        CouponID
    );
    result = await response.json();
    if (response.status == 200) {
      setassignedAttributeValues(result);
      setfilteredAssignedAttributeValues(result);
      fetchUnAssignedProducts(
        isAppliedToAttributes,
        isAppliedToCategories,
        result,
        assignedCategories
      );
      loader.current = false;
    }
  };
  async function AssignAttributeValue(row) {
    var assigned = [...assignedAttributeValues];
    var unassigned = [...unassignedAttributeValues];
    assigned.push(row);
    unassigned.forEach((element, i) => {
      if (element == row) {
        unassigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.AttributeValueId - b.AttributeValueId);
    unassigned.sort((a, b) => a.AttributeValueId - b.AttributeValueId);

    setfilteredAssignedAttributeValues(assigned);
    setassignedAttributeValues(assigned);
    setunassignedAttributeValues(unassigned);
    setfilteredUnAssignedAttributeValues(unassigned);
    setIsAppliedToProducts(false);
    setfilteredAssignedProducts([]);
    setassignedProducts([]);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assigned,
      assignedCategories
    );
  }

  async function UnAssignAttributeValue(row) {
    var assigned = [...assignedAttributeValues];
    var unassigned = [...unassignedAttributeValues];
    unassigned.push(row);
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.AttributeValueId - b.AttributeValueId);
    unassigned.sort((a, b) => a.AttributeValueId - b.AttributeValueId);

    setfilteredAssignedAttributeValues(assigned);
    setassignedAttributeValues(assigned);
    setunassignedAttributeValues(unassigned);
    setfilteredUnAssignedAttributeValues(unassigned);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assigned,
      assignedCategories
    );
  }
  function handleSearchAssignedAttributeValues(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedAttributeValues.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedAttributeValues(result);
  }
  function handleSearchUnAssignedAttributeValues(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedAttributeValues.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedAttributeValues(result);
  }
  async function AssignAllAttributeValues() {
    var assigned = [...assignedAttributeValues];
    var unassigned = [...unassignedAttributeValues];
    unassigned.forEach((element) => {
      assigned.push(element);
    });
    assigned.sort((a, b) => a.AttributeValueId - b.AttributeValueId);

    setfilteredAssignedAttributeValues(assigned);
    setassignedAttributeValues(assigned);
    setunassignedAttributeValues([]);
    setfilteredUnAssignedAttributeValues([]);
    setisOpenUnAssignedAttributeValues(false);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assigned,
      assignedCategories
    );
  }

  function handleAppliedToAttributes(e) {
    setIsAppliedToAttributes(e.target.checked);
    fetchUnAssignedProducts(
      e.target.checked,
      isAppliedToCategories,
      assignedAttributeValues,
      assignedCategories
    );
    setIsAppliedToProducts(false);
  }
  function handleIsAppliedToCategories(e) {
    setIsAppliedToCategories(e.target.checked);
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      e.target.checked,
      assignedAttributeValues,
      assignedCategories
    );
    setIsAppliedToProducts(false);
  }

  function handleIsAppliedToProducts(e) {
    setIsAppliedToProducts(e.target.checked);

    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assignedAttributeValues,
      assignedCategories
    );
  }

  //#endregion

  //#region Products
  const [isOpenUnAssignedProducts, setisOpenUnAssignedProducts] =
    useState(false);
  const [assignedProducts, setassignedProducts] = useState([]);
  const [filteredAssignedProducts, setfilteredAssignedProducts] = useState([]);
  const [unassignedProducts, setunassignedProducts] = useState([]);
  const [filteredUnAssignedProducts, setfilteredUnAssignedProducts] = useState(
    []
  );

  const AssignedProductsExpandedComponent = ({ data }) => (
    <DataTable
      className="productList"
      columns={assignedvariationColumns}
      data={data.Variations}
    />
  );

  const UnAssignedProductsExpandedComponent = ({ data }) => (
    <DataTable
      className="productList"
      columns={unassignedvariationColumns}
      data={data.Variations}
    />
  );

  async function fetchAssignedProductVariations(e, row) {
    if (e == true) {
      const URL =
        Variables.API_URL +
        "AssignCouponToProduct/GetAssignedProductVariations/" +
        row.ProductId +
        "/" +
        CouponID;
      const response = await fetch(URL, {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      });
      if (!response.ok) throw Error("Did Not Receive expected data");
      const listItems = await response.json();
      var array = [...assignedProducts];
      array.forEach((element, i) => {
        if (element.ProductId === row.ProductId) {
          delete array[i].Variations;
          array[i].Variations = listItems;
        }
      });
      setfilteredAssignedProducts(array);
      setassignedProducts(array);
    }
  }

  async function fetchUnassignedProductVariations(e, row) {
    if (e == true) {
      const URL =
        Variables.API_URL +
        "AssignCouponToProduct/GetUnAssignedProductVariations/" +
        row.ProductId +
        "/" +
        CouponID;

      const response = await fetch(URL, {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      });
      if (!response.ok) throw Error("Did Not Receive expected data");
      const listItems = await response.json();
      var array = [...unassignedProducts];
      array.forEach((element, i) => {
        if (element.ProductId === row.ProductId) {
          delete array[i].Variations;
          array[i].Variations = listItems;
        }
      });
      setfilteredUnAssignedProducts(array);
      setunassignedProducts(array);
    }
  }

  const unassignedExpandableRows = ({ data }) => (
    <DataTable
      className="ml-3"
      columns={unassignedvariationColumns}
      data={data.Product.Variation}
    />
  );
  const unassignedProductsColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductId,
      sortable: true,
    },
    {
      name: " Product Name ",
      selector: (row) => row.ProductName,
      sortable: true,
    },

    {
      name: "Assign All",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignProduct(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const unassignedvariationColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: " Sku ",
      selector: (row) => row.Sku,
      sortable: true,
    },
    {
      name: " Color ",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: " Size ",
      selector: (row) => row.Size,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignProductVariation(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedProductsColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductId,
      sortable: true,
    },
    {
      name: " Product Name",
      selector: (row) => row.ProductName,
      sortable: true,
    },

    {
      name: "UnAssign All",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignProduct(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedvariationColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: " Sku ",
      selector: (row) => row.Sku,
      sortable: true,
    },
    {
      name: " Color ",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: " Size ",
      selector: (row) => row.Size,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignProductVariation(row)}
        >
          <BiTrash />
        </button>
      ),
    },
  ];

  const fetchUnAssignedProducts = async (
    isAttributes,
    isCategories,
    attributes,
    categories
  ) => {
    unassignedProductLoader.current = true;
    var attributeToPost = [];
    var catToPost = [];
    if (isAttributes === true || isCategories === true) {
      if (isAttributes === true) {
        if (attributes.length != 0) {
          attributes.forEach((element) => {
            attributeToPost.push(element.AttributeValueId);
          });
        }
      }
      if (isCategories === true) {
        if (categories.length != 0) {
          categories.forEach((element) => {
            catToPost.push(element.CategoryId);
          });
        }
      }

      let response1 = await fetch(
        Variables.API_URL +
          "AssignCouponToProduct/GetUnAssignedProductsOfAttributesAndCategories/" +
          CouponID,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },

          body: JSON.stringify({
            attributes: attributeToPost,
            categories: catToPost,
            IsPriceRange: isAppliedToRangeOfPrices,
            StartTotal: startTotal,
            EndTotal: endTotal,
            Amount: amount,
          }),
        }
      );
      let result1 = await response1.json();
      setunassignedProducts(result1);
      setfilteredUnAssignedProducts(result1);
      unassignedProductLoader.current = false;
    } else {
      let response = await fetch(
        Variables.API_URL +
          "AssignCouponToProduct/GetUnAssignedProductsForCoupon/" +
          CouponID,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },

          body: JSON.stringify({
            IsPriceRange: isAppliedToRangeOfPrices,
            StartTotal: startTotal,
            EndTotal: endTotal,
            Amount: amount,
          }),
        }
      );
      let result = await response.json();
      setunassignedProducts(result);
      setfilteredUnAssignedProducts(result);
      unassignedProductLoader.current = false;
    }
  };
  const fetchAssignedProducts = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
        "AssignCouponToProduct/GetAssignedProducts/" +
        CouponID
    );
    result = await response.json();
    if (response.status == 200) {
      setassignedProducts(result);
      setfilteredAssignedProducts(result);
      assignedProductLoader.current = false;
      
    }
  };
  async function AssignProduct(row) {
    var assigned = [...assignedProducts];
    var unassigned = [...unassignedProducts];
    assigned.push(row);
    unassigned.forEach((element, i) => {
      if (element == row) {
        unassigned.splice(i, 1);
      }
    });

    assigned.sort((a, b) => a.ProductId - b.ProductId);
    unassigned.sort((a, b) => a.ProductId - b.ProductId);
    setassignedProducts(assigned);
    setfilteredAssignedProducts(assigned);
    setunassignedProducts(unassigned);
    setfilteredUnAssignedProducts(unassigned);
  }
  async function AssignProductVariation(row) {
    var assigned = [...assignedProducts];
    var unassigned = [...unassignedProducts];
    var ids = [];

    assigned.forEach((element) => {
      ids.push(element.ProductId);
    });
    if (ids.includes(row.ProductId)) {
      assigned.forEach((element) => {
        if (element.ProductId == row.ProductId) {
          var variations = [...element.Variations];
          variations.push(row);
          element.Variations = variations;
        }
      });
      unassigned.forEach((element) => {
        if (element.ProductId == row.ProductId) {
          element.Variations.forEach((variation, i) => {
            if (variation == row) {
              element.Variations.splice(i, 1);
              if (element.Variations.length == 0) {
                unassigned.splice(i, 1);
              }
            }
          });
        }
      });
    } else {
      var variations = [];
      variations.push(row);
      unassigned.forEach((element) => {
        if (element.ProductId == row.ProductId) {
          assigned.push({
            ProductId: element.ProductId,
            ProductName: element.ProductName,
            Variations: variations,
          });
          element.Variations.forEach((variation, i) => {
            if (variation == row) {
              element.Variations.splice(i, 1);
              if (element.Variations.length == 0) {
                unassigned.splice(i, 1);
              }
            }
          });
        }
      });
    }
    assigned.sort((a, b) => a.ProductId - b.ProductId);
    unassigned.sort((a, b) => a.ProductId - b.ProductId);
    setfilteredAssignedProducts(assigned);
    setassignedProducts(assigned);
    setfilteredUnAssignedProducts(unassigned);
    setunassignedProducts(unassigned);
  }

  async function UnAssignProduct(row) {
    var assigned = [...assignedProducts];
    var unassigned = [...unassignedProducts];
    unassigned.push(row);
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    assigned.sort((a, b) => a.ProductId - b.ProductId);
    unassigned.sort((a, b) => a.ProductId - b.ProductId);
    setassignedProducts(assigned);
    setfilteredAssignedProducts(assigned);
    setunassignedProducts(unassigned);
    setfilteredUnAssignedProducts(unassigned);
  }
  async function UnAssignProductVariation(row) {
        var assigned = [...assignedProducts];
    var unassigned = [...unassignedProducts];
    var ids = [];

    unassigned.forEach((element) => {
      ids.push(element.ProductId);
    });
    if (ids.includes(row.ProductId)) {
      unassigned.forEach((element) => {
        if (element.ProductId == row.ProductId) {
          var variations = [...element.Variations];
          variations.push(row);
          element.Variations = variations;
        }
      });
      assigned.forEach((element) => {
        if (element.ProductId == row.ProductId) {
          element.Variations.forEach((variation, i) => {
            if (variation == row) {
              element.Variations.splice(i, 1);
              if (element.Variations.length == 0) {
                assigned.splice(i, 1);
              }
            }
          });
        }
      });
    } else {
      var variations = [];
      variations.push(row);
      assigned.forEach((element) => {
        if (element.ProductId == row.ProductId) {
          unassigned.push({
            ProductId: element.ProductId,
            ProductName: element.ProductName,
            Variations: variations,
          });
          element.Variations.forEach((variation, i) => {
            if (variation == row) {
              element.Variations.splice(i, 1);
              if (element.Variations.length == 0) {
                assigned.splice(i, 1);
              }
            }
          });
        }
      });
    }
    assigned.sort((a, b) => a.ProductId - b.ProductId);
    unassigned.sort((a, b) => a.ProductId - b.ProductId);
    setfilteredAssignedProducts(assigned);
    setassignedProducts(assigned);
    setfilteredUnAssignedProducts(unassigned);
    setunassignedProducts(unassigned);
  }

  async function AssignAllProducts() {
    for (var i = 0; i < unassignedProducts.length; i++) {
      let response = await fetch(
        Variables.API_URL +
          "AssignCouponToProduct/AssignProductVariationToCoupon",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductId: unassignedProducts[i].ProductId,
            CouponId: CouponID,
          }),
        }
      );
    }
    fetchUnAssignedProducts(
      isAppliedToAttributes,
      isAppliedToCategories,
      assignedAttributeValues,
      assignedCategories
    );
  }
  function handleSearchAssignedProducts(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedProducts.filter((data) => {
      return data.ProductName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedProducts(result);
  }
  function handleSearchUnAssignedProducts(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedProducts.filter((data) => {
      return data.ProductName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedProducts(result);
  }
  //#endregion

  useEffect(() => {
    fetchCouponTypes();
    if (CouponID != 0) {
      fetchCoupon();
    }
    fecthUnAssignedCountries();
    fetchAssignedCountries();
    fecthUnAssignedGroups();
    fetchAssignedGroups();
    fetchAssignedCategories();
    fecthUnAssignedCategories();
    fetchAssignedAttributeValues();
    fecthUnAssignedAttributeValues();
    fetchAssignedProducts();
  }, [CouponID]);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        {loader.current ? (
          <Loader />
        ) : (
          <div>
            <div className="row">
              <div className="col-12">
                <h2>{CouponID == "0" ? "Add" : "Update"} Coupon</h2>
                <ul className="portalPath">
                  <li>
                    Home <AiOutlineRight />
                  </li>
                  <li>
                    <Link to="/Coupons">
                      Coupon <AiOutlineRight />
                    </Link>
                  </li>
                  <li>Manage Coupon</li>
                </ul>
              </div>
            </div>
            <div className="card mb-5">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h3>{name + " "} Coupon </h3>
                  </div>
                  <div className="col-6 text-end">
                    <button className="btn btn-primary " onClick={HandleSubmit}>
                      {" "}
                      Save
                    </button>{" "}
                    <button
                      className="btn btn-secondary ms-2"
                      onClick={() => navigate("/Coupons")}
                    >
                      Cancel
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-6">
                        <label>
                          Name <span className="required">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          id="CouponName"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        ></input>
                      </div>

                      <div className="col-6">
                        <label>Priority </label>
                        <input
                          type="number"
                          id="CouponPriority"
                          className="form-control"
                          placeholder="Price"
                          min="0"
                          value={priority}
                          onChange={(e) => handlePriority(e)}
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="form-group row mt-4">
                      <div className="col-3">
                        <label>
                          Start Date <span className="required">*</span>{" "}
                        </label>
                        <input
                          type="datetime-local"
                          id="StartDate"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required={true}
                        ></input>
                      </div>

                      <div className="col-3">
                        <label>
                          End Date <span className="required">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          id="ExpiryDate"
                          className="form-control"
                          value={expiryDate}
                          onChange={(e) => handleExpiryDate(e)}
                          required={true}
                        ></input>
                      </div>

                      <div className="form-check col-3 pl-38 mt-4">
                        <label
                          className="form-check-label"
                          htmlFor="IsPublished"
                        >
                          Published
                        </label>
                        <input
                          type="checkbox"
                          id="IsPublished"
                          className="form-check-input ml-4"
                          value={isPublished}
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                      </div>

                      {couponTypeId == 1 ? (
                        <div className="form-check col-3 pl-38 mt-4">
                          <label
                            className="form-check-label"
                            htmlFor="IsFreeShipping"
                          >
                            Free Shipping
                          </label>
                          <input
                            type="checkbox"
                            id="IsFreeShipping"
                            className="form-check-input"
                            value={freeShipping}
                            checked={freeShipping}
                            onChange={(e) => setFreeShipping(e.target.checked)}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-6">
                        <label>Type</label>
                        <select
                          id="CouponTypeId"
                          className="form-control"
                          value={couponTypeId}
                          onChange={(e) => setCouponTypeId(e.target.value)}
                        >
                          <option key="0" value="0">
                            -- Select --
                          </option>
                          {couponTypes.map((type) => {
                            return (
                              <option
                                key={type.CouponTypeId}
                                value={type.CouponTypeId}
                              >
                                {type.TypeName}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {couponTypeId != 0 ? (
                        <div className="form-check col-3 pl-38 mt-4">
                          <label
                            className="form-check-label"
                            htmlFor="DiscardSubsequentRules"
                          >
                            Discard Subsequent Rules
                          </label>
                          <input
                            type="checkbox"
                            id="DiscardSubsequentRules"
                            className="form-check-input"
                            value={isAppliedWithOtherCoupon}
                            checked={isAppliedWithOtherCoupon}
                            onChange={(e) =>
                              setIsAppliedWithOtherCoupon(e.target.checked)
                            }
                          />
                        </div>
                      ) : null}

                      {couponTypeId != 0 ? (
                        <div className="form-check col-3 pl-38 mt-4">
                          <label
                            className="form-check-label"
                            htmlFor="IsAppliedToRangeOfPrices"
                          >
                            {couponTypeId == 1 ? "Total Range" : ""}
                            {couponTypeId == 2 ? "Price Range" : ""}
                          </label>
                          <input
                            type="checkbox"
                            id="IsAppliedToRangeOfPrices"
                            className="form-check-input"
                            checked={isAppliedToRangeOfPrices}
                            value={isAppliedToRangeOfPrices}
                            onChange={(e) => handlePriceRange(e)}
                          />
                        </div>
                      ) : null}
                    </div>

                    {couponTypeId != 0 && isAppliedToRangeOfPrices === true ? (
                      <div className="form-group row mt-4">
                        <div className="col-6">
                          <label>
                            {couponTypeId == 1 ? "Start Total" : ""}{" "}
                            {couponTypeId == 2 ? "Start Price" : ""}
                          </label>
                          <input
                            type="number"
                            id="StartPrice"
                            className="form-control"
                            placeholder="Price"
                            min="0"
                            step="0.1"
                            value={startTotal}
                            onChange={(e) =>
                              setStartTotal(parseFloat(e.target.value))
                            }
                            required
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>
                            {couponTypeId === 1 ? "End Total" : ""}{" "}
                            {couponTypeId === 2 ? "End Price" : ""}{" "}
                          </label>
                          <input
                            type="number"
                            id="EndPrice"
                            className="form-control"
                            placeholder="Price"
                            min="0"
                            step="0.1"
                            value={endTotal}
                            onChange={(e) =>
                              setEndTotal(parseFloat(e.target.value))
                            }
                            required
                          ></input>
                        </div>
                      </div>
                    ) : null}

                    {couponTypeId == 1 ? (
                      <>
                        <div className="row mt-4">
                          <div className="form-group col-12">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Action"
                              id="None"
                              value={0}
                              checked={action == 0}
                              onChange={(e) => handleAction(e.target.value)}
                            />
                            &nbsp;
                            <label className="form-check-label" htmlFor="None">
                              None
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Action"
                              id="Amount"
                              value={1}
                              checked={action == 1}
                              onChange={(e) => handleAction(e.target.value)}
                            />
                            &nbsp;
                            <label
                              className="form-check-label"
                              htmlFor="Amount"
                            >
                              Amount <span className="required">*</span>
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Action"
                              id="Percentage"
                              value={2}
                              onChange={(e) => handleAction(e.target.value)}
                              checked={action == 2}
                            />
                            &nbsp;
                            <label
                              className="form-check-label"
                              htmlFor="Percentage"
                            >
                              Percentage <span className="required">*</span>
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="row">
                              {action == 0 ? (
                                <div className="col-6"></div>
                              ) : null}
                              {action == 1 ? (
                                <div className="col-12">
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      id="Action"
                                      className="form-control"
                                      placeholder="Amount"
                                      value={amount}
                                      min="1"
                                      step="0.1"
                                      onChange={(e) => handleAmount(e)}
                                      required
                                    ></input>
                                    <span className="input-group-text">$</span>
                                  </div>
                                </div>
                              ) : null}
                              {action == 2 ? (
                                <div className="col-12">
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      id="Action"
                                      className="form-control"
                                      placeholder="Percentage"
                                      value={percentage}
                                      onChange={(e) =>
                                        setPercentage(
                                          parseFloat(e.target.value)
                                        )
                                      }
                                      min="1"
                                      max="100"
                                      step="1"
                                      required
                                    ></input>
                                    <span className="input-group-text">%</span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div
                            className="form-group col-3 "
                            style={{ margin: "auto 0" }}
                          >
                            <div className="m-zAuto">
                              <div className="form-check pl-38">
                                <label
                                  className="form-check-label"
                                  htmlFor="IsCoupon"
                                >
                                  Coupon
                                </label>
                                <input
                                  type="checkbox"
                                  id="IsCoupon"
                                  className="form-check-input"
                                  value={isCoupon}
                                  checked={isCoupon}
                                  onChange={(e) => handleIsCoupon(e)}
                                />
                              </div>
                            </div>
                          </div>

                          {isCoupon ? (
                            <div className="col-3">
                              <label>
                                Coupon Code <span className="required">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                id="CouponCode"
                                className="form-control"
                                placeholder="Code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                required
                              ></input>
                            </div>
                          ) : null}

                          {isCoupon ? (
                            <div className="col-3">
                              <label>
                                Limit <span className="required">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  id="UserLimit"
                                  className="form-control"
                                  placeholder="Limit"
                                  min="0"
                                  value={limit}
                                  onChange={(e) => handleLimit(e)}
                                  required
                                ></input>
                                <span className="input-group-text">
                                  / coupon
                                </span>
                              </div>
                            </div>
                          ) : null}

                          {isCoupon ? (
                            <div className="col-3">
                              <label>
                                Usage <span className="required">*</span>{" "}
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  id="UsageTries"
                                  className="form-control"
                                  placeholder="Usage"
                                  min="0"
                                  value={usage}
                                  onChange={(e) =>
                                    setUsage(parseFloat(e.target.value))
                                  }
                                  required
                                ></input>
                                <span className="input-group-text">
                                  / customer
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}

                    {couponTypeId == 2 ? (
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Action"
                          id="None"
                          value={0}
                          onChange={(e) => handleAction(e.target.value)}
                          checked={action == 0}
                        />
                        &nbsp;
                        <label className="form-check-label" htmlFor="None">
                          None
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Action"
                          id="Amount"
                          value={1}
                          onChange={(e) => handleAction(e.target.value)}
                          checked={action == 1}
                        />
                        &nbsp;
                        <label className="form-check-label" htmlFor="Amount">
                          Amount <span className="required">*</span>
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Action"
                          id="Percentage"
                          value={2}
                          checked={action == 2}
                          onChange={(e) => handleAction(e.target.value)}
                        />
                        &nbsp; &nbsp;
                        <label
                          className="form-check-label"
                          htmlFor="Percentage"
                        >
                          Percentage <span className="required">*</span>
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                        <div className="row">
                          {action == 0 ? <div className="col-6"></div> : null}
                          {action == 1 ? (
                            <div className="col-12">
                              <div className="input-group">
                                <input
                                  type="number"
                                  id="Action"
                                  className="form-control"
                                  placeholder="Amount"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                  min="1"
                                  step="0.1"
                                  required
                                ></input>
                                <span className="input-group-text">$</span>
                              </div>
                            </div>
                          ) : null}
                          {action == 2 ? (
                            <div className="col-12">
                              <div className="input-group">
                                <input
                                  type="number"
                                  id="Action"
                                  className="form-control"
                                  placeholder="Percentage"
                                  value={percentage}
                                  min="1"
                                  max="100"
                                  step="1"
                                  onChange={(e) =>
                                    setPercentage(parseFloat(e.target.value))
                                  }
                                  required
                                ></input>
                                <span className="input-group-text">%</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {CouponID != 0 ? (
                      <div className="form-group row mt-4">
                        <div className="row">
                          <label>Applied To : </label>
                        </div>
                        <div className="row mt-3">
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToCountries"
                            >
                              Country
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToCountries"
                              className="form-check-input"
                              value={isAppliedToCountries}
                              checked={isAppliedToCountries}
                              onChange={(e) =>
                                setIsAppliedToCountries(e.target.checked)
                              }
                            />
                          </div>
                          <div className="form-check col-2 pl-38">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToGroups"
                            >
                              Group
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToGroups"
                              className="form-check-input"
                              value={isAppliedToGroups}
                              checked={isAppliedToGroups}
                              onChange={(e) =>
                                setIsAppliedToGroups(e.target.checked)
                              }
                            />
                          </div>

                          <div className="form-check col-2 pl-38">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToCategories"
                            >
                              Category
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToCategories"
                              className="form-check-input"
                              defaultValue={isAppliedToCategories}
                              checked={isAppliedToCategories}
                              onChange={(e) => handleIsAppliedToCategories(e)}
                            />
                          </div>

                          <div className="form-check col-2 pl-38">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToAttributes"
                            >
                              Attribute Value
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToAttributes"
                              className="form-check-input"
                              defaultValue={isAppliedToAttributes}
                              checked={isAppliedToAttributes}
                              onChange={(e) => handleAppliedToAttributes(e)}
                            />
                          </div>

                          <div className="form-check col-2 pl-38">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToProducts"
                            >
                              Product
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToProducts"
                              className="form-check-input"
                              defaultValue={isAppliedToProducts}
                              checked={isAppliedToProducts}
                              onChange={(e) => handleIsAppliedToProducts(e)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {isAppliedToCountries ? (
              <div className="card mb-5">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title">Assigned Countries</h5>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => setIsOpenUnassignedCountries(true)}
                      >
                        Assign Country
                      </button>
                      <Modal
                        show={isOpenUnassignedCountries}
                        onHide={() => setIsOpenUnassignedCountries(false)}
                        fullscreen={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Assign Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="row mb-3">
                                    <div className="col-8"></div>
                                    <div className="col-4 text-end tblSearchBar position-relative">
                                      <span>
                                        <MdManageSearch />
                                      </span>
                                      <input
                                        type="text"
                                        placeholder="Search any field in table"
                                        className="form-control form-control-sm"
                                        onChange={(e) =>
                                          handleSearchUnAssignedCountries(e)
                                        }
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="portalTable">
                                    <DataTable
                                      columns={unassignedCountriesColumns}
                                      data={filteredUnAssignedCountries}
                                      pagination
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 text-right">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setIsOpenUnassignedCountries(0)
                                  }
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  className="ml-20"
                                  onClick={AssignAllCountries}
                                >
                                  Assign All
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8"></div>
                    <div className="col-4 text-end tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search any field in table"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearchAssignedCountries(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="portalTable">
                    <DataTable
                      columns={assignedCountriesColumns}
                      data={filteredAssignedCountries}
                      pagination
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {/* Assigned Groups */}
            {isAppliedToGroups ? (
              <div className="card mb-5">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title">Assigned Groups</h5>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => setIsOpenUnassignedGroups(true)}
                      >
                        Assign Groups
                      </button>
                      <Modal
                        show={isOpenUnassignedGroups}
                        onHide={() => setIsOpenUnassignedGroups(false)}
                        fullscreen={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Assign Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="row mb-3">
                                  <div className="col-8"></div>
                                  <div className="col-4 text-end tblSearchBar position-relative">
                                    <span>
                                      <MdManageSearch />
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Search any field in table"
                                      className="form-control form-control-sm"
                                      onChange={(e) =>
                                        handleSearchUnAssignedGroups(e)
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <div className="portalTable">
                                  <DataTable
                                    columns={unassignedGroupsColumns}
                                    data={filteredUnAssignedGroups}
                                    pagination
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 text-right">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setIsOpenUnassignedGroups(false)
                                  }
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  className="ml-20"
                                  onClick={AssignAllGroups}
                                >
                                  Assign All
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8"></div>
                    <div className="col-4 text-end tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search any field in table"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearchAssignedGroups(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="portalTable">
                    <DataTable
                      columns={assignedGroupsColumns}
                      data={filteredAssignedGroups}
                      pagination
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {/* Assigned Categories */}
            {isAppliedToCategories ? (
              <div className="card mb-5">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title">Assigned Categories</h5>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => setIsOpenUnAssignedCategories(true)}
                      >
                        Assign Categories
                      </button>
                      <Modal
                        show={isOpenUnAssignedCategories}
                        onHide={() => setIsOpenUnAssignedCategories(false)}
                        fullscreen={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Assign Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="row mb-3">
                                  <div className="col-8"></div>
                                  <div className="col-4 text-end tblSearchBar position-relative">
                                    <span>
                                      <MdManageSearch />
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Search any field in table"
                                      className="form-control form-control-sm"
                                      onChange={(e) =>
                                        handleSearchUnAssignedCategories(e)
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <div className="portalTable">
                                  <DataTable
                                    columns={unassignedCategoriesColumns}
                                    data={filteredUnAssignedCategories}
                                    pagination
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 text-right">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setIsOpenUnAssignedCategories(false)
                                  }
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  className="ml-20"
                                  onClick={AssignAllCategories}
                                >
                                  Assign All
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8"></div>
                    <div className="col-4 text-end tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search any field in table"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearchAssignedCategories(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="portalTable">
                    <DataTable
                      columns={assignedCategoriesColumns}
                      data={filteredAssignedCategories}
                      pagination
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {isAppliedToAttributes ? (
              <div className="card mb-5">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title">Assigned Attribute Values</h5>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => setisOpenUnAssignedAttributeValues(true)}
                      >
                        Assign Attribute Value
                      </button>
                      <Modal
                        show={isOpenUnAssignedAttributeValues}
                        onHide={() => setisOpenUnAssignedAttributeValues(false)}
                        fullscreen={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Assign Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="row mb-3">
                                  <div className="col-8"></div>
                                  <div className="col-4 text-end tblSearchBar position-relative">
                                    <span>
                                      <MdManageSearch />
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Search any field in table"
                                      className="form-control form-control-sm"
                                      onChange={(e) =>
                                        handleSearchUnAssignedAttributeValues(e)
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <div className="portalTable">
                                  <DataTable
                                    columns={unassignedAttributeValuesColumns}
                                    data={filteredUnAssignedAttributeValues}
                                    pagination
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 text-right">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setisOpenUnAssignedAttributeValues(false)
                                  }
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  className="ml-20"
                                  onClick={AssignAllAttributeValues}
                                >
                                  Assign All
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8"></div>
                    <div className="col-4 text-end tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search any field in table"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearchAssignedAttributeValues(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="portalTable">
                    <DataTable
                      columns={assignedAttributeValuesColumns}
                      data={filteredAssignedAttributeValues}
                      pagination
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {isAppliedToProducts === true ? (
              <div className="card mb-5">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title">Assigned Products</h5>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setisOpenUnAssignedProducts(true);
                          // fetchUnAssignedProducts(
                          //   isAppliedToAttributes,
                          //   isAppliedToCategories,
                          //   assignedAttributeValues,
                          //   assignedCategories
                          // );
                        }}
                      >
                        Assign Product
                      </button>
                      <Modal
                        show={isOpenUnAssignedProducts}
                        onHide={() => setisOpenUnAssignedProducts(false)}
                        fullscreen={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Assign Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="row mb-3">
                                  <div className="col-8"></div>
                                  <div className="col-4 text-end tblSearchBar position-relative">
                                    <span>
                                      <MdManageSearch />
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Search any field in table"
                                      className="form-control form-control-sm"
                                      onChange={(e) =>
                                        handleSearchUnAssignedProducts(e)
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <div className="portalTable">
                                  {unassignedProductLoader.current ? (
                                    <div style={{ marginBottom: "20%" }}>
                                      <Loader />
                                    </div>
                                  ) : (
                                    <DataTable
                                      columns={unassignedProductsColumns}
                                      data={filteredUnAssignedProducts}
                                      pagination
                                      expandableRows={true}
                                      expandableRowsComponent={
                                        UnAssignedProductsExpandedComponent
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 text-right">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setisOpenUnAssignedProducts(false)
                                  }
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  className="ml-20"
                                  onClick={AssignAllProducts}
                                >
                                  Assign All
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8"></div>
                    <div className="col-4 text-end tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search any field in table"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearchAssignedProducts(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="portalTable">
                    {/* <DataTable
                      columns={assignedProductsColumns}
                      data={filteredAssignedProducts}
                      pagination
                      expandableRows
                      expandableRowsComponent={assignedExpandableRows}
                      expandableRowDisabled={(row) => row.disabled}
                    /> */}
                    {assignedProductLoader.current ?   <div style={{ marginBottom: "20%" }}>
                                      <Loader />
                                    </div> :
                    <DataTable
                    columns={assignedProductsColumns}
                    data={filteredAssignedProducts}
                    pagination
                    expandableRows={true}
                    expandableRowsComponent={
                      AssignedProductsExpandedComponent
                    }
                    // onRowExpandToggled={(e, row) =>
                    //   fetchAssignedProductVariations(e, row)
                    // }
                  />
                    }
                    
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageCoupons;
