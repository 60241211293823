export const Variables ={
//  API_URL:"https://localhost:5001/api/",
    //PHOTO_URL:"https://localhost:44339/Photos/",


  // API_URL: "https://api-r.smallinsize.com/api/",
  // API_PUBLISHED: "https://api-r.smallinsize.com/Uploads/",
  // AIRWAY_BILL:"https://api-r.smallinsize.com/Uploads/LabelURL/"

  API_URL: "https://api.smallinsize.com/api/",
  API_PUBLISHED: "https://api.smallinsize.com/Uploads/",
  AIRWAY_BILL:"https://api.smallinsize.com/Uploads/LabelURL/",
  Excel_URL:"https://api.smallinsize.com/",
};
