import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import CustomSnipper from "../../CustomSnipper";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { BiEdit } from "react-icons/bi";
import "react-phone-number-input/style.css";
import { BsBuilding } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import { TiShoppingCart } from "react-icons/ti";
import { FaHeart } from "react-icons/fa";
import { RiFileListFill } from "react-icons/ri";
import { AiOutlineCheck, AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import Util from "../../Classes/Util";
import { MdLocationOn, MdOutlinePhoneIphone } from "react-icons/md";
import { RiMailCheckFill } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import { Row, Col, Button, Tab, Nav, Card } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

function CustomerDetails() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  const [detailsLoader, setDetailsLoader] = useState(false);
  let { CustomerId } = useParams();
  const [customer, setCustomer] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, seWishlistItems] = useState([]);
  const [addressBooks, setAddressBooks] = useState([]);
  const [addressBook, setAddressBook] = useState({});
  const [defaultAddress, setDefaultAddress] = useState({});
  const [isOpen, setIsOpen] = useState("");
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const PhoneNumberChange = async (e) => {
    if (e !== undefined) {
      if (isPossiblePhoneNumber(e)) {
        setAddressBook((prevState) => ({
          ...prevState,
          PhoneNumber: e,
        }));
      }
    }
  };

  function IsValidAddressBook() {
    if (
      addressBook.Address1 != "" &&
      addressBook.Address2 != "" &&
      addressBook.City != "" &&
      isPossiblePhoneNumber(addressBook.PhoneNumber)
    ) {
      return true;
    } else {
      return false;
    }
  }
  function IsValidCustomerDetails() {
    const EmailRegex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$");
    var validEmail = EmailRegex.test(customer.Email);
    if (
      customer.FirstName != "" &&
      customer.LastName != "" &&
      customer.Email != "" &&
      validEmail
    ) {
      return true;
    } else {
      toast.error("Please Fill All Required Fields");
      return false;
    }
  }

  const UpdateAddressBook = async (AddressBookId) => {
    if (IsValidAddressBook()) {
      let response = await fetch(
        Variables.API_URL + "Customer/UpdateAddressBook/" + AddressBookId,
        {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
          body: JSON.stringify({
            Address1: addressBook.Address1,
            Address2: addressBook.Address2,
            City: addressBook.City,
            IsDefault: addressBook.IsDefault,
            IsSamePerson: addressBook.IsSamePerson,
            IsSameShippingAddress: addressBook.IsSameShippingAddress,
            PhoneNumber: addressBook.PhoneNumber.replaceAll("+", ""),
          }),
        }
      );
      if (response.ok === false) {
        response = await response.json();
        toast.error(response);
      } else {
        toast.success("Updated Successfully");
        setIsOpen(false);
        Getcustomer();
      }
    } else {
      toast.error("Please Fill All Required Fields");
    }
  };

  const UpdateDetails = async () => {
    debugger;
    if (IsValidCustomerDetails()) {
      let response = await fetch(
        Variables.API_URL + "Customer/UpdateCustomerDetails/" + CustomerId,
        {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
          body: JSON.stringify({
            FirstName: customer.FirstName,
            LastName: customer.LastName,
            Email: customer.Email,
          }),
        }
      );
      if (response.ok === false) {
        response = await response.json();
        toast.error(response);
      } else {
        toast.success("Updated Successfully");
        setIsOpen(false);
      }
    }
  };
  const addressBooksColumns = [
    {
      name: "#",
      selector: (row) => row.AddressBookId,
      sortable: true,
    },
    {
      name: "Address 1",
      selector: (row) => row.Address1,
      sortable: true,
    },
    {
      name: "Address 2",
      selector: (row) => row.Address2,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.PhoneNumber,

      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.State,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <>
            {permissions.ManageAddressBook && (
              <button
                className=" btn btn-outline-secondary btn-sm"
                onClick={() => {
                  setIsOpen(row);
                  setAddressBook(row);
                }}
              >
                <BiEdit data-tip="Manage Address Book" />
                <ReactTooltip
                  place="bottom"
                  className="tooltip"
                  effect="solid"
                />
              </button>
            )}

            <Modal
              // fullscreen={true}
              show={row === isOpen}
              onHide={() => setIsOpen(0)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Manage Address Book</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-6 mb-3">
                      <label>City</label>

                      <input
                        type="text"
                        value={addressBook.City}
                        onChange={(e) =>
                          setAddressBook((prevState) => ({
                            ...prevState,
                            City: e.target.value,
                          }))
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-6 mb-3">
                      <label>Phone Number</label>

                      <PhoneInput
                        placeholder="Phone Number"
                        country="AE"
                        defaultCountry="AE"
                        className="phoneValidate"
                        value={addressBook.PhoneNumber}
                        countryCallingCodeEditable={false}
                        onChange={(e) => PhoneNumberChange(e)}
                        international={false}
                        required={true}
                      />
                    </div>

                    <div className="col-6 mb-3">
                      <label>Address 1</label>
                      <input
                        type="text"
                        value={addressBook.Address1}
                        onChange={(e) =>
                          setAddressBook((prevState) => ({
                            ...prevState,
                            Address1: e.target.value,
                          }))
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label>Address 2</label>
                      <input
                        type="text"
                        value={addressBook.Address2}
                        onChange={(e) =>
                          setAddressBook((prevState) => ({
                            ...prevState,
                            Address2: e.target.value,
                          }))
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={addressBook.IsDefault}
                          id="flexCheckDefault"
                          onChange={(e) =>
                            setAddressBook((prevState) => ({
                              ...prevState,
                              IsDefault: e.target.checked,
                            }))
                          }
                          checked={addressBook.IsDefault}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Is Default
                        </label>
                      </div>
                    </div>
                    <div className="col-6 mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={addressBook.IsSameShippingAddress}
                          id="flexCheckDefault"
                          onChange={(e) =>
                            setAddressBook((prevState) => ({
                              ...prevState,
                              IsSameShippingAddress: e.target.checked,
                            }))
                          }
                          checked={addressBook.IsSameShippingAddress}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Is Same Shipping Address
                        </label>
                      </div>
                    </div>
                    <div className="col-6 mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={addressBook.IsSamePerson}
                          id="flexCheckDefault"
                          onChange={(e) =>
                            setAddressBook((prevState) => ({
                              ...prevState,
                              IsSamePerson: e.target.checked,
                            }))
                          }
                          checked={addressBook.IsSamePerson}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Is Same Person
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="container">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                      <Button
                        variant="btn btn-primary"
                        className="form-control"
                        onClick={() => UpdateAddressBook(row.AddressBookId)}
                      >
                        Update
                      </Button>
                    </div>

                    <div className="col-4">
                      <Button
                        variant="secondary"
                        className="form-control"
                        onClick={() => setIsOpen(0)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        </div>
      ),
    },
  ];
  const cartItemsColumns = [
    {
      name: "#",
      selector: (row) => row.CartIemId,
      sortable: true,
    },
    {
      name: "Product Image",
      selector: (row) => (
        <div>
          <img
            src={Variables.API_PUBLISHED + row.Image}
            className="form-control productImage"
          />
        </div>
      ),

      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.ProductName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
    },

    {
      name: "Quantity",
      selector: (row) => row.Quantity,
      sortable: true,
    },
    {
      name: "Price Per Item",
      selector: (row) => row.Price + ".00 $",
      sortable: true,
    },
  ];
  const wishlistItemsColumns = [
    {
      name: "#",
      selector: (row) => row.WishlistItemId,
      sortable: true,
    },

    {
      name: "Product Image",
      selector: (row) => (
        <div>
          <img
            src={Variables.API_PUBLISHED + row.Image}
            className="form-control productImage"
          />
        </div>
      ),

      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.ProductName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <div className="portalTable">
      <DataTable
        className="ml-3"
        columns={orderDetailsColumns}
        data={data.Products}
      />
    </div>
  );
  const ordersColumns = [
    {
      name: "City",
      selector: (row) => row.City,
      sortable: true,
    },
    {
      name: "Ordered Date",
      selector: (row) => row.OrderDate,
      sortable: true,
    },
    {
      name: "Is Cancelled",
      selector: (row) =>
        row.IsCancelled ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.Total,
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.PaymentMethod,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
  ];

  const orderDetailsColumns = [
    {
      name: "#",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: "Product Image",
      selector: (row) => (
        <div>
          <img
            src={Variables.API_PUBLISHED + row.Image}
            className="productImage"
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.ProductName,
      sortable: true,
    },
    {
      name: "Size",
      selector: (row) => row.Size,
      sortable: true,
    },
    {
      name: "Color",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.Quantity,
      sortable: true,
    },
    {
      name: "Price Per Item",
      selector: (row) => row.PricePerItem,
      sortable: true,
    },

    {
      name: "Total",
      selector: (row) => row.Total,
      sortable: true,
    },
  ];

  const Getcustomer = async () => {
    setDetailsLoader(true);
    let response = await fetch(
      Variables.API_URL + "Customer/Getcustomer/" + CustomerId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setCustomer(result);
      setCartItems(result.CartItems);
      seWishlistItems(result.WishlistItems);
      setOrders(result.Orders);
      setAddressBooks(result.AddressBook);
      setDetailsLoader(false);
      setDefaultAddress(result.DefaultAddress);
    }
  };

  useEffect(() => {
    Getcustomer();
  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12">
            <h2>Customer Details: {customer.FirstName + " " + customer.LastName}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Customer">Customers <AiOutlineRight /></Link></li>
              <li>Manage Customer</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">{customer.FirstName + " " + customer.LastName}</h4>
                </div>
                <Button
                  variant="secondary"
                  onClick={() => navigate("/Customers")}
                >
                  Cancel
                </Button>
              </div>
              {detailsLoader === true ? (
                <div className="text-center">
                  <CustomSnipper />
                </div>
              ) : (
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 justify-content-center">
                          <br />
                          <h3>
                            {customer.FirstName + " " + customer.LastName}{" "}
                          </h3>
                          <br />
                          <div className="text-muted">
                            <RiMailCheckFill /> : {customer.Email} <br />
                            <MdLocationOn /> :{" "}
                            {defaultAddress.Country != ""
                              ? defaultAddress.Country
                              : "-"}{" "}
                            <br />
                            <BiLogOut /> :{customer.LastLogin} <br />
                            <MdOutlinePhoneIphone /> :{" "}
                            {defaultAddress.PhoneNumber != ""
                              ? defaultAddress.PhoneNumber
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />

                      <div className="new-user-info">
                        <div className="bd-example">
                          <Tab.Container defaultActiveKey="Details">
                            <Nav>
                              <Nav
                                variant="tabs"
                                className="mb-3"
                                id="nav-tab"
                                role="tablist"
                              >
                                <Nav.Link
                                  eventKey="Details"
                                  variant=" d-flex align-items-center"
                                  id="nav-Details-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-Details"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-Details"
                                  aria-selected="true"
                                >
                                  <CgDetailsMore /> Customer Details
                                </Nav.Link>
                                <Nav.Link
                                  eventKey="AddressBooks"
                                  variant=" d-flex align-items-center"
                                  id="nav-Details-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-Details"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-Details"
                                  aria-selected="true"
                                >
                                  <BsBuilding /> Address Books
                                </Nav.Link>
                                <Nav.Link
                                  eventKey="Cart"
                                  variant=""
                                  id="nav-Cart-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-Cart"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-Cart"
                                  aria-selected="false"
                                >
                                  <TiShoppingCart /> Cart
                                </Nav.Link>

                                <Nav.Link
                                  eventKey="WishList"
                                  variant=""
                                  id="nav-WishList-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-WishList"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-WishList"
                                  aria-selected="false"
                                >
                                  <FaHeart /> WishList
                                </Nav.Link>
                                <Nav.Link
                                  eventKey="Orders"
                                  variant=""
                                  id="nav-WisOrdershList-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-Orders"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-Orders"
                                  aria-selected="false"
                                >
                                  <RiFileListFill /> Orders
                                </Nav.Link>
                              </Nav>
                            </Nav>

                            <Tab.Content>
                              <Tab.Pane
                                className=" fade show"
                                eventKey="Details"
                                id="nav-Details"
                                role="tabpanel"
                                aria-labelledby="nav-Details-tab"
                              >
                                <br />

                                <div className="row">
                                  <div className="text-right">
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => UpdateDetails()}
                                    >
                                      Update Details
                                    </button>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <label>First Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={customer.FirstName}
                                      onChange={(e) =>
                                        setCustomer((prevState) => ({
                                          ...prevState,
                                          FirstName: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label>Last Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={customer.LastName}
                                      onChange={(e) =>
                                        setCustomer((prevState) => ({
                                          ...prevState,
                                          LastName: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <label>Email</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={customer.Email}
                                      onChange={(e) =>
                                        setCustomer((prevState) => ({
                                          ...prevState,
                                          Email: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane
                                className=" fade"
                                id="nav-Cart"
                                eventKey="AddressBooks"
                                role="tabpanel"
                                aria-labelledby="nav-Cart-tab"
                              >
                                {detailsLoader ? (
                                  <CustomSnipper />
                                ) : (
                                  <div className="portalTable">
                                    <DataTable
                                      columns={addressBooksColumns}
                                      data={addressBooks}
                                      pagination
                                      paginationComponentOptions={
                                        paginationComponentOptions
                                      }
                                    />
                                  </div>
                                )}
                              </Tab.Pane>

                              <Tab.Pane
                                className=" fade"
                                id="nav-Cart"
                                eventKey="Cart"
                                role="tabpanel"
                                aria-labelledby="nav-Cart-tab"
                              >
                                {detailsLoader ? (
                                  <CustomSnipper />
                                ) : (
                                  <div className="portalTable">
                                    <DataTable
                                      columns={cartItemsColumns}
                                      data={cartItems}
                                      pagination
                                      paginationComponentOptions={
                                        paginationComponentOptions
                                      }
                                    />
                                  </div>
                                )}
                              </Tab.Pane>

                              <Tab.Pane
                                className=" fade"
                                id="nav-WishList"
                                eventKey="WishList"
                                role="tabpanel"
                                aria-labelledby="nav-WishList-tab"
                              >
                                {detailsLoader ? (
                                  <CustomSnipper />
                                ) : (
                                  <div className="portalTable">
                                    <DataTable
                                      columns={wishlistItemsColumns}
                                      data={wishlistItems}
                                      pagination
                                      paginationComponentOptions={
                                        paginationComponentOptions
                                      }
                                    />
                                  </div>
                                )}
                              </Tab.Pane>
                              <Tab.Pane
                                className=" fade"
                                id="nav-Orders"
                                eventKey="Orders"
                                role="tabpanel"
                                aria-labelledby="nav-Orders-tab"
                              >
                                {detailsLoader ? (
                                  <CustomSnipper />
                                ) : (
                                  <div className="portalTable">
                                    <DataTable
                                      columns={ordersColumns}
                                      data={orders}
                                      pagination
                                      expandableRows
                                      expandableRowsComponent={ExpandedComponent}
                                    />
                                  </div>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default CustomerDetails;
