import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Util from "../../Classes/Util";
import { BiEdit, BiHide, BiShowAlt, BiTrash } from "react-icons/bi";
import AttributesMedia from "./AttributesMedia";
const ManageAttributesValues = (props) => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  let { navigate } = useNavigate();
  let { AttributeID } = useParams();
  const [attributeValueName, setAttributeValueName] = useState("")
  const [attributeName, setAttributeName] = useState("")
  const [listAttributeValues, setListAttributeValues] = useState([]);
  const [color, setColor] = useColor("");
  const [image, setImage] = useState("");
  const [button, setButton] = useState("");
  const [type, setType] = useState("");
  const [update, setUpdate] = useState(false);
  const [id, setID] = useState("");
  const[order,setOrder]=useState(0)
  const [showingImage, setShowingImage] = useState("")
  async function GetAttribute() {
    const URL =
      Variables.API_URL + "AttributeValues/GetAttributeValues/" + AttributeID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setListAttributeValues(listItems)
  }

  const DeleteAttributeValue = async (AttributeValueId) => {
    let response = await fetch(
      Variables.API_URL + "AttributeValues/DeleteAttributeValue/" + AttributeValueId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetAttribute();
    } else {
      toast.error("Please Check With Administrator");
    }
  };


  async function GetAttributeValue(id) {
    const URL =
      Variables.API_URL + "AttributeValues/GetAttributeValue/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
  
    setAttributeValueName(listItems.AttributeValueName);
    setOrder(listItems.PriorityOrder)
    if (type == 1) {
      setColor(toColor("hex", listItems.AttributesValue))
    }
    if (type == 2) {
      setImage(listItems.AttributesValue)
    }
    if (type == 3) {
      setButton(listItems.AttributesValue)
    }
    setID(listItems.AttributeValueId)
    setUpdate(true)
  
  }

  async function GetAttributeById(id) {
    const URL = Variables.API_URL + "Attributes/GetAttributeById/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setType(listItems[0].AttributeTypeId);
    setAttributeName(listItems[0].Attribute1);
  }


  function HandleSubmit(e) {
    debugger;
    e.preventDefault();
    if (image != "") {
      if (update == false) {
        submitValues(image)
      }
      else {
        UpdateValues(image)

      }
      return false;
    }

    if (type == 3) {
      if (update == false) {
        submitValues(button)
      }
      else {
        UpdateValues(button)

      }
      return false;
    }
    if (color.hex != "#121212") {

      if (update == false) {
        submitValues(color.hex)
      }
      else {
        UpdateValues(color.hex)

      }
      return false;
    }
    else {
      toast.error("Please fill all fields");
    }

  }
  function handleCancel() {
    setAttributeValueName("")
    setUpdate(false)
    if (button !== "") {
      setButton("")
    }
    if (color !== "") {
      window.location.href = "/Attributes/Value/" + AttributeID
    }
    if (image !== "") {
      setImage("")
    }

  }



  async function TooglePublishAttributeValue(id) {
    let response = await fetch(
      Variables.API_URL + "AttributeValues/TooglePublishAttributeValue/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetAttribute();
    } else {
      toast.error(result);
    }
  }

  async function submitValues(v) {

    let response = await fetch(Variables.API_URL + "AttributeValues/AddValue", {
      method: "Post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        AttributeId: AttributeID,
        AttributesValue: v,
        AttributeValueName: attributeValueName,
        PriorityOrder:order
      }),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      setAttributeValueName("");
      if (type == 1) {
        setColor(toColor("hex", '#121212'))
      }
      if (type == 2) {
        setImage('')
      }
      if (type == 3) {
        setButton('')
      }
      setOrder(0);
      GetAttribute();
    } else {
      toast.error(result);
    }


  }


  async function UpdateValues(v) {
    let response = await fetch(
      Variables.API_URL + "AttributeValues/UpdateAttributeValue/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeId: AttributeID,
          AttributesValue: v,
          AttributeValueName: attributeValueName,
          PriorityOrder:order
        }),
      }
    );
    let result = await response.json();
    if (response.status == 200) {
      toast.success(result);
      setUpdate(false);
      setAttributeValueName("");
      if (type == 1) {
        setColor(toColor("hex", '#121212'))
      }
      if (type == 2) {
        setImage('')
      }
      if (type == 3) {
        setButton('')
      }
      setOrder(0);
      GetAttribute();
    } else {
      toast.error(result);
    }
  }

  const columns = [
    {
      name: "Attribute Value ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
      reorder: true,
    },

    {
      name: "Attribute Value Name",
      selector: (row) => row.AttributeValueName,
      sortable: true,
      reorder: true,
    },

    {
      name: "Attribute Value",

      selector: (row) => row.Attribute1
      ,
      sortable: true,
      reorder: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.PublishAttributeValue === true ? (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishAttributeValue(row.AttributeValueId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}
          {permissions.ManageAttributeValue === true ? (<button
            className="btn btn-outline-warning btn-sm ms-2"
            onClick={() => GetAttributeValue(row.AttributeValueId)}
          >
            {" "}
            <BiEdit />{" "}
          </button>) : null}
          {permissions.DeleteAttributeValue && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => DeleteAttributeValue(row.AttributeValueId)}><BiTrash /></button>)}


        </div>
      ),

    },
  ];
  useEffect(() => {
    GetAttribute();

    GetAttributeById(AttributeID);
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-12">
                    <h3>Assign Values to Attribute '{attributeName}'</h3>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {permissions.ManageAttributeValue === true ? (
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-4">
                        <div className="row"> 
                        <label>Attribute Value Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter Attribute Value"
                          value={attributeValueName}
                          onChange={(e) => setAttributeValueName(e.target.value)}
                        />
 </div>
 <div className="row">
                          <label> Priority Order </label>
                          <input type="number" value={order} onChange={(e)=>setOrder(parseInt(e.target.value))} className="form-control" min={0}/>
                        </div>
 <div className="row"> 
                        {type == 1 ? (
                          <>
                            <label>Attribute Value Color</label>
                            <ColorPicker defaultValue='#452135'
                              width={250}
                              height={250}
                              color={color}
                              onChange={setColor}
                              hideHSV
                              hideRGB
                            />
                          </>
                        ) : null}

                        {type == 2 ? (
                          <>
                            <label> Upload Image</label>
                            <AttributesMedia setImage={setImage} setShowingImage={setShowingImage} />


                            {showingImage !== "" ? (
                              <img src={Variables.API_PUBLISHED + showingImage} className="form-control" />

                            ) : null}
                          </>
                        ) : null}

                        {type == 3 ? (
                          <>
                            <label> URL</label>
                            <input
                              type="text"
                              className="form-control"
                              value={button}
                              onChange={(e) => setButton(e.target.value)}
                              placeholder="URL"
                            />
                          </>
                        ) : null}
                        </div>
                       

</div>
                      <div className="col-8 text-end">
                        <button className="btn btn-secondary" style={{ margin: "2px" }} onClick={handleCancel}>Cancel</button>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => HandleSubmit(e)}
                        >
                          {update === true ? 'update' : 'save'}
                        </button>




                      </div>

                    </div>
                  </form>
                ) : null}
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={listAttributeValues}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};
export default ManageAttributesValues;
