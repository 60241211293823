import React, { useState, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineRight } from "react-icons/ai";
const ManageMaterial = () => {
  const [material, setmaterial] = useState({ Name: "", IsPublished: false });
  let { MaterialID } = useParams();
  const navigate = useNavigate();

  async function Getmaterial() {
    const URL = Variables.API_URL + "Material/GetMaterial/" + MaterialID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setmaterial(listItems)
  }
  function IsValid() {
    if (material.Name == "") {
      toast.error("Name Is Required");
      return false;
    } else {
      return true;
    }
  }

  async function HandleSubmit() {
    if (IsValid()) {
      if (MaterialID == 0) {
        let response = await fetch(Variables.API_URL + "Material/AddMaterial", {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Name: material.Name,
            IsPublished: material.IsPublished,
          }),
        });
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          navigate("/Materials");
        } else {
          toast.error(result);
        }
      } else {
        let response = await fetch(
          Variables.API_URL + "Material/UpdateMaterial" ,
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                MaterialId:MaterialID,
              Name: material.Name,
              IsPublished: material.IsPublished,
            }),
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          navigate("/Materials");
        } else {
          toast.error(result);
        }
      }
    }
  }
  //#region Use Effect
  useEffect(() => {
    if(MaterialID!=0){
    Getmaterial();
    }
  }, []);
  //#endregion Use Effect
  return (
    <div className="rightSide">
    <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{MaterialID == "0" ? "Add" : "Update"} Material</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Materials">Material <AiOutlineRight /></Link></li>
              <li>Manage Material</li>
            </ul>
          </div>
        </div>
      <div className="row">
        <div className="col-12">
          {/* <form> */}
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-8">
                  <h3>{material.Name}</h3>
                </div>
                <div className="col-lg-4 text-right">
                  <NavLink to="/Materials" className="btn btn-secondary">
                    Cancel
                  </NavLink>
                  <button
                    type="submit"
                    className="btn btn-primary ms-2"
                    onClick={HandleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-6">
                      <label>
                        {" "}
                        Name <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        id="name"
                        placeholder="Material Name"
                        onChange={(e)=>setmaterial((prevState) => ({
                          ...prevState,
                          Name: e.target.value,
                        }))}
                        value={material.Name}
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="form-check" style={{marginTop:"30px"}}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={material.IsPublished}
                          checked={material.IsPublished}
                          id="flexCheckDefault"
                          onChange={(e)=>setmaterial((prevState) => ({
                            ...prevState,
                            IsPublished: e.target.checked,
                          }))}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Is Published ?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default ManageMaterial;
