import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Card,
  Form,
  Tab,
  Nav,
  Modal,
  Button,
  Spinner,
  NavLink,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import { Variables } from "../../Variations/Variations";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CustomSnipper from "../../CustomSnipper";
import { RiUserHeartFill,RiBillFill } from "react-icons/ri";
import Loader from "../../Layout/Loader";
import {
  MdPayment,
  MdLocationPin,
  MdLocalShipping,
  MdPhoneIphone,
  MdOutlineMarkEmailRead,
  MdLocationCity,
} from "react-icons/md";
import { GiNotebook } from "react-icons/gi";
import { CgDollar } from "react-icons/cg";
import { AiOutlineRight,AiOutlineCheck,AiOutlineClose } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
function ListOrders() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [orders, setOrders] = useState([]);
  const [orderStatus, SetOrderStatus] = useState([]);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState(0);
  const navigate=useNavigate();
  const [orderToUpdate, setOrderToUpdate] = useState({
    OrderId: 0,
    OrderStatusId: 0,
  });
  const[state,setState]=useState(false)
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [reason, setReason] = useState("");
  let {OrderId}=useParams();
  const [search, setSearch] = useState("");
  const [isOpenSearchField, setIsOpenSearchField] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [countryName, setCountryName] = useState("");
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const [orderDetails, setOrderDetails] = useState([
    {
      OrderID: 0,
      Quantity: 0,
      TotalPriceItem: 0,
      ProductVariationId: 0,
      Discount: 0,
      Color: "",
      Size: "",
      ProductDetails: {
        ProductId: 0,
        ProductName: "",
        Description: "",
        ImageUrl: "",
      },
    },
  ]);
  // Get orders Stautses
  const GetOrdersStatus = async () => {
    let response = await fetch(
      Variables.API_URL + "Order/GetAllOrderStatuses",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      SetOrderStatus(result);
    }
  };

  // Get orders
  const GetOrders = async () => {
    let response = await fetch(Variables.API_URL + "Order/GetOrders", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOrders(result);
      if(window.location.pathname.includes("/Order/") || window.location.pathname.includes("/order/") ){  
        setFilteredOrder(result.filter((value)=>value.OrderId==OrderId));
       setOrderDetailsModal(parseInt(OrderId));
  
        GetOrderByOrderId(OrderId);
      } else{
        setFilteredOrder(result)
      }
    }
  };

  //Get Order Details
  const GetOrderDetails = async (row) => {
    let response = await fetch(
      Variables.API_URL + "Order/GetOrderDetails/" + row.OrderId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setOrderDetails(result);
      GetCountry(row.AddressBook.CountryCode);
      setOrderDetailsModal(row.OrderId);
    }
  };
  const GetOrderByOrderId = async (OrderId) => {
    let response = await fetch(
      Variables.API_URL + "Order/GetOrderById/" + OrderId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      GetOrderDetails(result);
    }
  };
  const RejectOrder = async () => {
    var str = reason;
    var wordCount = str.match(/(\w+)/g).length;

    if (reason != "") {
      if (wordCount > 2) {
        let response = await fetch(
          Variables.API_URL + "Order/RejectOrder/" + orderToUpdate.OrderId,
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Reason: reason,
            }),
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          setReason("");
          setOrderDetailsModal(0);
          setRejectModal(false);
          GetOrders();
          setOrderToUpdate({
            OrderId: 0,
            statusId: 0,
          });
        } else {
          toast.error(result);
        }
      } else {
        toast.error("Please Enter A Valid Reason");
      }
    } else {
      toast.error("Please Enter Your Reason");
    }
  };

  const SaveChanges = async (e) => {
    setState(true)
    if (orderToUpdate.OrderStatusId != 0) {
      if (orderToUpdate.OrderStatusId != 3) {
        let response = await fetch(
          Variables.API_URL + "Order/UpdateOrderStatus",
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              OrderId: orderToUpdate.OrderId,
              OrderStatusId: orderToUpdate.OrderStatusId,
            }),
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          setOrderToUpdate({
            OrderId: 0,
            statusId: 0,
          });
          setOrderDetailsModal(0);
          GetOrders();
          setTimeout(() => {
            setState(false)
          }, 500);
        } else {
          toast.error(result);
        }
      } else {
        setOrderDetailsModal(0);
        setRejectModal(true);
      }
    } else {
      toast.error("Please Choose A Status");
    }
  };

  // Cancel Update
  const CancelUpdate = () => {
    setOrderToUpdate({
      OrderId: 0,
      statusId: 0,
    });
    setOrderDetailsModal(0);
    setFilteredOrder(orders);
    navigate("/Orders")
  };

  const columns = [
    {
      name: "#Order Number",
      selector: (row) => row.OrderNumber,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.CustomerName,
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.PaymentMethodName,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => row.Date,
      sortable: true,
    },
    {
      name: "Total Without Shipping",
      selector: (row) => row.OrderTotal,
      sortable: true,
    },
    {
      name: "Shipping Rate",
      selector: (row) => row.ShippingRate,
      sortable: true,
    },
    {
      name: "Status ",
      selector: (row) => row.OrderStatusName,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.ManageOrder && (
            <Link
              className="btn btn-outline-secondary btn-sm "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              to={"#"}
              data-original-title="Edit"
              onClick={() => GetOrderDetails(row)}
            >
              <BiEdit />
            </Link>
          )}
          <Modal
            show={orderDetailsModal == row.OrderId}
            size="xl"
            onHide={() => {setOrderToUpdate({ OrderId: 0, OrderStatusId: 0 });navigate("/Orders");setOrderDetailsModal(0)}}
            aria-labelledby="contained-modal-title-vcenter"
            animation={true}
            autoFocus={true}
            keyboard={true}
            scrollable={true}
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>{"Order# : " + row.OrderNumber}</Modal.Title>
            </Modal.Header>
          {state ? 
          <Loader/>
        :
        
        <>
          <Modal.Body>
              <div className="row">
                <Col md="9"> </Col>
                <Col md="3">
                  {row.OrderStatusId != 3 && row.OrderStatusId != 6 ? (
                    <select
                      className="form-control"
                      value={orderToUpdate.OrderStatusId}
                      onChange={(e) =>
                        setOrderToUpdate({
                          OrderId: row.OrderId,
                          OrderStatusId: e.target.value,
                        })
                      }
                    >
                      <option value={0}>--Select--</option>

                      {row.OrderStatusId == 2 ? (
                        <>
                          {orderStatus
                            .filter((child) => child.OrderStatusId > 3)
                            .map((o, i) => {
                              return (
                                <option value={o.OrderStatusId} key={i}>
                                  {" "}
                                  {o.StatusName}
                                </option>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {orderStatus
                            .filter(
                              (child) =>
                                child.OrderStatusId != row.OrderStatusId &&
                                child.OrderStatusId > row.OrderStatusId
                            )
                            .map((o, i) => {
                              return (
                                <option value={o.OrderStatusId} key={i}>
                                  {" "}
                                  {o.StatusName}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </select>
                  ) : (
                    <input
                      type="text"
                      disabled={true}
                      value={row.OrderStatusName}
                      className="form-control"
                    />
                  )}
                </Col>
              </div>
              <div className="text-muted" style={{ marginBottom: "20px" }}>
                <div className="row">
                  <div className="col-6">
                    Customer <RiUserHeartFill /> : {row.CustomerName} <br />
                    Address <MdLocationPin /> : {row.AddressBook.Address1}{" "}
                    <br />
                    Country <MdLocationCity /> : {countryName} -{" "}
                    {row.AddressBook.CountryCode} <br />
                    To <MdLocalShipping /> : {row.AddressBook.FirstName}{" "}
                    {row.AddressBook.LastName} <br />
                    Email <MdOutlineMarkEmailRead /> : {row.AddressBook.Email}
                  </div>
                  <div className="col-6">
                    Phone number <MdPhoneIphone /> :{" "}
                    {row.AddressBook.PhoneNumber}
                    <br />
                    Payment <MdPayment /> : {row.PaymentMethodName} <br />
                    Total <CgDollar /> <b>
                      {" : " + row.OrderTotal + ".00 "}{" "}
                    </b>{" "}
                    <br />
                    Notes <GiNotebook /> : {row.Notes}
                    <br />
                   
                  {row.AirwayBill!="" && <p> <RiBillFill/> : <button onClick={()=>openInNewTab(Variables.AIRWAY_BILL+row.AirwayBill) } className="btn btn-primary"> {Variables.AIRWAY_BILL+row.AirwayBill}</button></p>}  
                  </div>
                </div>
              </div>

              {orderDetails.map((detail, index) => (
                <div key={index}>
                  <Row key={index + "row"}>
                    <Col md="3">
                      <b>
                        {"Item : " +
                          (index + 1) +
                          " - " +
                          detail.ProductDetails.ProductName}{" "}
                        :
                      </b>
                      <hr className="mt-2" />
                    </Col>

                    <Row>
                      <Col md="3">
                        <img
                          src={
                            Variables.API_PUBLISHED +
                            detail.ProductDetails.ImageUrl
                          }
                          className="w-25 shadow-1-strong rounded mb-4 img-200 image-log"
                          alt="Boat on Calm Water"
                        />
                      </Col>
                      <Col md="3">
                        <b>Discount : {detail.Discount + " %"}</b>
                      </Col>
                      <Col md="3">
                        <b>Color : {detail.Color}</b>
                      </Col>
                      <Col md="3">
                        <b>Size : {detail.Size}</b>
                      </Col>
                     
                    </Row>
                    <Row>
                      <Col md="3"></Col>
                      <Col md="3">
                        <b>Quantity : {detail.Quantity}</b>
                      </Col>
                      <Col md="3"> <b> Original Price : {detail.OriginalPrice + ".00 $"}</b></Col>
                      <Col md="3">
                        <b>Total : {detail.TotalPriceItem + ".00 $"}</b>
                      </Col>
                      
                    </Row>
                    <Row>
                      <Col md="3" className="mt-3"></Col>
                    <Col md="3" className="mt-3">
                        <b>Sku : {detail.Sku}</b>
                      </Col>
                      <Col md="3" className="mt-3">
                        <b>Applied To Coupon : {detail.IsAppliedToCoupon ? <AiOutlineCheck/> : <AiOutlineClose/>}</b>
                       
                      </Col>
                      <Col md="3">
                        
                       
                      </Col>
                    </Row>
                    {detail.IsAppliedToCoupon &&
                         <Row >
                         
                         <Col md="3" className="mt-3" > </Col>
                         <Col md="3" className="mt-3" > <b> Coupons Applied : </b></Col> 
                          <Col md="6" ></Col>
                        <Col md="3"></Col>
                         {detail.Coupons.map((c,i)=>{
                          return ( 
                          <Col md="3" className="mt-3"  key={i}> <b>{i+1} - {c}</b></Col>
                      
                          )
                         })}
                        
                   </Row>
                    }
               
                  </Row>
                  <hr />
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => CancelUpdate()}>
                Close
              </Button>
              <Button variant="primary" onClick={(e) => SaveChanges()}>
                Save changes
              </Button>
            </Modal.Footer>
        </>
        }
         
         
          </Modal>
          <Modal show={rejectModal} onHide={() => setRejectModal(false)}>
            <Modal.Header>
              <Modal.Title>Reject Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-5">
                <Col md="6" sm="6" lg="12">
                  <label>Reason</label>
                  <textarea
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="form-control"
                    autoFocus={true}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setRejectModal(false);
                  setReason("");
                }}
              >
                Close
              </Button>
              <Button variant="primary" onClick={(e) => RejectOrder()}>
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  const handleFilter = (event) => {
    let value = event.target.value;
    setSearch(value);
    let result = [];
    if (value != "OrderNumber" && value != "Customer") {
      if (value == 0) {
        result = orders;
      } else {
        result = orders.filter(
          (child) => child.OrderStatusId == parseInt(value)
        );
      }
      if (value == "CashOn") {
        result = orders.filter(
          (child) => child.PaymentMethodName == "Cash On Delivery"
        );
      }
      if (value == "Card") {
        result = orders.filter((child) => child.PaymentMethodName == "Card");
      }
      setIsOpenSearchField(false);
    } else {
      setIsOpenSearchField(true);
      result = orders;
    }
    setFilteredOrder(result);
  };

  const handleSearch = (event) => {
    var value = event.target.value;
    setSearchField(value);
    let result = [];

    if (value === "") {
      result = orders;
    } else if (search == "OrderNumber") {
      result = orders.filter((child) =>
        String(child.OrderNumber).includes(String(value))
      );
    } else if (search == "Customer") {
      result = orders.filter(
        (child) =>
          child.AddressBook.FirstName.toLowerCase().includes(value) ||
          child.AddressBook.LastName.toLowerCase().includes(value) ||
          (
            child.AddressBook.FirstName.toLowerCase() +
            " " +
            child.AddressBook.LastName.toLowerCase()
          ).includes(value) ||
          (
            child.AddressBook.FirstName.toLowerCase() +
            child.AddressBook.LastName.toLowerCase()
          ).includes(value)
      );
    }

    setFilteredOrder(result);
  };
  const GetCountry = async (countryCode) => {
    let response = await fetch(
      Variables.API_URL + "Country/GetCoutryByCountryCode/" + countryCode,
      {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    setCountryName(result.CountryName);
  };
  useEffect(() => {
    GetOrdersStatus();
    GetOrders();
     
   

  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12">
            <h2>Orders</h2>
            <ul className="portalPath">
              <li>
                Home <AiOutlineRight />
              </li>
              <li>Orders</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body px-3">
                <div className="row mb-3">
                  {isOpenSearchField == false ? (
                    <>
                      <div className="col-8"></div>
                      <div className="col-4 text-end">
                        <select
                          className="form-select"
                          value={search}
                          onChange={(e) => handleFilter(e)}
                        >
                          <option value={"0"}>Filter By .. </option>
                          <option disabled style={{ fontSize: "large" }}>
                            Order Status
                          </option>
                          {orderStatus.map((s, i) => {
                            return (
                              <option value={s.OrderStatusId} key={i}>
                                {" "}
                                {s.StatusName}{" "}
                              </option>
                            );
                          })}
                          <option
                            value={"OrderNumber"}
                            style={{ fontSize: "large" }}
                          >
                            Order Number
                          </option>
                          <option
                            value={"Customer"}
                            style={{ fontSize: "large" }}
                          >
                            Customer Name
                          </option>
                          <option disabled style={{ fontSize: "large" }}>
                            Payment Method
                          </option>
                          <option
                            value={"CashOn"}
                            style={{ fontSize: "large" }}
                          >
                            Cash on delivery
                          </option>
                          <option value={"Card"} style={{ fontSize: "large" }}>
                            Credit Card
                          </option>
                        </select>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-6"></div>
                      <div className="col-3 text-end">
                        <select
                          className="form-select"
                          value={search}
                          onChange={(e) => handleFilter(e)}
                        >
                          <option value={0}>Filter By .. </option>
                          <option disabled style={{ fontSize: "large" }}>
                            Order Status
                          </option>
                          {orderStatus.map((s, i) => {
                            return (
                              <option value={s.OrderStatusId} key={i}>
                                {" "}
                                {s.StatusName}{" "}
                              </option>
                            );
                          })}
                          <option
                            value={"OrderNumber"}
                            style={{ fontSize: "large" }}
                          >
                            Order Number
                          </option>
                          <option
                            value={"Customer"}
                            style={{ fontSize: "large" }}
                          >
                            Customer Name
                          </option>
                          <option disabled style={{ fontSize: "large" }}>
                            Payment Method
                          </option>
                          <option
                            value={"CashOn"}
                            style={{ fontSize: "large" }}
                          >
                            Cash on delivery
                          </option>
                          <option value={"Card"} style={{ fontSize: "large" }}>
                            Credit Card
                          </option>
                        </select>
                      </div>
                      <div className="col-3">
                        {" "}
                        <input
                          type="text"
                          value={searchField}
                          onChange={(e) => handleSearch(e)}
                          className="form-control"
                        />{" "}
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="bd-example">
                      {ordersLoader === true ? (
                        <CustomSnipper />
                      ) : (
                        <div className="portalTable">
                          <DataTable
                            columns={columns}
                            data={filteredOrder}
                            pagination
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default ListOrders;
