import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Util from "../../../Classes/Util";
import { BiEdit, BiHide, BiShowAlt } from "react-icons/bi";
import { Variables } from "../../../Variations/Variations";
import ProductAttributesMedia from "./ProductAttributesMedia";

const ProductManageAttributeValues = (props) => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  let { navigate } = useNavigate();
  const [attributeValueName, setAttributeValueName] = useState();
  const [attributeName, setAttributeName] = useState();
  const [listAttributeValues, setListAttributeValues] = useState([]);
  const [color, setColor] = useColor();
  const [image, setImage] = useState("");
  const [button, setButton] = useState("");
  const [type, setType] = useState();
  const [update, setUpdate] = useState(false);
  const [id, setID] = useState("");
  const [showingImage, setShowingImage] = useState("");
  async function GetAttribute() {
    const URL =
      Variables.API_URL +
      "AttributeValues/GetAttributeValues/" +
      props.AttributeId;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setListAttributeValues(listItems);
  }

  async function GetAttributeValue(id) {
    const URL = Variables.API_URL + "AttributeValues/GetAttributeValue/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAttributeValueName(listItems[0].AttributeValueName);
    if (type == 1) {
      setColor(toColor("hex", listItems[0].AttributesValue));
    }
    if (type == 2) {
      setImage(listItems[0].AttributesValue);
    }
    if (type == 3) {
      setButton(listItems[0].AttributesValue);
    }
    setID(listItems[0].AttributeValueId);
    setUpdate(true);
  }

  async function GetAttributeById(id) {
    const URL = Variables.API_URL + "Attributes/GetAttributeById/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setType(listItems[0].AttributeTypeId);
    setAttributeName(listItems[0].Attribute1);
  }

  function HandleSubmit(e) {
    debugger;
    e.preventDefault();
    if (image != "") {
      if (update == false) {
        submitValues(image);
      } else {
        UpdateValues(image);
      }
      return false;
    }

    if (button != "") {
      if (update == false) {
        submitValues(button);
      } else {
        UpdateValues(button);
      }
      return false;
    }
    if (color.hex != "#121212") {
      if (update == false) {
        submitValues(color.hex);
      } else {
        UpdateValues(color.hex);
      }
      return false;
    } else {
      toast.error("Please fill all fields");
    }
  }
  function handleCancel() {
    setAttributeValueName("");
    setUpdate(false);
    if (button !== "") {
      setButton("");
    }
    if (color !== "") {
      window.location.href = "/Attributes/Value/" + props.AttributeId;
    }
    if (image !== "") {
      setImage("");
    }
  }

  function TooglePublishAttributeValue(id) {
    const URL =
      Variables.API_URL + "AttributeValues/TooglePublishAttributeValue/" + id;
    fetch(URL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        GetAttribute();
        toast.success("Action Done");
      } else {
        toast.error("Error, please check with administrator");
      }
    });
  }

  function submitValues(v) {
    const URL = Variables.API_URL + "AttributeValues/AddValue";
    fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        AttributeId: props.AttributeId,
        AttributesValue: v,
        AttributeValueName: attributeValueName,
      }),
    }).then((response) => {
      if (response.ok) {
        CheckResponse(response);
      } else {
        toast.error(response);
      }
    });
  }

  function CheckResponse(response) {
    if (response.ok) {
      toast.success("Attribute Added Successfully");
      setAttributeValueName("");
      if (type == 1) {
        setColor(toColor("hex", "#121212"));
      }
      if (type == 2) {
        setImage("");
      }
      if (type == 3) {
        setButton("");
      }
      GetAttribute();
    }
  }

  function UpdateValues(v) {
    const URL =
      Variables.API_URL + "AttributeValues/UpdateAttributeValue/" + id;
    fetch(URL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        AttributeId: props.AttributeId,
        AttributesValue: v,
        AttributeValueName: attributeValueName,
      }),
    }).then((response) => {
      if (response.ok) {
        setUpdate(false);
        CheckResponse(response);
      } else {
        toast.error(response);
      }
    });
  }

  const columns = [
    {
      name: "Attribute Value Name",
      selector: (row) => row.AttributeValueName,
      sortable: true,
      reorder: true,
    },

    {
      name: "Attribute Value",

      selector: (row) => row.Attribute1,
      sortable: true,
      reorder: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.PublishAttributeValue === true ? (
            <button
              className="btn btn-outline-success mr-5"
              onClick={() => TooglePublishAttributeValue(row.AttributeValueId)}
            >
              {row.IsPublished ? <BiShowAlt /> : <BiHide />}{" "}
            </button>
          ) : null}
          {permissions.UpdateAttributeValue === true ? (
            <button
              className="btn btn-outline-info mr-5"
              onClick={() => GetAttributeValue(row.AttributeValueId)}
            >
              {" "}
              <BiEdit />{" "}
            </button>
          ) : null}
        </div>
      ),
    },
  ];
  useEffect(() => {
    GetAttribute();

    GetAttributeById(props.AttributeId);
  }, []);

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-12">
                  <h3>Assign Values to Attribute '{attributeName}'</h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {permissions.AddAttributeValue === true ||
                permissions.UpdateAttributeValue === true ? (
                  <div className="col-lg-3">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <label>Attribute Value Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter Attribute Value"
                        value={attributeValueName}
                        onChange={(e) => setAttributeValueName(e.target.value)}
                      ></input>

                      {type == 1 ? (
                        <div>
                          <label>Attribute Value Color</label>
                          <ColorPicker
                            defaultValue="#452135"
                            width={250}
                            height={250}
                            color={color}
                            onChange={setColor}
                            hideHSV
                            hideRGB
                          />
                        </div>
                      ) : null}

                      {type == 2 ? (
                        <div>
                          <label> Upload Image</label>
                          <ProductAttributesMedia
                            setImage={setImage}
                            setShowingImage={setShowingImage}
                          />

                          {showingImage !== "" ? (
                            <img
                              src={Variables.API_PUBLISHED + showingImage}
                              className="form-control"
                            />
                          ) : null}
                        </div>
                      ) : null}

                      {type == 3 ? (
                        <div>
                          <label> URL</label>
                          <input
                            type="text"
                            className="form-control"
                            value={button}
                            onChange={(e) => setButton(e.target.value)}
                            placeholder="URL"
                          />
                        </div>
                      ) : null}

                      <div className="row">
                        <div className="col-6">
                          <button
                            className="btn btn-secondary"
                            style={{ margin: "2px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={(e) => HandleSubmit(e)}
                          >
                            {update === true ? "update" : "save"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : null}
                <div className="col-lg-9">
                  <DataTable
                    columns={columns}
                    data={listAttributeValues}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </div>
  );
};
export default ProductManageAttributeValues;
