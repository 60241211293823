import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../../Variations/Variations";
import { AiOutlineRight } from "react-icons/ai";
const ManageRole = () => {
  const navigate = useNavigate();
  let { RoleID } = useParams();
  const [role, setRole] = useState({
    IsEnabled: false,
    RoleName: "",
    Dashboard: false,
    Notification: false,
    ReadNotification: false,
    NotificationMarkAs: false,
    DeleteNotification: false,
    RestoreNotification: false,
    Pages: false,
    ManagePage: false,
    PublishPage: false,
    DeletePage: false,
    Menus: false,
    ManageMenu: false,
    PublishMenu: false,
    DeleteMenu: false,
    Groups: false,
    ManageGroup: false,
    PublishGroup: false,
    DeleteGroup: false,
    Sliders: false,
    ManageSlider: false,
    PublishSlider: false,
    Slides: false,
    ManageSlide: false,
    PublishSlide: false,
    Widgets: false,
    ManageWidget: false,
    PublishWidget: false,
    Products: false,
    ManageProduct: false,
    PublishProduct: false,
    DeleteProduct: false,
    SetInStock: false,
    SetOutOfStock: false,
    SetOnBackorder: false,
    Attributes: false,
    ManageAttribute: false,
    PublishAttribute: false,
    IncludeAttribute: false,
    DeleteAttribute: false,
    AttributeValues: false,
    ManageAttributeValue: false,
    PublishAttributeValue: false,
    DeleteAttributeValue: false,
    Brands: false,
    ManageBrand: false,
    PublishBrand: false,
    DeleteBrand: false,
    Categories: false,
    ManageCategory: false,
    PublishCategory: false,
    DeleteCategory: false,
    Materials: false,
    ManageMaterial: false,
    PublishMaterial: false,
    DeleteMaterial: false,
    Tags: false,
    ManageTag: false,
    PublishTag: false,
    DeleteTag: false,
    Media: false,
    UploadMedia: false,
    DeleteMedia: false,
    Measurements: false,
    ManageMeasurement: false,
    PublishMeasurement: false,
    DeleteMeasurement: false,
    SizeCharts: false,
    ManageSizeChart: false,
    PublishSizeChart: false,
    DeleteSizeChart: false,
    Coupons: false,
    ManageCoupon: false,
    PublishCoupon: false,
    DeleteCoupon: false,
    CouponGroups: false,
    ManageCouponGroup: false,
    PublishCouponGroup: false,
    DeleteCouponGroup: false,
    PriceRules: false,
    ManagePriceRule: false,
    PublishPriceRule: false,
    DeletePriceRule: false,
    Customers: false,
    LockCustomer: false,
    CustomerDetails: false,
    ManageAddressBook: false,
    Settings: false,
    Roles: false,
    ManageRole: false,
    EnableRole: false,
    Users: false,
    ManageUser: false,
    LockUser: false,
    DeleteUser: false,
    Smtp: false,
    Orders:false,
    ManageOrder:false,
    StarNotification:false,
    Contents:false,
    ManageContent:false,
    PublishContent:false,
    DeleteContent:false,
    Countries:false,
    ManageCountry:false,
    PublishCountry:false

  });

  const fetchRole = async () => {
    let response = await fetch(Variables.API_URL + "Role/GetRole/" + RoleID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setRole(result);
    }
  };
  function IsValid() {
    if (role.RoleName === "") {
      toast.error("Name Is Required");
      return false;
    }
    return true;
  }

  const Submit = async () => {
    if (IsValid()) {
      if (RoleID == 0) {
        let response = await fetch(Variables.API_URL + "Role/AddRole", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.HostAPI,
          },
          body: JSON.stringify({
            IsEnabled: role.IsEnabled,
            RoleName: role.RoleName,
            Dashboard: role.Dashboard,
            Notification: role.Notification,
            ReadNotification: role.ReadNotification,
            NotificationMarkAs: role.NotificationMarkAs,
            DeleteNotification: role.DeleteNotification,
            RestoreNotification: role.RestoreNotification,
            Pages: role.Pages,
            ManagePage: role.ManagePage,
            PublishPage: role.PublishPage,
            DeletePage: role.DeletePage,
            Menus: role.Menus,
            ManageMenu: role.ManageMenu,
            PublishMenu: role.PublishMenu,
            DeleteMenu: role.DeleteMenu,
            Groups: role.Groups,
            ManageGroup: role.ManageGroup,
            PublishGroup: role.PublishGroup,
            DeleteGroup: role.DeleteGroup,
            Sliders: role.Sliders,
            ManageSlider: role.ManageSlider,
            PublishSlider: role.PublishSlider,
            Slides: role.Slides,
            ManageSlide: role.ManageSlide,
            PublishSlide: role.PublishSlide,
            Widgets: role.Widgets,
            ManageWidget: role.ManageWidget,
            PublishWidget: role.PublishWidget,
            Products: role.Products,
            ManageProduct: role.ManageProduct,
            PublishProduct: role.PublishProduct,
            DeleteProduct: role.DeleteProduct,
            SetInStock: role.SetInStock,
            SetOutOfStock: role.SetOutOfStock,
            SetOnBackorder: role.SetOnBackorder,
            Attributes: role.Attributes,
            ManageAttribute: role.ManageAttribute,
            PublishAttribute: role.PublishAttribute,
            IncludeAttribute: role.IncludeAttribute,
            DeleteAttribute: role.DeleteAttribute,
            AttributeValues: role.AttributeValues,
            ManageAttributeValue: role.ManageAttributeValue,
            PublishAttributeValue: role.PublishAttributeValue,
            DeleteAttributeValue: role.DeleteAttributeValue,
            Brands: role.Brands,
            ManageBrand: role.ManageBrand,
            PublishBrand: role.PublishBrand,
            DeleteBrand: role.DeleteBrand,
            Categories: role.Categories,
            ManageCategory: role.ManageCategory,
            PublishCategory: role.PublishCategory,
            DeleteCategory: role.DeleteCategory,
            Materials: role.Materials,
            ManageMaterial: role.ManageMaterial,
            PublishMaterial: role.PublishMaterial,
            DeleteMaterial: role.DeleteMaterial,
            Tags: role.Tags,
            ManageTag: role.ManageTag,
            PublishTag: role.PublishTag,
            DeleteTag: role.DeleteTag,
            Media: role.Media,
            UploadMedia: role.UploadMedia,
            DeleteMedia: role.DeleteMedia,
            Measurements: role.Measurements,
            ManageMeasurement: role.ManageMeasurement,
            PublishMeasurement: role.PublishMeasurement,
            DeleteMeasurement: role.DeleteMeasurement,
            SizeCharts: role.SizeCharts,
            ManageSizeChart: role.ManageSizeChart,
            PublishSizeChart: role.PublishSizeChart,
            DeleteSizeChart: role.DeleteSizeChart,
            Coupons: role.Coupons,
            ManageCoupon: role.ManageCoupon,
            PublishCoupon: role.PublishCoupon,
            DeleteCoupon: role.DeleteCoupon,
            CouponGroups: role.CouponGroups,
            ManageCouponGroup: role.ManageCouponGroup,
            PublishCouponGroup: role.PublishCouponGroup,
            DeleteCouponGroup: role.DeleteCouponGroup,
            PriceRules: role.PriceRules,
            ManagePriceRule: role.ManagePriceRule,
            PublishPriceRule: role.PublishPriceRule,
            DeletePriceRule: role.DeletePriceRule,
            Customers: role.Customers,
            LockCustomer: role.LockCustomer,
            CustomerDetails: role.CustomerDetails,
            ManageAddressBook: role.ManageAddressBook,
            Settings: role.Settings,
            Roles: role.Roles,
            ManageRole: role.ManageRole,
            EnableRole: role.EnableRole,
            Users: role.Users,
            ManageUser: role.ManageUser,
            LockUser: role.LockUser,
            DeleteUser: role.DeleteUser,
            Smtp: role.Smtp,
            Orders:role.Orders,
            ManageOrder:role.ManageOrder,
            StarNotification:role.StarNotification,
            Contents:role.Contents,
            ManageContent:role.ManageContent,
            PublishContent:role.PublishContent,
            DeleteContent:role.DeleteContent,
            Countries:role.Countries,
            ManageCountry:role.ManageCountry,
            PublishCountry:role.PublishCountry

          }),
        });
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          toast.success("Successfully Added");
          setTimeout(() => {
            navigate("/Roles");
          }, 1000);
        }
      } else {
        let response = await fetch(
          Variables.API_URL + "Role/UpdateRole/" + RoleID,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.HostAPI,
            },
            body: JSON.stringify({
              IsEnabled: role.IsEnabled,
              RoleName: role.RoleName,
              Dashboard: role.Dashboard,
              Notification: role.Notification,
              ReadNotification: role.ReadNotification,
              NotificationMarkAs: role.NotificationMarkAs,
              DeleteNotification: role.DeleteNotification,
              RestoreNotification: role.RestoreNotification,
              Pages: role.Pages,
              ManagePage: role.ManagePage,
              PublishPage: role.PublishPage,
              DeletePage: role.DeletePage,
              Menus: role.Menus,
              ManageMenu: role.ManageMenu,
              PublishMenu: role.PublishMenu,
              DeleteMenu: role.DeleteMenu,
              Groups: role.Groups,
              ManageGroup: role.ManageGroup,
              PublishGroup: role.PublishGroup,
              DeleteGroup: role.DeleteGroup,
              Sliders: role.Sliders,
              ManageSlider: role.ManageSlider,
              PublishSlider: role.PublishSlider,
              Slides: role.Slides,
              ManageSlide: role.ManageSlide,
              PublishSlide: role.PublishSlide,
              Widgets: role.Widgets,
              ManageWidget: role.ManageWidget,
              PublishWidget: role.PublishWidget,
              Products: role.Products,
              ManageProduct: role.ManageProduct,
              PublishProduct: role.PublishProduct,
              DeleteProduct: role.DeleteProduct,
              SetInStock: role.SetInStock,
              SetOutOfStock: role.SetOutOfStock,
              SetOnBackorder: role.SetOnBackorder,
              Attributes: role.Attributes,
              ManageAttribute: role.ManageAttribute,
              PublishAttribute: role.PublishAttribute,
              IncludeAttribute: role.IncludeAttribute,
              DeleteAttribute: role.DeleteAttribute,
              AttributeValues: role.AttributeValues,
              ManageAttributeValue: role.ManageAttributeValue,
              PublishAttributeValue: role.PublishAttributeValue,
              DeleteAttributeValue: role.DeleteAttributeValue,
              Brands: role.Brands,
              ManageBrand: role.ManageBrand,
              PublishBrand: role.PublishBrand,
              DeleteBrand: role.DeleteBrand,
              Categories: role.Categories,
              ManageCategory: role.ManageCategory,
              PublishCategory: role.PublishCategory,
              DeleteCategory: role.DeleteCategory,
              Materials: role.Materials,
              ManageMaterial: role.ManageMaterial,
              PublishMaterial: role.PublishMaterial,
              DeleteMaterial: role.DeleteMaterial,
              Tags: role.Tags,
              ManageTag: role.ManageTag,
              PublishTag: role.PublishTag,
              DeleteTag: role.DeleteTag,
              Media: role.Media,
              UploadMedia: role.UploadMedia,
              DeleteMedia: role.DeleteMedia,
              Measurements: role.Measurements,
              ManageMeasurement: role.ManageMeasurement,
              PublishMeasurement: role.PublishMeasurement,
              DeleteMeasurement: role.DeleteMeasurement,
              SizeCharts: role.SizeCharts,
              ManageSizeChart: role.ManageSizeChart,
              PublishSizeChart: role.PublishSizeChart,
              DeleteSizeChart: role.DeleteSizeChart,
              Coupons: role.Coupons,
              ManageCoupon: role.ManageCoupon,
              PublishCoupon: role.PublishCoupon,
              DeleteCoupon: role.DeleteCoupon,
              CouponGroups: role.CouponGroups,
              ManageCouponGroup: role.ManageCouponGroup,
              PublishCouponGroup: role.PublishCouponGroup,
              DeleteCouponGroup: role.DeleteCouponGroup,
              PriceRules: role.PriceRules,
              ManagePriceRule: role.ManagePriceRule,
              PublishPriceRule: role.PublishPriceRule,
              DeletePriceRule: role.DeletePriceRule,
              Customers: role.Customers,
              LockCustomer: role.LockCustomer,
              CustomerDetails: role.CustomerDetails,
              ManageAddressBook: role.ManageAddressBook,
              Settings: role.Settings,
              Roles: role.Roles,
              ManageRole: role.ManageRole,
              EnableRole: role.EnableRole,
              Users: role.Users,
              ManageUser: role.ManageUser,
              LockUser: role.LockUser,
              DeleteUser: role.DeleteUser,
              Smtp: role.Smtp,
              Orders:role.Orders,
              ManageOrder:role.ManageOrder,
              StarNotification:role.StarNotification,
              Contents:role.Contents,
              ManageContent:role.ManageContent,
              PublishContent:role.PublishContent,
              DeleteContent:role.DeleteContent,
              Countries:role.Countries,
              ManageCountry:role.ManageCountry,
              PublishCountry:role.PublishCountry
            }),
          }
        );
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          toast.success("Successfully Updated");
          setTimeout(() => {
            navigate("/Roles");
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (RoleID != 0) {
      fetchRole();
    }
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{role.RoleName == "" ? "Add" : "Update"} Role</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Settings">Settings <AiOutlineRight /></Link></li>
              <li><Link to="/Roles">Roles <AiOutlineRight /></Link></li>
              <li>Manage Role</li>
            </ul>
          </div>
        </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">{role.RoleName}</h4>
              </div>
              <div>
                <Button variant="secondary" onClick={() => navigate("/Roles")}>
                  Cancel
                </Button>
                {"  "}
                <Button variant="btn btn-primary ms-2" onClick={Submit}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="card-body">
              <div className="bd-example">
                <Row>
                  <Col lg="6" md="6" sm="12">
                    <label> Role Name</label>
                    <input
                      type="text"
                      value={role.RoleName}
                      onChange={(e) =>
                        setRole((prevState) => ({
                          ...prevState,
                          RoleName: e.target.value,
                        }))
                      }
                      className="form-control"
                      placeholder="Role Name"
                    />
                  </Col>
                  <Col lg="6" md="6" sm="12">
                  <div className="form-check  ml-1 mt-4">
                      <input
                        id="IsEnabled"
                        type="checkbox"
                        className="form-check-input"
                        value={role.IsEnabled}
                        checked={role.IsEnabled}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            IsEnabled: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="IsEnabled"
                      >
                     Enabled ?
                      </label>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <label>Dashboard</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check col-3 ml-1 mt-4">
                      <input
                        id="Dashboard"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Dashboard}
                        checked={role.Dashboard}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Dashboard: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="Dashboard">
                        Dashboard
                      </label>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <label>Notification</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Notification"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Notification}
                        checked={role.Notification}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Notification: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Notification"
                      >
                      Notification
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ReadNotification"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ReadNotification}
                        checked={role.ReadNotification}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ReadNotification: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="ReadNotification">
                      Read Notification
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="NotificationMarkAs"
                        type="checkbox"
                        className="form-check-input"
                        value={role.NotificationMarkAs}
                        checked={role.NotificationMarkAs}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            NotificationMarkAs: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="NotificationMarkAs"
                      >
                     Mark As
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteNotification"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteNotification}
                        checked={role.DeleteNotification}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteNotification: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteNotification"
                      >
                       Delete Notification
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="RestoreNotification"
                        type="checkbox"
                        className="form-check-input"
                        value={role.RestoreNotification}
                        checked={role.RestoreNotification}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            RestoreNotification: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="RestoreNotification"
                      >
                      Restore Notification
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="StarNotification"
                        type="checkbox"
                        className="form-check-input"
                        value={role.StarNotification}
                        checked={role.StarNotification}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            StarNotification: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="StarNotification"
                      >
                      Star Notification
                      </label>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <label>Pages</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Pages"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Pages}
                        checked={role.Pages}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Pages: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Pages"
                      >
                        Pages
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManagePage"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManagePage}
                        checked={role.ManagePage}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManagePage: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManagePage"
                      >
                        Manage Page
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishPage"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishPage}
                        checked={role.PublishPage}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishPage: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishPage"
                      >
                        Publish Page
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeletePage"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeletePage}
                        checked={role.DeletePage}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeletePage: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeletePage"
                      >
                        Delete Page
                      </label>
                    </div>
                  </Col>
                </Row>
                <br />

                <br />
                <Row>
                  <label>Menus</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Menus"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Menus}
                        checked={role.Menus}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Menus: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="Menus">
                      Menus
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageMenu"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageMenu}
                        checked={role.ManageMenu}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageMenu: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="ManageMenu">
                      Manage Menu
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishMenu"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishMenu}
                        checked={role.PublishMenu}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishMenu: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="PublishMenu">
                      Publish Menu
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteMenu"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteMenu}
                        checked={role.DeleteMenu}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteMenu: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteMenu"
                      >
                       Delete Menu
                      </label>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <label>Menu Groups</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Groups"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Groups}
                        checked={role.Groups}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Groups: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Groups"
                      >
                     Menu Groups
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageGroup"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageGroup}
                        checked={role.ManageGroup}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageGroup: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="ManageGroup">
                      Manage Group
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishGroup"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishGroup}
                        checked={role.PublishGroup}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishGroup: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishGroup"
                      >
                      Publish Group
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteGroup"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteGroup}
                        checked={role.DeleteGroup}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteGroup: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteGroup"
                      >
                        Delete Group
                      </label>
                    </div>
                  </Col>
           
                </Row>
                <br />
                <Row>
                  <label>Sliders</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Sliders"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Sliders}
                        checked={role.Sliders}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Sliders: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="Sliders">
                      Sliders
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageSlider"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageSlider}
                        checked={role.ManageSlider}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageSlider: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="ManageSlider">
                      ManageSlider
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishSlider"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishSlider}
                        checked={role.PublishSlider}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishSlider: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="PublishSlider">
                      Publish Slider
                      </label>
                    </div>
                  </Col>
                
                </Row>
                <br />
                <Row>
                  <label>Slides</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Slides"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Slides}
                        checked={role.Slides}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Slides: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="Slides">
                      Slides
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageSlide"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageSlide}
                        checked={role.ManageSlide}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageSlide: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="ManageSlide">
                      Manage Slide
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishSlide"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishSlide}
                        checked={role.PublishSlide}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishSlide: e.target.checked,
                          }))
                        }
                      />
                      <label className="form-check-label" htmlFor="PublishSlide">
                      Publish Slide
                      </label>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <label>Widgets</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Widgets"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Widgets}
                        checked={role.Widgets}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Widgets: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Widgets"
                      >
                     Widgets
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageWidget"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageWidget}
                        checked={role.ManageWidget}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageWidget: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageWidget"
                      >
                        Manage Widget
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishWidget"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishWidget}
                        checked={role.PublishWidget}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishWidget: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishWidget"
                      >
                       Publish Widget 
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Products</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Products"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Products}
                        checked={role.Products}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Products: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Products"
                      >
                     Products
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageProduct"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageProduct}
                        checked={role.ManageProduct}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageProduct: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageProduct"
                      >
                        Manage Product
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishProduct"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishProduct}
                        checked={role.PublishProduct}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishProduct: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishProduct"
                      >
                       Publish Product 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteProduct"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteProduct}
                        checked={role.DeleteProduct}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteProduct: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteProduct"
                      >
                       Delete Product 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="SetInStock"
                        type="checkbox"
                        className="form-check-input"
                        value={role.SetInStock}
                        checked={role.SetInStock}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            SetInStock: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="SetInStock"
                      >
                      Set In Stock
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="SetOutOfStock"
                        type="checkbox"
                        className="form-check-input"
                        value={role.SetOutOfStock}
                        checked={role.SetOutOfStock}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            SetOutOfStock: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="SetOutOfStock"
                      >
                  Set Out Of Stock
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="SetOnBackorder"
                        type="checkbox"
                        className="form-check-input"
                        value={role.SetOnBackorder}
                        checked={role.SetOnBackorder}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            SetOnBackorder: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="SetOnBackorder"
                      >
        Set On Backorder
                      </label>
                    </div>
                  </Col>
              
              
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Attributes</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Attributes"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Attributes}
                        checked={role.Attributes}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Attributes: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Attributes"
                      >
                     Attributes
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageAttribute"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageAttribute}
                        checked={role.ManageAttribute}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageAttribute: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageAttribute"
                      >
                        Manage Attribute
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishAttribute"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishAttribute}
                        checked={role.PublishAttribute}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishAttribute: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishAttribute"
                      >
                       Publish Attribute 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="IncludeAttribute"
                        type="checkbox"
                        className="form-check-input"
                        value={role.IncludeAttribute}
                        checked={role.IncludeAttribute}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            IncludeAttribute: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="IncludeAttribute"
                      >
                       Include In Filter
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteAttribute"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteAttribute}
                        checked={role.DeleteAttribute}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteAttribute: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteAttribute"
                      >
                       Delete Attribute
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Attribute Values</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="AttributeValues"
                        type="checkbox"
                        className="form-check-input"
                        value={role.AttributeValues}
                        checked={role.AttributeValues}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            AttributeValues: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="AttributeValues"
                      >
                     Attribute Values
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageAttributeValue"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageAttributeValue}
                        checked={role.ManageAttributeValue}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageAttributeValue: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageAttributeValue"
                      >
                        Manage AttributeValue
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishAttributeValue"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishAttributeValue}
                        checked={role.PublishAttributeValue}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishAttributeValue: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishAttributeValue"
                      >
                       Publish AttributeValue 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteAttributeValue"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteAttributeValue}
                        checked={role.DeleteAttributeValue}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteAttributeValue: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteAttributeValue"
                      >
                       Delete Attribute Value
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Brands</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Brands"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Brands}
                        checked={role.Brands}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Brands: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Brands"
                      >
                  Brands
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageBrand"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageBrand}
                        checked={role.ManageBrand}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageBrand: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageBrand"
                      >
                        Manage Brand
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishBrand"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishBrand}
                        checked={role.PublishBrand}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishBrand: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishBrand"
                      >
                       Publish Brand 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteBrand"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteBrand}
                        checked={role.DeleteBrand}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteBrand: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteBrand"
                      >
                       Delete Brand
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Categories</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Categories"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Categories}
                        checked={role.Categories}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Categories: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Categories"
                      >
                  Categories
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageCategory"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageCategory}
                        checked={role.ManageCategory}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageCategory: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageCategory"
                      >
                        Manage Category
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishCategory"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishCategory}
                        checked={role.PublishCategory}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishCategory: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishCategory"
                      >
                       Publish Category 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteCategory"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteCategory}
                        checked={role.DeleteCategory}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteCategory: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteCategory"
                      >
                       Delete Category
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Materials</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Materials"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Materials}
                        checked={role.Materials}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Materials: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Materials"
                      >
                  Materials
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageMaterial"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageMaterial}
                        checked={role.ManageMaterial}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageMaterial: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageMaterial"
                      >
                        Manage Material
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishMaterial"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishMaterial}
                        checked={role.PublishMaterial}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishMaterial: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishMaterial"
                      >
                       Publish Material 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteMaterial"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteMaterial}
                        checked={role.DeleteMaterial}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteMaterial: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteMaterial"
                      >
                       Delete Material
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Tags</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Tags"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Tags}
                        checked={role.Tags}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Tags: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Tags"
                      >
                  Tags
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageTag"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageTag}
                        checked={role.ManageTag}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageTag: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageTag"
                      >
                        Manage Tag
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishTag"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishTag}
                        checked={role.PublishTag}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishTag: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishTag"
                      >
                       Publish Tag 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteTag"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteTag}
                        checked={role.DeleteTag}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteTag: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteTag"
                      >
                       Delete Tag
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Media</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Media"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Media}
                        checked={role.Media}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Media: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Media"
                      >
                  Media
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="UploadMedia"
                        type="checkbox"
                        className="form-check-input"
                        value={role.UploadMedia}
                        checked={role.UploadMedia}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            UploadMedia: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="UploadMedia"
                      >
                       Upload Media
                      </label>
                    </div>
                  </Col>
            
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteMedia"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteMedia}
                        checked={role.DeleteMedia}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteMedia: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteMedia"
                      >
                       Delete Media
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Measurements</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Measurements"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Measurements}
                        checked={role.Measurements}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Measurements: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Measurements"
                      >
                  Measurements
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageMeasurement"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageMeasurement}
                        checked={role.ManageMeasurement}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageMeasurement: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageMeasurement"
                      >
                        Manage Measurement
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishMeasurement"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishMeasurement}
                        checked={role.PublishMeasurement}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishMeasurement: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishMeasurement"
                      >
                       Publish Measurement 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteMeasurement"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteMeasurement}
                        checked={role.DeleteMeasurement}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteMeasurement: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteMeasurement"
                      >
                       Delete Measurement
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Size Charts</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="SizeCharts"
                        type="checkbox"
                        className="form-check-input"
                        value={role.SizeCharts}
                        checked={role.SizeCharts}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            SizeCharts: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="SizeCharts"
                      >
                  Size Charts
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageSizeChart"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageSizeChart}
                        checked={role.ManageSizeChart}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageSizeChart: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageSizeChart"
                      >
                        Manage SizeChart
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishSizeChart"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishSizeChart}
                        checked={role.PublishSizeChart}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishSizeChart: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishSizeChart"
                      >
                       Publish SizeChart 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteSizeChart"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteSizeChart}
                        checked={role.DeleteSizeChart}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteSizeChart: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteSizeChart"
                      >
                       Delete SizeChart
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Coupons</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Coupons"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Coupons}
                        checked={role.Coupons}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Coupons: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Coupons"
                      >
                Coupons
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageCoupon"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageCoupon}
                        checked={role.ManageCoupon}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageCoupon: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageCoupon"
                      >
                        Manage Coupon
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishCoupon"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishCoupon}
                        checked={role.PublishCoupon}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishCoupon: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishCoupon"
                      >
                       Publish Coupon 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteCoupon"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteCoupon}
                        checked={role.DeleteCoupon}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteCoupon: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteCoupon"
                      >
                       Delete Coupon
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Coupon Groups</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="CouponGroups"
                        type="checkbox"
                        className="form-check-input"
                        value={role.CouponGroups}
                        checked={role.CouponGroups}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            CouponGroups: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="CouponGroups"
                      >
                Coupon Groups
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageCouponGroup"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageCouponGroup}
                        checked={role.ManageCouponGroup}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageCouponGroup: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageCouponGroup"
                      >
                        Manage Coupon Group
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishCouponGroup"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishCouponGroup}
                        checked={role.PublishCouponGroup}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishCouponGroup: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishCouponGroup"
                      >
                       Publish Coupon Group 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteCouponGroup"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteCouponGroup}
                        checked={role.DeleteCouponGroup}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteCouponGroup: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteCouponGroup"
                      >
                       Delete Coupon Group
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Price Rules</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PriceRules"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PriceRules}
                        checked={role.PriceRules}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PriceRules: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PriceRules"
                      >
                Price Rules
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManagePriceRule"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManagePriceRule}
                        checked={role.ManagePriceRule}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManagePriceRule: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManagePriceRule"
                      >
                        Manage Price Rule
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishPriceRule"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishPriceRule}
                        checked={role.PublishPriceRule}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishPriceRule: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishPriceRule"
                      >
                       Publish Price Rule 
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeletePriceRule"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeletePriceRule}
                        checked={role.DeletePriceRule}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeletePriceRule: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeletePriceRule"
                      >
                       Delete Price Rule
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Customers</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Customers"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Customers}
                        checked={role.Customers}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Customers: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Customers"
                      >
                Customers
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="CustomerDetails"
                        type="checkbox"
                        className="form-check-input"
                        value={role.CustomerDetails}
                        checked={role.CustomerDetails}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            CustomerDetails: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="CustomerDetails"
                      >
                       Customer Details
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="LockCustomer"
                        type="checkbox"
                        className="form-check-input"
                        value={role.LockCustomer}
                        checked={role.LockCustomer}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            LockCustomer: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="LockCustomer"
                      >
                      Lock Customer
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageAddressBook"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageAddressBook}
                        checked={role.ManageAddressBook}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageAddressBook: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageAddressBook"
                      >
                     Manage Address Book
                      </label>
                    </div>
                  </Col>
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Settings</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Settings"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Settings}
                        checked={role.Settings}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Settings: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Settings"
                      >
                Settings
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Roles"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Roles}
                        checked={role.Roles}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Roles: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Roles"
                      >
                       Roles
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageRole"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageRole}
                        checked={role.ManageRole}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageRole: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageRole"
                      >
                  Manage Role
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="EnableRole"
                        type="checkbox"
                        className="form-check-input"
                        value={role.EnableRole}
                        checked={role.EnableRole}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            EnableRole: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="EnableRole"
                      >
                    Enable Role
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Users"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Users}
                        checked={role.Users}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Users: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Users"
                      >
                  Users
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageUser"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageUser}
                        checked={role.ManageUser}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageUser: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageUser"
                      >
                   Manage User
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="LockUser"
                        type="checkbox"
                        className="form-check-input"
                        value={role.LockUser}
                        checked={role.LockUser}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            LockUser: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="LockUser"
                      >
                   Lock User
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteUser"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteUser}
                        checked={role.DeleteUser}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteUser: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteUser"
                      >
                    Delete User
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Smtp"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Smtp}
                        checked={role.Smtp}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Smtp: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Smtp"
                      >
                  Smtp
                      </label>
                    </div>
                  </Col>
              
              
              
              
                </Row>

                <br />
                <br />
                <Row>
                  <label>Orders</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Orders"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Orders}
                        checked={role.Orders}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Orders: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Orders"
                      >
                Orders
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageOrder"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageOrder}
                        checked={role.ManageOrder}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageOrder: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageOrder"
                      >
                    Manage Order
                      </label>
                    </div>
                  </Col>
                
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Contents</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Contents"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Contents}
                        checked={role.Contents}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Contents: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Contents"
                      >
                Contents
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageContent"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageContent}
                        checked={role.ManageContent}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageContent: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageContent"
                      >
                    Manage Content
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishContent"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishContent}
                        checked={role.PublishContent}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishContent: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishContent"
                      >
                    Publish Content
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="DeleteContent"
                        type="checkbox"
                        className="form-check-input"
                        value={role.DeleteContent}
                        checked={role.DeleteContent}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            DeleteContent: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="DeleteContent"
                      >
                    Delete Content
                      </label>
                    </div>
                  </Col>
                
              
                </Row>
                <br />
                <br />
                <Row>
                  <label>Countries</label>
                </Row>
                <hr />
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="Countries"
                        type="checkbox"
                        className="form-check-input"
                        value={role.Countries}
                        checked={role.Countries}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            Countries: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Countries"
                      >
                Countries
                      </label>
                    </div>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="ManageCountry"
                        type="checkbox"
                        className="form-check-input"
                        value={role.ManageCountry}
                        checked={role.ManageCountry}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            ManageCountry: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ManageCountry"
                      >
                    Manage Country
                      </label>
                    </div>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <div className="form-check  ml-1 mt-4">
                      <input
                        id="PublishCountry"
                        type="checkbox"
                        className="form-check-input"
                        value={role.PublishCountry}
                        checked={role.PublishCountry}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            PublishCountry: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PublishCountry"
                      >
                    Publish Country
                      </label>
                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ManageRole;
