import React, { useState, useEffect } from 'react';
import { MdOutlineDelete, MdOutlineAssignmentReturned, MdManageSearch } from 'react-icons/md';
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import { Variables } from '../../../Variations/Variations';
import { toast } from 'react-toastify';
const ProductMaterials = (props) => {
  const [isOpenUnassignedMaterials, setisOpenUnassignedMaterials] = useState(false);

  const unassignedMaterialsColumns = [
    {
      name: "ID",
      selector: (row) => row.MaterialId,
      sortable: true,
    },
    {
      name: " Name  ",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: " Percentage %  ",
      selector: (row) => (
        <div>
          <input type="number" value={row.Percentage} className="form-control" onChange={(e) => handlePercentage(e, row)} />
        </div>
      ),
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button className="btn btn-outline-secondary btn-sm" onClick={() => AssignMaterial(row)}>
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedMaterialsColumns = [
    {
      name: "ID",
      selector: (row) => row.MaterialId,
      sortable: true,
    },
    {
      name: " Name ",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: " Percentage %  ",
      selector: (row) => row.Percentage,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnassignMaterials(row)}
        >
          <MdOutlineDelete />
        </button>
      ),
    },
  ];

  function handlePercentage(e, row) {
    var array = [...props.unassignedMaterials];

    array.forEach(element => {
      if (element == row) {
        element.Percentage = parseFloat(e.target.value)
      }
    });
    props.setUnassignedMaterials(array)
  }
  function AssignMaterial(row) {
    var array = [...props.assignedMaterials];
    var unassigned = [...props.unassignedMaterials];
    var count = row.Percentage;
    array.forEach(element => {
      count += element.Percentage
    });
    if (row.Percentage == 0 || isNaN(row.Percentage) == true) {
      toast.error("Please choose the percentage");
    }
    else if (row.Percentage > 100) {
      toast.error("Please choose a valid percentage")
    }
    else if (count > 100) {
      toast.error("You cannot choose more than 100 %");
    }
    else {
      unassigned.forEach((element,i) => {
        if (element.MaterialId == row.MaterialId) {
          unassigned.splice(i, 1);

        }
      });
      array.push(row);
      props.setAssignedMaterials(array);
      props.setFilteredAssignedMaterials(array);
      props.setUnassignedMaterials(unassigned);
      props.setFilteredUnassignedMaterials(unassigned);
    }


  }

  function UnassignMaterials(row) {
   
    row.Percentage = 0;
    var assigned = [...props.assignedMaterials]
    var unassigned = [...props.unassignedMaterials];
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    unassigned.push(row);
    props.setAssignedMaterials(assigned);
    props.setFilteredAssignedMaterials(assigned);
    props.setUnassignedMaterials(unassigned);
    props.setFilteredUnassignedMaterials(unassigned)

  }
  const fetchUnassignedMaterials = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "Material/GetUnassignedMaterials/" +
      props.ProductID
    );
    result = await response.json();
    if (response.status === 200) {
      props.setUnassignedMaterials(result);
      props.setFilteredUnassignedMaterials(result);
    }
  };
  const fetchAssignedMaterials = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "Material/GetAssignedMaterials/" +
      props.ProductID
    );
    result = await response.json();
    props.setAssignedMaterials(result);
    props.setFilteredAssignedMaterials(result);

  };

  function handleSearchAssignedMaterials(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = props.assignedMaterials.filter((data) => {
      return data.Name.toLowerCase().search(value) != -1;
    });
    props.setFilteredAssignedMaterials(result);
  }
  function handleSearchUnassignedMaterials(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = props.unassignedMaterials.filter((data) => {
      return data.Name.toLowerCase().search(value) != -1;
    });
    props.setFilteredUnassignedMaterials(result);
  }
  useEffect(() => {
    fetchUnassignedMaterials();
    if (props.ProductID != 0) {

      fetchAssignedMaterials();
    }


  }, [])
  return (
    <div className="row">

      <div className="card-header">
        <div className="row">
          <div className="col-6">
            <h5 className="card-title">Assigned Materials</h5>
          </div>
          <div className="col-6 text-right">
            <button
              className="btn btn-primary"
              onClick={() => setisOpenUnassignedMaterials(true)}
            >
              Assign Material
            </button>
            <Modal
              dialogClassName="w-max"
              show={isOpenUnassignedMaterials}
              onHide={() => {setisOpenUnassignedMaterials(false); props.unassignedMaterials.map((m)=> m.Percentage=0)}}

            >
              <Modal.Header closeButton>
                <Modal.Title>Assign Request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="row mb-3">
                        <div className="col-8"></div>
                        <div className="col-4 text-end tblSearchBar position-relative">
                          <span>
                            <MdManageSearch />
                          </span>
                          <input
                            type="text"
                            placeholder="Search any field in table"
                            className="form-control form-control-sm"
                            onChange={(e) => handleSearchUnassignedMaterials(e)}
                          ></input>
                        </div>
                      </div>
                      <div className='portalTable'>
                        <DataTable
                          columns={unassignedMaterialsColumns}
                          data={props.filteredUnassignedMaterials}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-right">
                      <Button
                        variant="secondary"
                        onClick={() =>
                          setisOpenUnassignedMaterials(false)
                        }
                      >
                        Close
                      </Button>

                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className='row'>
          <div className="col-8"></div>
          <div className="col-4 text-end tblSearchBar position-relative">
            <span>
              <MdManageSearch />
            </span>
            <input
              type="text"
              placeholder="Search any field in table"
              className="form-control form-control-sm"
              onChange={(e) => handleSearchAssignedMaterials(e)}
            ></input>
          </div>
        </div>
      </div>
      <div className='portalTable'>
        <DataTable
          columns={assignedMaterialsColumns}
          data={props.filteredAssignedMaterials}
          pagination
        />
      </div>




    </div>
  )
}

export default ProductMaterials