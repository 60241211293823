import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import './Require.css'
import { AiOutlineRight } from "react-icons/ai";
const ManageUsers = () => {
  let { UserID } = useParams();
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  function IsValid() {
    if (username === "") {
      var element = document.getElementById("username");
      element.classList.add("required");
      toast.error("Username is required")
      return false;
    } else {
      if (firstName === "") {
        var element = document.getElementById("firstName");
        element.classList.add("required");
        toast.error("First Name is required")
        return false;
      } else {
        if (lastName === "") {
          var element = document.getElementById("lastName");
          element.classList.add("required");
          toast.error("Last Name is required")
          return false;
        } else {
          if (email === "") {
            var element = document.getElementById("email");
            element.classList.add("required");
            toast.error("Email is required")
            return false;
          } else {
            if (phoneNumber === "") {
              var element = document.getElementById("phoneNumber");
              element.classList.add("required");
              toast.error("Phone Number is required")
              return false;
            } else {
              if (selectedRole == 0) {
                var element = document.getElementById("selectedRole");
                element.classList.add("required");
                toast.error("Role is required")
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }
    }
  }

  const HandleSubmit = async (e) => {
    debugger
    if (IsValid()) {
      if (UserID == 0) {
        let response = await fetch(Variables.API_URL + "User/UserRegistration", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Username: username,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            PhoneNumber: phoneNumber,
            RoleId: selectedRole,
          }),
        });
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          navigate("/Users");
        } else {
          toast.error(result);
        }
      }
      else {
        let response = await fetch(Variables.API_URL + "User/UpdateUser/" + UserID, {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Username: username,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            PhoneNumber: phoneNumber,
            RoleId: selectedRole,
          }),
        });
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          navigate("/Users");
        } else {
          toast.error(result);
        }
      }
    }

  };

  async function Getuser(id) {
    const URL = Variables.API_URL + "User/GetUser/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setFirstName(listItems.FirstName);
    setLastName(listItems.LastName);
    setEmail(listItems.Email);
    setUsername(listItems.Username);
    setSelectedRole(listItems.RoleId);
    setPhoneNumber(listItems.PhoneNumber);
  }

  async function GetRoles() {
    const URL = Variables.API_URL + "Role/GetRoles";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllRoles(listItems);
  }

  useEffect(() => {
    if (UserID != 0) {
      Getuser(UserID);
    }
    GetRoles();
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{UserID == "0" ? "Add" : "Update"} User</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Settings">Settings <AiOutlineRight /></Link></li>
              <li><Link to="/Users">Users <AiOutlineRight /></Link></li>
              <li>Manage User</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{username}</h3>
                  </div>
                  <div className="col-lg-4 text-right">
                    <NavLink to="/Users" className="btn btn-secondary">
                      Cancel
                    </NavLink>
                    <button
                      type="submit"
                      className="btn btn-primary ms-2"
                      onClick={HandleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label className="mb-2">Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label className="mb-2">First Name </label>
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12">
                        <label className="mb-2">Last Name </label>
                        <input
                          className="form-control"
                          type="text"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 mb-3 mt-3">
                        <label className="mb-2">Email</label>
                        <input
                          className="form-control"
                          id="email"
                          value={email}
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label className="mb-2"> Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          id="phoneNumber"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label className="mb-2">Role</label>

                        <select
                          className="form-control"
                          id="selectedRole"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <option value={0}>Select Role</option>
                          {allRoles.map((r) => {
                            return (
                              <option value={r.RoleId}> {r.RoleName}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
