import React, { useState, useEffect } from "react";
import { Variables } from "../../Variations/Variations";
import { useParams } from "react-router";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import "../../Styles.css";
import { MdManageSearch, MdOutlineAssignmentReturned } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { BsTrash } from 'react-icons/bs'

const ManageCartPriceRule = () => {
  const navigate = useNavigate();
  let { ID } = useParams();

  //#region Coupon
  const [name, setName] = useState("");
  const [priority, setPriority] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [freeShipping, setFreeShipping] = useState(false);
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [limit, setLimit] = useState(0);
  const [usage, setUsage] = useState(0);
  const [isAppliedToCountries, setIsAppliedToCountries] = useState(false);
  const [isAppliedToGroups, setIsAppliedToGroups] = useState(false);
  const [discardSubsequentRules, setdiscardSubsequentRules] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [discountQuantityStepX, setdiscountQuantityStepX] = useState(0);
  const [discountAmount, setdiscountAmount] = useState(0);
  const [discountQuantityAmountY, setdiscountQuantityAmountY] = useState(0);
  const [maximumQuantityDiscount, setmaximumQuantityDiscount] = useState(0);
  const [XIsAppliedToCategories, setXIsAppliedToCategories] = useState(false);
  const [XIsAppliedToAttributes, setXIsAppliedToAttributes] = useState(false);
  const [XIsAppliedToProducts, setXIsAppliedToProducts] = useState(false);
  const [YIsAppliedToCategories, setYIsAppliedToCategories] = useState(false);
  const [YIsAppliedToAttributes, setYIsAppliedToAttributes] = useState(false);
  const [YIsAppliedToProducts, setYIsAppliedToProducts] = useState(false);

  const fetchPriceRule = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "CartPriceRule/GetCartPriceRule/" + ID
    );
    result = await response.json();

    setName(result.CartPriceRuleName);
    setPriority(result.CouponPriority);
    setStartDate(result.StartDate);
    setExpiryDate(result.ExpiryDate);
    setIsPublished(result.IsPublished);
    setFreeShipping(result.IsFreeShipping);
    setIsAppliedToCountries(result.IsAppliedToCountries);
    setIsAppliedToGroups(result.IsAppliedToGroups);
    setIsCoupon(result.IsCoupon);
    setCouponCode(result.CouponCode);
    setLimit(result.UserLimit);
    setUsage(result.UsageTries);
    setdiscardSubsequentRules(result.DiscardSubsequentRules);
    setdiscountQuantityStepX(result.DiscountQuantityStepX);
    setmaximumQuantityDiscount(result.MaximumQuantityDiscount);
    setdiscountAmount(result.DiscountAmount);
    setdiscountQuantityAmountY(result.DiscountQuantityAmountY);
    setXIsAppliedToCategories(result.XisAppliedToCategories);
    setXIsAppliedToProducts(result.XisAppliedToProducts);
    setXIsAppliedToAttributes(result.XisAppliedToAttributes);

    setYIsAppliedToCategories(result.YisAppliedToCategories);
    setYIsAppliedToProducts(result.YisAppliedToProducts);
    setYIsAppliedToAttributes(result.YisAppliedToAttributes);
  };
  function handleExpiryDate(e) {
    if (e.target.value < startDate) {
      document.getElementById("ExpiryDate").focus();
      setExpiryDate("");
      toast.error("Expity Date < Start Date");
    } else {
      setExpiryDate(e.target.value);
    }
  }
  function handlePriority(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setPriority(0);
    } else {
      setPriority(e.target.value);
    }
  }
  function handleLimit(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setLimit(0);
    } else {
      setLimit(e.target.value);
    }
    if (e.target.value > 2147483647) {
      toast.error("Please Choose A Lower Limit");
    }
  }

  function handleUsage(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setUsage(0);
    } else {
      setUsage(e.target.value);
    }
  }
  function handleDiscountQuantityX(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setdiscountQuantityStepX(0);
    } else {
      setdiscountQuantityStepX(e.target.value);
    }
  }

  function handleDiscountAmountY(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setdiscountQuantityAmountY(0);
    } else {
      setdiscountQuantityAmountY(e.target.value);
    }
  }
  function handleMaximumQuantity(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setmaximumQuantityDiscount(0);
    } else {
      setmaximumQuantityDiscount(e.target.value);
    }
  }
  function handleDiscountAmount(e) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert A Negative Value");
      setdiscountAmount(0);
    } else {
      setdiscountAmount(e.target.value);
    }
    if (e.target.value > 100) {
      toast.error("Please Choose A Number Less Than 100 ");
      setdiscountAmount(0);
    }
  }
  function handleXIsAppliedToCategories(e) {
    setXIsAppliedToCategories(e.target.checked);
    fetchUnAssignedProductsForX(
      XIsAppliedToAttributes,
      e.target.checked,
      assignedAttributeValuesForX,
      assignedCategoriesForX
    );
  }
  function handleXIsAppliedToProducts(e) {
    setXIsAppliedToProducts(e.target.checked);
    fetchUnAssignedProductsForX(
      XIsAppliedToAttributes,
      XIsAppliedToCategories,
      assignedAttributeValuesForX,
      assignedCategoriesForX
    );
  }
  function handleIsCoupon(e) {
    setIsCoupon(e.target.checked)
    if (e.target.checked === false) {
      setLimit(0)
      setUsage(0)
      setCouponCode("")
    }
  }
  function IsValid() {
    if (name === "") {
      var element;
      element = document.getElementById("CouponName");
      element.classList.add("required1");
      toast.error("Please Fill All Required Fields");
      return false;
    }

    if (priority === "") {
      toast.error("Please Fill All Required Fields");
      return false;
    }

    if (startDate === "" || expiryDate === "") {
      toast.error("Please Choose A Start And End Date");
      return false;
    }

    if (isCoupon === true) {
      if (couponCode === "") {
        toast.error("Please Fill All required Fields");
        return false;
      }
      if (limit == 0) {
        toast.error("Please Fill All required Fields");
        return false;
      }
      if (limit > 2147483647) {
        toast.error("Please Choose A Lower Limit");
      }
      if (usage == 0) {
        toast.error("Please Fill All required Fields");
        return false;
      }
    }

    if (discountQuantityStepX === "" || discountQuantityStepX == 0) {
      toast.error("Please Choose Discount Quantity Step (Buy X) ");
      return false;
    }
    if (discountQuantityAmountY === "" || discountQuantityAmountY == 0) {
      toast.error("Please Choose Discount Quantity Step (Get Y)");
      return false;
    }
    if (maximumQuantityDiscount === "" || maximumQuantityDiscount == 0) {
      toast.error("Please Choose Maximum Quantity Discount");
      return false;
    }
    if (discountAmount === "" || discountAmount == 0) {
      toast.error("Please Choose Discount Amount");
      return false;
    }
    if (ID != 0) {
      if (
        XIsAppliedToAttributes === false &&
        XIsAppliedToCategories === false &&
        XIsAppliedToProducts === false &&
        YIsAppliedToAttributes === false &&
        YIsAppliedToCategories === false &&
        YIsAppliedToProducts === false
      ) {
        toast.error("Please Choose Attributes Or Categories Or Products ");
        return false;
      }
      if (XIsAppliedToAttributes === true && YIsAppliedToAttributes === false) {
        toast.error("Please Choose Attributes For Y");
        return false;
      }
      if (XIsAppliedToCategories === true && YIsAppliedToCategories === false) {
        toast.error("Please Choose Categories For Y");
        return false;
      }
      if (XIsAppliedToProducts === true && YIsAppliedToProducts === false) {
        toast.error("Please Choose Products For Y");
        return false;
      }
      if (XIsAppliedToAttributes === false && YIsAppliedToAttributes === true) {
        toast.error("Please Choose Attributes For X");
        return false;
      }
      if (XIsAppliedToCategories === false && YIsAppliedToCategories === true) {
        toast.error("Please Choose Categories For X");
        return false;
      }
      if (XIsAppliedToProducts === false && YIsAppliedToProducts === true) {
        toast.error("Please Choose Products For X");
        return false;
      }
      if (XIsAppliedToAttributes === true && YIsAppliedToAttributes === true) {
        if (assignedAttributeValuesForX.length == 0) {
          toast.error("Please Choose Attributes For X");
          return false;
        }
        if (assignedAttributeValuesForY.length == 0) {
          toast.error("Please Choose Attributes For Y");
          return false;
        }
      }
      if (XIsAppliedToCategories === true && YIsAppliedToCategories === true) {
        if (assignedCategoriesForX.length == 0) {
          toast.error("Please Choose Categories For X");
          return false;
        }
        if (assignedCategoriesForY.length == 0) {
          toast.error("Please Choose Categories For Y");
          return false;
        }
      }
      if (XIsAppliedToProducts === true && YIsAppliedToProducts === true) {
        if (assignedProductsForX.length == 0) {
          toast.error("Please Choose Products For X");
          return false;
        }
        if (assignedProductsForY.length == 0) {
          toast.error("Please Choose Products For Y");
          return false;
        }
      }

      if (XIsAppliedToAttributes === true && YIsAppliedToAttributes === true) {
        var xattributes = 0;
        assignedAttributeValuesForX.forEach((element) => {
          xattributes = xattributes + element.Quantity;
        });

        if (xattributes < discountQuantityStepX) {
          toast.error(
            "Please Choose + " +
            (discountQuantityStepX - xattributes) +
            " Attribute Values For X"
          );
          return false;
        }
        var yattributes = 0;
        assignedAttributeValuesForY.forEach((element) => {
          yattributes = yattributes + element.Quantity;
        });
        if (yattributes < discountQuantityAmountY) {
          toast.error(
            "Please Choose + " +
            (discountQuantityAmountY - yattributes) +
            " Attribute Values For Y"
          );
          return false;
        }
      }

      if (XIsAppliedToCategories === true && YIsAppliedToCategories === true) {
        var xcategories = 0;
        assignedCategoriesForX.forEach((element) => {
          xcategories = xcategories + element.Quantity;
        });
        if (xcategories < discountQuantityStepX) {
          toast.error(
            "Please Choose + " +
            (discountQuantityStepX - xcategories) +
            " Categories For X"
          );
          return false;
        }
        var ycategories = 0;
        assignedCategoriesForY.forEach((element) => {
          ycategories = ycategories + element.Quantity;
        });
        if (ycategories < discountQuantityAmountY) {
          toast.error(
            "Please Choose + " +
            (discountQuantityAmountY - ycategories) +
            " Categories For Y"
          );
          return false;
        }
      }

      if (XIsAppliedToProducts === true && YIsAppliedToProducts) {
        var xproducts = 0;
        assignedProductsForX.forEach((element) => {
          xproducts = xproducts + element.Product.Quantity;
          element.Product.Variation.forEach((v) => {
            xproducts = xproducts + v.Quantity;
          });
        });
        if (xproducts < discountQuantityStepX) {
          toast.error(
            "Please Choose + " +
            (discountQuantityStepX - xproducts) +
            "Products For X"
          );
          return false;
        }

        var yproducts = 0;
        assignedProductsForY.forEach((element) => {
          yproducts = yproducts + element.Product.Quantity;
          element.Product.Variation.forEach((v) => {
            yproducts = yproducts + v.Quantity;
          });
        });
        if (yproducts < discountQuantityAmountY) {
          toast.error(
            "Please Choose + " +
            (discountQuantityAmountY - yproducts) +
            "Products For Y"
          );
          return false;
        }
      }
    }

    return true;
  }

  function HandleSubmit(e) {
    if (IsValid()) {
      e.preventDefault();
      if (ID == 0) {
        const addCoupon = async () => {
          let response = await fetch(
            Variables.API_URL + "CartPriceRule/AddCartPriceRule",
            {
              method: "Post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": Variables.API_URL,
              },
              body: JSON.stringify({
                CartPriceRuleName: name,
                IsFreeShipping: freeShipping,
                StartDate: startDate,
                ExpiryDate: expiryDate,
                IsPublished: isPublished,
                IsCoupon: isCoupon,
                CouponCode: couponCode,
                CouponPriority: priority,
                UsageTries: usage,
                UserLimit: limit,
                IsAppliedToCountries: isAppliedToCountries,
                IsAppliedToGroups: isAppliedToGroups,
                discardSubsequentRules: discardSubsequentRules,
                DiscountQuantityStepX: discountQuantityStepX,
                DiscountAmount: discountAmount,
                DiscountQuantityAmountY: discountQuantityAmountY,
                MaximumQuantityDiscount: maximumQuantityDiscount,
                XIsAppliedToCategories: XIsAppliedToCategories,
                XIsAppliedToAttributes: XIsAppliedToAttributes,
                XIsAppliedToProducts: XIsAppliedToProducts,
                YIsAppliedToCategories: YIsAppliedToCategories,
                YIsAppliedToAttributes: YIsAppliedToAttributes,
                YIsAppliedToProducts: YIsAppliedToProducts,
              }),
            }
          );
          let result = await response.json();
          if (response.status === 200) {
            navigate("/PriceRules/" + result);
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await addCoupon())();
        }, 100);
      } else {
        const updateCoupon = async () => {
          let response = await fetch(
            Variables.API_URL + "CartPriceRule/UpdateCartPriceRule/" + ID,
            {
              method: "Put",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": Variables.API_URL,
              },
              body: JSON.stringify({
                CartPriceRuleName: name,
                IsFreeShipping: freeShipping,
                StartDate: startDate,
                ExpiryDate: expiryDate,
                IsPublished: isPublished,
                IsCoupon: isCoupon,
                CouponCode: couponCode,
                CouponPriority: priority,
                UsageTries: usage,
                UserLimit: limit,
                IsAppliedToCountries: isAppliedToCountries,
                IsAppliedToGroups: isAppliedToGroups,
                discardSubsequentRules: discardSubsequentRules,
                DiscountQuantityStepX: discountQuantityStepX,
                DiscountAmount: discountAmount,
                DiscountQuantityAmountY: discountQuantityAmountY,
                MaximumQuantityDiscount: maximumQuantityDiscount,
                XIsAppliedToCategories: XIsAppliedToCategories,
                XIsAppliedToAttributes: XIsAppliedToAttributes,
                XIsAppliedToProducts: XIsAppliedToProducts,
                YIsAppliedToCategories: YIsAppliedToCategories,
                YIsAppliedToAttributes: YIsAppliedToAttributes,
                YIsAppliedToProducts: YIsAppliedToProducts,
              }),
            }
          );
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            setTimeout(() => {
              navigate("/PriceRules");
            }, 600);
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await updateCoupon())();
        }, 100);
      }
    }
  }
  //#endregion

  //#region Countries
  const [unassignedCountries, setUnassignedCountries] = useState([]);
  const [assignedCountries, setAssignedCountries] = useState([]);
  const [isOpenUnassignedCountries, setIsOpenUnassignedCountries] =
    useState(false);
  const [filteredAssignedCountries, setFilteredAssignedCountries] = useState(
    []
  );
  const [filteredUnAssignedCountries, setFilteredUnAssignedCountries] =
    useState([]);

  const fecthUnAssignedCountries = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignCartPriceRuleToCountry/GetUnAssignedCountriesForPriceRule/" +
      ID
    );
    result = await response.json();

    setUnassignedCountries(result);
    setFilteredUnAssignedCountries(result);
  };
  const fetchAssignedCountries = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignCartPriceRuleToCountry/GetAssignedCountries/" +
      ID
    );
    result = await response.json();
    var array = [];
    for (var i = 0; i < result.length; i++) {
      array.push(result[i]);
    }
    setAssignedCountries(array);
    setFilteredAssignedCountries(array);
  };

  const unassignedCountriesColumns = [
    {
      name: "ID",
      selector: (row) => row.CountryId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.CountryName,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.CountryCode,
      sortable: true,
    },
    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignCountry(row.CountryId)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedCountriesColumns = [
    {
      name: "ID",
      selector: (row) => row.CountryId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.CountryName,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.CountryCode,
      sortable: true,
    },
    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignCountry(row.CountryId)}
        >
          <BsTrash />
        </button>
      ),
    },
  ];

  async function AssignCountry(CountryId) {
    let response = await fetch(
      Variables.API_URL +
      "AssignCartPriceRuleToCountry/AssignCountryToPriceRule",
      {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CountryId: CountryId,
          CartPriceRuleID: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fecthUnAssignedCountries();
      fetchAssignedCountries();
      setIsOpenUnassignedCountries(false);
    } else {
      toast.error(result);
    }
  }

  async function AssignAllCountries() {
    for (var i = 0; i < unassignedCountries.length; i++) {
      let response = await fetch(
        Variables.API_URL +
        "AssignCartPriceRuleToCountry/AssignCountryToPriceRule",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CountryId: unassignedCountries[i].CountryId,
            CartPriceRuleID: ID,
          }),
        }
      );
    }
    fecthUnAssignedCountries();
    fetchAssignedCountries();
  }
  async function UnAssignCountry(CountryId) {
    let response = await fetch(
      Variables.API_URL +
      "AssignCartPriceRuleToCountry/UnAssignCountryToPriceRule",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CountryId: CountryId,
          CartPriceRuleID: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedCountries();
      fecthUnAssignedCountries();
    } else {
      toast.error(result);
    }
  }

  function handleSearchAssignedCountries(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      setFilteredAssignedCountries(filteredAssignedCountries);
    } else {
      result = assignedCountries.filter((data) => {
        return data.CountryName.toLowerCase().search(value) != -1;
      });
      setFilteredAssignedCountries(result);
    }
  }
  function handleSearchUnAssignedCountries(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      setFilteredUnAssignedCountries(filteredUnAssignedCountries);
    } else {
      result = unassignedCountries.filter((data) => {
        return data.CountryName.toLowerCase().search(value) != -1;
      });
      setFilteredUnAssignedCountries(result);
    }
  }

  //#endregion

  //#region Groups
  const [unassignedGroups, setUnassignedGroups] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);
  const [isOpenUnassignedGroups, setIsOpenUnassignedGroups] = useState(false);
  const [filteredUnAssignedGroups, setFilteredUnAssignedGroups] = useState([]);
  const [filteredAssignedGroups, setFilteredAssignedGroups] = useState([]);
  const fecthUnAssignedGroups = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignCartPriceRuleToGroup/GetUnAssignedGroupsForPriceRule/" +
      ID
    );
    result = await response.json();
    setUnassignedGroups(result);
    setFilteredUnAssignedGroups(result);
  };

  const fetchAssignedGroups = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignCartPriceRuleToGroup/GetAssignedGroups/" + ID
    );
    result = await response.json();
    setAssignedGroups(result);
    setFilteredAssignedGroups(result);
  };

  function handleSearchAssignedGroups(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedGroups.filter((data) => {
      return data.GroupName.toLowerCase().search(value) != -1;
    });
    setFilteredAssignedCountries(result);
  }
  function handleSearchUnAssignedGroups(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedGroups.filter((data) => {
      return data.GroupName.toLowerCase().search(value) != -1;
    });
    setFilteredUnAssignedCountries(result);
  }
  const unassignedGroupsColumns = [
    {
      name: "ID",
      selector: (row) => row.CouponGroupId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignGroup(row.CouponGroupId)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedGroupsColumns = [
    {
      name: "ID",
      selector: (row) => row.CouponGroupId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignGroup(row.CouponGroupId)}
        >
          <BsTrash />
        </button>
      ),
    },
  ];
  async function AssignGroup(GroupId) {
    let response = await fetch(
      Variables.API_URL + "AssignCartPriceRuleToGroup/AssignGroupToPriceRule",
      {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          GroupId: GroupId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fecthUnAssignedGroups();
      fetchAssignedGroups();
      setIsOpenUnassignedGroups(false);
    } else {
      toast.error(result);
    }
  }
  async function UnAssignGroup(GroupId) {
    let response = await fetch(
      Variables.API_URL + "AssignCartPriceRuleToGroup/UnAssignGroupToPriceRule",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          GroupId: GroupId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedGroups();
      fecthUnAssignedGroups();
    } else {
      toast.error(result);
    }
  }
  async function AssignAllGroups() {
    for (var i = 0; i < unassignedGroups.length; i++) {
      let response = await fetch(
        Variables.API_URL + "AssignCartPriceRuleToGroup/AssignGroupToPriceRule",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            GroupId: unassignedGroups[i].CouponGroupId,
            CartPriceRuleId: ID,
          }),
        }
      );
    }
    fecthUnAssignedGroups();
    fetchAssignedGroups();
  }

  //#endregion

  //#region Categories For X

  const [isOpenUnAssignedCategoriesOfX, setisOpenUnAssignedCategoriesOfX] =
    useState(false);
  const [assignedCategoriesForX, setassignedCategoriesForX] = useState([]);
  const [filteredAssignedCategoriesForX, setfilteredAssignedCategoriesForX] =
    useState([]);
  const [unassignedCategoriesForX, setunassignedCategoriesForX] = useState([]);
  const [
    filteredUnAssignedCategoriesForX,
    setfilteredUnAssignedCategoriesForX,
  ] = useState([]);
  function handleSearchAssignedCategoriesForX(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedCategoriesForX.filter((data) => {
      return data.CategoryName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedCategoriesForX(result);
  }
  function handleSearchUnAssignedCategoriesForX(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedCategoriesForX.filter((data) => {
      return data.CategoryName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedCategoriesForX(result);
  }

  const unassignedCategoriesForXColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: " Category Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) => handleQuantityOfCategoriesForX(e, row.CategoryId)}
          value={row.Quantity}
          min={0}
          className="form-control"
        />
      ),
    },
    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignCategoryToX(row.CategoryId)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedCategoriesForXColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: " Category Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },

    { name: "Quantity", selector: (row) => row.Quantity },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignCategoryToX(row.CategoryId)}
        >
          <BsTrash />
        </button>
      ),
    },
  ];
  const fecthUnAssignedCategoriesForX = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignXToCategory/GetUnAssignedCategories/" + ID
    );
    result = await response.json();
    setunassignedCategoriesForX(result);
    setfilteredUnAssignedCategoriesForX(result);
  };

  const fetchAssignedCategoriesFoX = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignXToCategory/GetAssignedCategories/" + ID
    );
    result = await response.json();
    setassignedCategoriesForX(result);
    setfilteredAssignedCategoriesForX(result);
    fetchUnAssignedProductsForX(
      XIsAppliedToAttributes,
      XIsAppliedToCategories,
      assignedAttributeValuesForX,
      result
    );
  };

  async function AssignCategoryToX(CategoryId) {
    var quantity = 0;
    assignedCategoriesForX.forEach((element) => {
      quantity = quantity + element.Quantity;
    });

    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;
    filteredUnAssignedCategoriesForX.forEach((element) => {
      if (element.CategoryId == CategoryId) {
        if (element.Quantity != 0 && element.Quantity !== "") {
          if (quantity < discountQuantityStepX) {
            if (element.Quantity <= discountQuantityStepX - quantity) {
              quantityToPost = element.Quantity;
              valid = true;
            }
          }
        } else {
          toastForQuantity = true;
        }
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignXToCategory/AssignCategoryToX",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CategoryId: CategoryId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fecthUnAssignedCategoriesForX();
        fetchAssignedCategoriesFoX();
        setisOpenUnAssignedCategoriesOfX(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityStepX + " X");
    }
  }
  function handleQuantityOfCategoriesForX(e, CategoryId) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedCategoriesForX];

      array.forEach((element) => {
        if (element.CategoryId == CategoryId) {
          element.Quantity = e.target.value;
        }
      });
      setfilteredUnAssignedCategoriesForX(array);
    }
  }
  async function UnAssignCategoryToX(CategoryId) {
    let response = await fetch(
      Variables.API_URL + "AssignXToCategory/UnAssignCategoryToX",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CategoryId: CategoryId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedCategoriesFoX();
      fecthUnAssignedCategoriesForX();
    } else {
      toast.error(result);
    }
  }

  //#endregion

  //#region Attribute Values For X

  const [
    isOpenUnAssignedAttributeValuesOfX,
    setisOpenUnAssignedAttributeValuesOfX,
  ] = useState(false);
  const [assignedAttributeValuesForX, setassignedAttributeValuesForX] =
    useState([]);
  const [
    filteredAssignedAttributeValuesForX,
    setfilteredAssignedAttributeValuesForX,
  ] = useState([]);
  const [unassignedAttributeValuesForX, setunassignedAttributeValuesForX] =
    useState([]);
  const [
    filteredUnAssignedAttributeValuesForX,
    setfilteredUnAssignedAttributeValuesForX,
  ] = useState([]);
  const unassignedAttributeValuesForXColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value Name ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) =>
            handleQuantityOfAttributesForX(e, row.AttributeValueId)
          }
          min={0}
          value={row.Quantity}
          className="form-control"
        />
      ),
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignAttributeValueToX(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  async function AssignAttributeValueToX(row) {
    var quantity = 0;
    assignedAttributeValuesForX.forEach((element) => {
      quantity = quantity + element.Quantity;
    });
    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;
    filteredUnAssignedAttributeValuesForX.forEach((element) => {
      if (element.AttributeValueId == row.AttributeValueId) {
        if (element.Quantity != 0 && element.Quantity !== "") {
          if (quantity < discountQuantityStepX) {
            if (element.Quantity <= discountQuantityStepX - quantity) {
              quantityToPost = element.Quantity;
              valid = true;
            }
          }
        }
      }
    });

    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignXToAttributeValue/AssignAttributeValueToX",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            AttributeValueId: row.AttributeValueId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fecthUnAssignedAttributeValuesForX();
        fetchAssignedAttributeValuesForX();
        setisOpenUnAssignedAttributeValuesOfX(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityStepX + " X");
    }
  }
  function handleQuantityOfAttributesForX(e, AttributeValueId) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedAttributeValuesForX];

      array.forEach((element) => {
        if (element.AttributeValueId == AttributeValueId) {
          element.Quantity = e.target.value;
        }
      });
      setfilteredUnAssignedAttributeValuesForX(array);
    }
  }
  const assignedAttributeValuesForXColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value Name",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },

    {
      name: "Quantity",
      selector: (row) => row.Quantity,
    },
    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignAttributeValueToX(row.AttributeValueId)}
        >
          <BsTrash />
        </button>
      ),
    },
  ];
  const fecthUnAssignedAttributeValuesForX = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignXToAttributeValue/GetUnAssignedAttributeValues/" +
      ID
    );
    result = await response.json();
    if (response.status === 200) {
      setunassignedAttributeValuesForX(result);
      setfilteredUnAssignedAttributeValuesForX(result);
    }
  };
  const fetchAssignedAttributeValuesForX = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignXToAttributeValue/GetAssignedAttributeValues/" +
      ID
    );
    result = await response.json();

    setassignedAttributeValuesForX(result);
    setfilteredAssignedAttributeValuesForX(result);
    fetchUnAssignedProductsForX(
      XIsAppliedToAttributes,
      XIsAppliedToCategories,
      result,
      assignedCategoriesForX
    );
  };

  async function UnAssignAttributeValueToX(AttributeValueId) {
    let response = await fetch(
      Variables.API_URL + "AssignXToAttributeValue/UnAssignAttributeValueToX",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeValueId: AttributeValueId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedAttributeValuesForX();
      fecthUnAssignedAttributeValuesForX();
    } else {
      toast.error(result);
    }
  }
  function handleSearchAssignedAttributeValuesForX(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedAttributeValuesForX.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedAttributeValuesForX(result);
  }
  function handleSearchUnAssignedAttributeValuesForX(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedAttributeValuesForX.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedAttributeValuesForX(result);
  }

  function handleXIsAppliedToAttributeValues(e) {
    setXIsAppliedToAttributes(e.target.checked);
    fetchUnAssignedProductsForX(
      e.target.checked,
      XIsAppliedToCategories,
      assignedAttributeValuesForX,
      assignedCategoriesForX
    );
  }

  //#endregion

  //#region Products For X
  const [isOpenUnAssignedProductsOfX, setisOpenUnAssignedProductsOfX] =
    useState(false);
  const [assignedProductsForX, setassignedProductsForX] = useState([]);
  const [filteredAssignedProductsForX, setfilteredAssignedProductsForX] =
    useState([]);
  const [unassignedProductsForX, setunassignedProductsForX] = useState([]);
  const [filteredUnAssignedProductsForX, setfilteredUnAssignedProductsForX] =
    useState([]);
  const [quantityOfUnassignedProductForX, setquantityOfUnassignedProductForX] =
    useState(0);
  const unassignedExpandableRowsForX = ({ data }) => (
    <div className="portalTable">
      <DataTable
        className="ml-3"
        columns={unassignedvariationForXColumns}
        data={data.Product.Variation}
      />
    </div>
  );
  const assignedExpandableRowsForX = ({ data }) => (
    <div className="portalTable">
      <DataTable
        className="ml-3"
        columns={assignedvariationForXColumns}
        data={data.Product.Variation}
      />
    </div>
  );

  const unassignedProductsForXColumns = [
    {
      name: "ID",
      selector: (row) => row.Product.ProductId,
      sortable: true,
    },
    {
      name: " Product Name ",
      selector: (row) => row.Product.ProductName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) =>
            handleQuantityOfProductsForX(e, row.Product.ProductId)
          }
          min={0}
          value={row.Product.Quantity}
          className="form-control"
        />
      ),
    },

    {
      name: "Assign All",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignProductToX(row.Product.ProductId)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  function handleQuantityOfProductsForX(e, ProductId) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedProductsForX];
      array.forEach((element) => {
        if (element.Product.ProductId == ProductId) {
          element.Product.Quantity = e.target.value;
        }
      });
      setfilteredUnAssignedProductsForX(array);
    }
  }
  function handleQuantityOfProductVariationsForX(
    e,
    ProductVariationId,
    ProductId
  ) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedProductsForX];
      array.forEach((element) => {
        if (element.Product.ProductId == ProductId) {
          element.Product.Variation.forEach((v) => {
            if (v.ProductVariationId == ProductVariationId) {
              v.Quantity = e.target.value;
            }
          });
        }
      });
      setfilteredUnAssignedProductsForX(array);
    }
  }

  const unassignedvariationForXColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: " Sku ",
      selector: (row) => row.Sku,
      sortable: true,
    },
    {
      name: " Regular Price ",
      selector: (row) => row.RegularPrice,
      sortable: true,
    },
    {
      name: " Color ",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: " Size ",
      selector: (row) => row.Size,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) =>
            handleQuantityOfProductVariationsForX(
              e,
              row.ProductVariationId,
              row.ProductId
            )
          }
          value={row.Quantity}
          min={0}
          className="form-control"
        />
      ),
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() =>
            AssignProductVariationToX(row.ProductVariationId, row.ProductId)
          }
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedProductsForXColumns = [
    {
      name: "ID",
      selector: (row) => row.Product.ProductId,
      sortable: true,
    },
    {
      name: " Product Name",
      selector: (row) => row.Product.ProductName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.Product.Quantity,
    },

    {
      name: "UnAssign All",
      selector: (row) => (
        <div>
          {row.Product.IsProduct === true ? (
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => UnAssignProductToX(row.Product.ProductId)}
            >
              <BsTrash />
            </button>
          ) : (
            <button className="btn btn-outline-danger btn-sm" disabled>
              <BsTrash />
            </button>
          )}
        </div>
      ),
    },
  ];

  const assignedvariationForXColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: " Sku ",
      selector: (row) => row.Sku,
      sortable: true,
    },

    {
      name: " Regular Price ",
      selector: (row) => row.RegularPrice,
      sortable: true,
    },
    {
      name: " Color ",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: " Size ",
      selector: (row) => row.Size,
      sortable: true,
    },

    { name: "Quantity", selector: (row) => row.Quantity },

    {
      name: "UnAssign",
      selector: (row) => (
        <div>
          {row.IsProduct === true ? (
            <button className="btn btn-outline-danger btn-sm" disabled id="button">
              <MdOutlineAssignmentReturned />
            </button>
          ) : (
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() =>
                UnAssignProductVariationToX(row.ProductVariationId)
              }
            >
              <MdOutlineAssignmentReturned />
            </button>
          )}
        </div>
      ),
    },
  ];
  const fetchUnAssignedProductsForX = async (
    isAttributes,
    isCategories,
    attributes,
    categories
  ) => {

    var attributeToPost = [];
    var catToPost = [];

    if (isAttributes === true) {
      if (attributes.length != 0) {
        attributes.forEach((element) => {
          attributeToPost.push(element.AttributeValueId);
        });
      }
    }
    if (isCategories === true) {
      if (categories.length != 0) {
        categories.forEach((element) => {
          catToPost.push(element.CategoryId);
        });
      }
    }
    let response1 = await fetch(
      Variables.API_URL + "AssignXToProduct/GetUnAssignedProductsForX/" + ID,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },

        body: JSON.stringify({
          attributes: attributeToPost,
          categories: catToPost,
        }),
      }
    );
    let result1 = await response1.json();
    setunassignedProductsForX(result1);
    setfilteredUnAssignedProductsForX(result1);
  };
  const fetchAssignedProductsForX = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignXToProduct/GetAssignedProducts/" + ID
    );
    result = await response.json();

    var array = [];
    for (var i = 0; i < result.length; i++) {
      array.push(result[i]);
    }
    setassignedProductsForX(array);
    setfilteredAssignedProductsForX(array);
  };
  async function AssignProductToX(ProductId) {

    var quantity = 0;
    assignedProductsForX.forEach((element) => {
      quantity = quantity + element.Product.Quantity;
      element.Product.Variation.forEach((v) => {
        quantity = quantity + v.Quantity;
      });
    });

    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;

    unassignedProductsForX.forEach((element) => {
      if (element.Product.ProductId == ProductId) {
        if (element.Product.Quantity != 0 && element.Product.Quantity !== "") {
          if (quantity < discountQuantityStepX) {
            if (element.Product.Quantity <= discountQuantityStepX - quantity) {
              quantityToPost = element.Product.Quantity;
              valid = true;
            }
          }
        } else {
          toastForQuantity = true;
        }
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignXToProduct/AssignProductToX",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductId: ProductId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fetchUnAssignedProductsForX(
          XIsAppliedToAttributes,
          XIsAppliedToCategories,
          assignedAttributeValuesForX,
          assignedCategoriesForX
        );
        fetchAssignedProductsForX();
        setisOpenUnAssignedProductsOfX(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityStepX + " X");
    }

    fetchUnAssignedProductsForX(
      XIsAppliedToAttributes,
      XIsAppliedToCategories,
      assignedAttributeValuesForX,
      assignedCategoriesForX
    );
  }
  async function UnAssignProductToX(ProductId) {
    let response = await fetch(
      Variables.API_URL + "AssignXToProduct/UnAssignProductToX",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductId: ProductId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchUnAssignedProductsForX(
        XIsAppliedToAttributes,
        XIsAppliedToCategories,
        assignedAttributeValuesForX,
        assignedCategoriesForX
      );
      fetchAssignedProductsForX();
      setisOpenUnAssignedProductsOfX(false);
    } else {
      toast.error(result);
    }
  }
  async function UnAssignProductVariationToX(ProductVariationId) {
    let response = await fetch(
      Variables.API_URL + "AssignXToProduct/UnAssignProductVariationToX",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductVariationId: ProductVariationId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchUnAssignedProductsForX(
        XIsAppliedToAttributes,
        XIsAppliedToCategories,
        assignedAttributeValuesForX,
        assignedCategoriesForX
      );
      fetchAssignedProductsForX();
      setisOpenUnAssignedProductsOfX(false);
    } else {
      toast.error(result);
    }
  }
  function handleSearchAssignedProductsForX(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedProductsForX.filter((data) => {
      return data.ProductName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedProductsForX(result);
  }
  function handleSearchUnAssignedProductsForX(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedProductsForX.filter((data) => {
      return data.ProductName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedProductsForX(result);
  }
  async function AssignProductVariationToX(ProductVariationId, ProductId) {

    var quantity = 0;
    assignedProductsForX.forEach((element) => {
      quantity = quantity + element.Product.Quantity;

      element.Product.Variation.forEach((v) => {
        quantity = quantity + v.Quantity;
      });
    });

    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;
    filteredUnAssignedProductsForX.forEach((element) => {
      if (element.Product.ProductId == ProductId) {
        element.Product.Variation.forEach((v) => {
          if (v.ProductVariationId == ProductVariationId) {
            if (v.Quantity != 0 && v.Quantity !== "") {
              if (quantity < discountQuantityStepX) {
                if (v.Quantity <= discountQuantityStepX - quantity) {
                  quantityToPost = v.Quantity;
                  valid = true;
                }
              }
            } else {
              toastForQuantity = true;
            }
          }
        });
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignXToProduct/AssignProductVariationToX",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductVariationId: ProductVariationId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fetchUnAssignedProductsForX(
          XIsAppliedToAttributes,
          XIsAppliedToCategories,
          assignedAttributeValuesForX,
          assignedCategoriesForX
        );
        fetchAssignedProductsForX();
        setisOpenUnAssignedProductsOfX(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityStepX + " X");
    }
  }
  //#endregion

  //#region Categories For Y

  const [isOpenUnAssignedCategoriesOfY, setisOpenUnAssignedCategoriesOfY] =
    useState(false);
  const [assignedCategoriesForY, setassignedCategoriesForY] = useState([]);
  const [filteredAssignedCategoriesForY, setfilteredAssignedCategoriesForY] =
    useState([]);
  const [unassignedCategoriesForY, setunassignedCategoriesForY] = useState([]);
  const [
    filteredUnAssignedCategoriesForY,
    setfilteredUnAssignedCategoriesForY,
  ] = useState([]);

  function handleSearchAssignedCategoriesForY(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedCategoriesForY.filter((data) => {
      return data.CategoryName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedCategoriesForY(result);
  }
  function handleSearchUnAssignedCategoriesForY(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedCategoriesForY.filter((data) => {
      return data.CategoryName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedCategoriesForY(result);
  }

  const unassignedCategoriesForYColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: " Category Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) => handleQuantityOfCategoriesForY(e, row.CategoryId)}
          value={row.Quantity}
          min={0}
          className="form-control"
        />
      ),
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignCategoryToY(row.CategoryId)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  async function AssignCategoryToY(CategoryId) {
    var quantity = 0;
    assignedCategoriesForY.forEach((element) => {
      quantity = quantity + element.Quantity;
    });

    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;
    filteredUnAssignedCategoriesForY.forEach((element) => {
      if (element.CategoryId == CategoryId) {
        if (element.Quantity != 0 && element.Quantity !== "") {
          if (quantity < discountQuantityAmountY) {
            if (element.Quantity <= discountQuantityAmountY - quantity) {
              quantityToPost = element.Quantity;
              valid = true;
            }
          }
        } else {
          toastForQuantity = true;
        }
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignYToCategory/AssignCategoryToY",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CategoryId: CategoryId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fecthUnAssignedCategoriesForY();
        fetchAssignedCategoriesFoY();
        setisOpenUnAssignedCategoriesOfY(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityAmountY + " Y");
    }
  }
  function handleQuantityOfCategoriesForY(e, CategoryId) {

    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedCategoriesForX];

      array.forEach((element) => {
        if (element.CategoryId == CategoryId) {
          element.Quantity = e.target.value;
        }
      });
      setfilteredUnAssignedCategoriesForY(array);
    }
  }
  function handleYIsAppliedToCategories(e) {
    setYIsAppliedToCategories(e.target.checked);
    fetchUnAssignedProductsForY(
      YIsAppliedToAttributes,
      YIsAppliedToCategories,
      assignedAttributeValuesForY,
      assignedCategoriesForY
    );
  }

  const assignedCategoriesForYColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: " Category Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },
    { name: "Quantity", selector: (row) => row.Quantity },
    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignCategoryToY(row.CategoryId)}
        >
          <BsTrash />
        </button>
      ),
    },
  ];
  const fecthUnAssignedCategoriesForY = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignYToCategory/GetUnAssignedCategories/" + ID
    );
    result = await response.json();
    setunassignedCategoriesForY(result);
    setfilteredUnAssignedCategoriesForY(result);
  };

  const fetchAssignedCategoriesFoY = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignYToCategory/GetAssignedCategories/" + ID
    );
    result = await response.json();
    setassignedCategoriesForY(result);
    setfilteredAssignedCategoriesForY(result);
    fetchUnAssignedProductsForY(
      YIsAppliedToAttributes,
      YIsAppliedToCategories,
      assignedAttributeValuesForY,
      result
    );
  };

  async function UnAssignCategoryToY(CategoryId) {
    let response = await fetch(
      Variables.API_URL + "AssignYToCategory/UnAssignCategoryToY",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CategoryId: CategoryId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedCategoriesFoY();
      fecthUnAssignedCategoriesForY();
    } else {
      toast.error(result);
    }
  }

  //#endregion

  //#region Attribute Values For Y

  const [
    isOpenUnAssignedAttributeValuesOfY,
    setisOpenUnAssignedAttributeValuesOfY,
  ] = useState(false);
  const [assignedAttributeValuesForY, setassignedAttributeValuesForY] =
    useState([]);
  const [
    filteredAssignedAttributeValuesForY,
    setfilteredAssignedAttributeValuesForY,
  ] = useState([]);
  const [unassignedAttributeValuesForY, setunassignedAttributeValuesForY] =
    useState([]);
  const [
    filteredUnAssignedAttributeValuesForY,
    setfilteredUnAssignedAttributeValuesForY,
  ] = useState([]);
  const unassignedAttributeValuesForYColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value Name ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) =>
            handleQuantityOfAttributesForY(e, row.AttributeValueId)
          }
          value={row.Quantity}
          min={0}
          className="form-control"
        />
      ),
    },
    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignAttributeValueToY(row)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedAttributeValuesForYColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value Name",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.Quantity,
    },
    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignAttributeValueToY(row.AttributeValueId)}
        >
          <BsTrash />
        </button>
      ),
    },
  ];
  const fecthUnAssignedAttributeValuesForY = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignYToAttributeValue/GetUnAssignedAttributeValues/" +
      ID
    );
    result = await response.json();
    if (response.status === 200) {
      setunassignedAttributeValuesForY(result);
      setfilteredUnAssignedAttributeValuesForY(result);
    }
  };
  const fetchAssignedAttributeValuesForY = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "AssignYToAttributeValue/GetAssignedAttributeValues/" +
      ID
    );
    result = await response.json();

    setassignedAttributeValuesForY(result);
    setfilteredAssignedAttributeValuesForY(result);
    fetchUnAssignedProductsForY(
      YIsAppliedToAttributes,
      YIsAppliedToCategories,
      result,
      assignedCategoriesForY
    );
  };
  async function AssignAttributeValueToY(row) {
    var quantity = 0;
    assignedAttributeValuesForY.forEach((element) => {
      quantity = quantity + element.Quantity;
    });
    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;
    filteredUnAssignedAttributeValuesForY.forEach((element) => {
      if (element.AttributeValueId == row.AttributeValueId) {
        if (element.Quantity != 0 && element.Quantity !== "") {
          if (quantity < discountQuantityAmountY) {
            if (element.Quantity <= discountQuantityAmountY - quantity) {
              quantityToPost = element.Quantity;
              valid = true;
            }
          }
        } else {
          toastForQuantity = true;
        }
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignYToAttributeValue/AssignAttributeValueToY",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            AttributeValueId: row.AttributeValueId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fecthUnAssignedAttributeValuesForY();
        fetchAssignedAttributeValuesForY();
        setisOpenUnAssignedAttributeValuesOfY(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityAmountY + " Y");
    }
  }
  function handleQuantityOfAttributesForY(e, AttributeValueId) {

    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedAttributeValuesForY];

      array.forEach((element) => {
        if (element.AttributeValueId == AttributeValueId) {
          element.Quantity = e.target.value;
        }
      });
      setfilteredUnAssignedAttributeValuesForY(array);
    }
  }

  async function UnAssignAttributeValueToY(AttributeValueId) {
    let response = await fetch(
      Variables.API_URL + "AssignYToAttributeValue/UnAssignAttributeValueToY",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeValueId: AttributeValueId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedAttributeValuesForY();
      fecthUnAssignedAttributeValuesForY();
    } else {
      toast.error(result);
    }
  }
  function handleSearchAssignedAttributeValuesForY(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedAttributeValuesForY.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedAttributeValuesForY(result);
  }
  function handleSearchUnAssignedAttributeValuesForY(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedAttributeValuesForY.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedAttributeValuesForY(result);
  }

  function handleAppliedToAttributesForY(e) {
    setYIsAppliedToAttributes(e.target.checked);
    fetchUnAssignedProductsForY(
      e.target.checked,
      YIsAppliedToCategories,
      assignedAttributeValuesForY,
      assignedCategoriesForY
    );
  }

  //#endregion

  //#region Products For Y
  const [isOpenUnAssignedProductsOfY, setisOpenUnAssignedProductsOfY] =
    useState(false);
  const [assignedProductsForY, setassignedProductsForY] = useState([]);
  const [filteredAssignedProductsForY, setfilteredAssignedProductsForY] =
    useState([]);
  const [unassignedProductsForY, setunassignedProductsForY] = useState([]);
  const [filteredUnAssignedProductsForY, setfilteredUnAssignedProductsForY] =
    useState([]);
  const unassignedExpandableRowsForY = ({ data }) => (
    <DataTable
      className="ml-3"
      columns={unassignedvariationForYColumns}
      data={data.Product.Variation}
    />
  );
  const assignedExpandableRowsForY = ({ data }) => (
    <DataTable
      className="ml-3"
      columns={assignedvariationForYColumns}
      data={data.Product.Variation}
    />
  );

  const unassignedProductsForYColumns = [
    {
      name: "ID",
      selector: (row) => row.Product.ProductId,
      sortable: true,
    },
    {
      name: " Product Name ",
      selector: (row) => row.Product.ProductName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) =>
            handleQuantityOfProductsForY(e, row.Product.ProductId)
          }
          min={0}
          value={row.Product.Quantity}
          className="form-control"
        />
      ),
    },

    {
      name: "Assign All",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => AssignProductToY(row.Product.ProductId)}
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  function handleQuantityOfProductsForY(e, ProductId) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedProductsForY];
      array.forEach((element) => {
        if (element.Product.ProductId == ProductId) {
          element.Product.Quantity = e.target.value;
        }
      });
      setfilteredUnAssignedProductsForY(array);
    }
  }
  function handleQuantityOfProductVariationsForY(
    e,
    ProductVariationId,
    ProductId
  ) {
    if (e.target.value < 0) {
      toast.error("Cannot Insert Negative Value");
    } else {
      var array = [...filteredUnAssignedProductsForY];
      array.forEach((element) => {
        if (element.Product.ProductId == ProductId) {
          element.Product.Variation.forEach((v) => {
            if (v.ProductVariationId == ProductVariationId) {
              v.Quantity = e.target.value;
            }
          });
        }
      });
      setfilteredUnAssignedProductsForY(array);
    }
  }

  const unassignedvariationForYColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: " Sku ",
      selector: (row) => row.Sku,
      sortable: true,
    },
    {
      name: " Regular Price ",
      selector: (row) => row.RegularPrice,
      sortable: true,
    },
    {
      name: " Color ",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: " Size ",
      selector: (row) => row.Size,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <input
          type="number"
          onChange={(e) =>
            handleQuantityOfProductVariationsForY(
              e,
              row.ProductVariationId,
              row.ProductId
            )
          }
          value={row.Quantity}
          min={0}
          className="form-control"
        />
      ),
    },

    {
      name: "Assign",
      selector: (row) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() =>
            AssignProductVariationToY(row.ProductVariationId, row.ProductId)
          }
        >
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];

  const assignedProductsForYColumns = [
    {
      name: "ID",
      selector: (row) => row.Product.ProductId,
      sortable: true,
    },
    {
      name: " Product Name",
      selector: (row) => row.Product.ProductName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.Product.Quantity,
    },

    {
      name: "UnAssign All",
      selector: (row) => (
        <div>
          {row.Product.IsProduct === true ? (
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => UnAssignProductToY(row.Product.ProductId)}
            >
              <MdOutlineAssignmentReturned />
            </button>
          ) : (
            <button className="btn btn-outline-danger btn-sm" disabled>
              <MdOutlineAssignmentReturned />
            </button>
          )}
        </div>
      ),
    },
  ];

  const assignedvariationForYColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: " Sku ",
      selector: (row) => row.Sku,
      sortable: true,
    },

    {
      name: " Regular Price ",
      selector: (row) => row.RegularPrice,
      sortable: true,
    },
    {
      name: " Color ",
      selector: (row) => row.Color,
      sortable: true,
    },
    {
      name: " Size ",
      selector: (row) => row.Size,
      sortable: true,
    },

    { name: "Quantity", selector: (row) => row.Quantity },

    {
      name: "UnAssign",
      selector: (row) => (
        <div>
          {row.IsProduct === true ? (
            <button className="btn btn-outline-danger btn-sm" disabled id="button">
              <BsTrash />
            </button>
          ) : (
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() =>
                UnAssignProductVariationToY(row.ProductVariationId)
              }
            >
              <BsTrash />
            </button>
          )}
        </div>
      ),
    },
  ];
  const fetchUnAssignedProductsForY = async (
    isAttributes,
    isCategories,
    attributes,
    categories
  ) => {

    var attributeToPost = [];
    var catToPost = [];
    if (isAttributes === true) {
      if (attributes.length != 0) {
        attributes.forEach((element) => {
          attributeToPost.push(element.AttributeValueId);
        });
      }
    }
    if (isCategories === true) {
      if (categories.length != 0) {
        categories.forEach((element) => {
          catToPost.push(element.CategoryId);
        });
      }
    }
    let response1 = await fetch(
      Variables.API_URL + "AssignYToProduct/GetUnAssignedProductsForY/" + ID,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },

        body: JSON.stringify({
          attributes: attributeToPost,
          categories: catToPost,
        }),
      }
    );
    let result1 = await response1.json();
    setunassignedProductsForY(result1);
    setfilteredUnAssignedProductsForY(result1);
  };
  const fetchAssignedProductsForY = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "AssignYToProduct/GetAssignedProducts/" + ID
    );
    result = await response.json();

    var array = [];
    for (var i = 0; i < result.length; i++) {
      array.push(result[i]);
    }
    setassignedProductsForY(array);
    setfilteredAssignedProductsForY(array);
  };
  async function AssignProductToY(ProductId) {

    var quantity = 0;
    assignedProductsForY.forEach((element) => {
      quantity = quantity + element.Product.Quantity;
      element.Product.Variation.forEach((v) => {
        quantity = quantity + v.Quantity;
      });
    });

    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;

    unassignedProductsForY.forEach((element) => {
      if (element.Product.ProductId == ProductId) {
        if (element.Product.Quantity != 0 && element.Product.Quantity !== "") {
          if (quantity < discountQuantityAmountY) {
            if (
              element.Product.Quantity <=
              discountQuantityAmountY - quantity
            ) {
              quantityToPost = element.Product.Quantity;
              valid = true;
            }
          }
        } else {
          toastForQuantity = true;
        }
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignYToProduct/AssignProductToY",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductId: ProductId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fetchUnAssignedProductsForY(
          YIsAppliedToAttributes,
          YIsAppliedToCategories,
          assignedAttributeValuesForY,
          assignedCategoriesForY
        );
        fetchAssignedProductsForY();
        setisOpenUnAssignedProductsOfY(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityAmountY + " Y");
    }

    fetchUnAssignedProductsForY(
      YIsAppliedToAttributes,
      YIsAppliedToCategories,
      assignedAttributeValuesForY,
      assignedCategoriesForY
    );
  }
  async function UnAssignProductToY(ProductId) {
    let response = await fetch(
      Variables.API_URL + "AssignYToProduct/UnAssignProductToY",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductId: ProductId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchUnAssignedProductsForY(
        YIsAppliedToAttributes,
        YIsAppliedToCategories,
        assignedAttributeValuesForY,
        assignedCategoriesForY
      );
      fetchAssignedProductsForY();
      setisOpenUnAssignedProductsOfY(false);
    } else {
      toast.error(result);
    }
  }
  async function UnAssignProductVariationToY(ProductVariationId) {
    let response = await fetch(
      Variables.API_URL + "AssignYToProduct/UnAssignProductVariationToY",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductVariationId: ProductVariationId,
          CartPriceRuleId: ID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchUnAssignedProductsForY(
        YIsAppliedToAttributes,
        YIsAppliedToCategories,
        assignedAttributeValuesForY,
        assignedCategoriesForY
      );
      fetchAssignedProductsForY();
      setisOpenUnAssignedProductsOfY(false);
    } else {
      toast.error(result);
    }
  }
  function handleSearchAssignedProductsForY(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedProductsForY.filter((data) => {
      return data.ProductName.toLowerCase().search(value) != -1;
    });
    setfilteredAssignedProductsForY(result);
  }
  function handleSearchUnAssignedProductsForY(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedProductsForY.filter((data) => {
      return data.ProductName.toLowerCase().search(value) != -1;
    });
    setfilteredUnAssignedProductsForY(result);
  }
  async function AssignProductVariationToY(ProductVariationId, ProductId) {

    var quantity = 0;
    assignedProductsForY.forEach((element) => {
      quantity = quantity + element.Product.Quantity;

      element.Product.Variation.forEach((v) => {
        quantity = quantity + v.Quantity;
      });
    });

    var quantityToPost = 0;
    var valid = false;
    var toastForQuantity = false;
    filteredUnAssignedProductsForY.forEach((element) => {
      if (element.Product.ProductId == ProductId) {
        element.Product.Variation.forEach((v) => {
          if (v.ProductVariationId == ProductVariationId) {
            if (v.Quantity != 0 && v.Quantity !== "") {
              if (quantity < discountQuantityAmountY) {
                if (v.Quantity <= discountQuantityAmountY - quantity) {
                  quantityToPost = v.Quantity;
                  valid = true;
                }
              }
            } else {
              toastForQuantity = true;
            }
          }
        });
      }
    });
    if (toastForQuantity == true) {
      toast.error("Please Choose A Quantity");
    } else if (valid === true) {
      let response = await fetch(
        Variables.API_URL + "AssignYToProduct/AssignProductVariationToY",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductVariationId: ProductVariationId,
            CartPriceRuleId: ID,
            Quantity: quantityToPost,
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        fetchUnAssignedProductsForY(
          YIsAppliedToAttributes,
          YIsAppliedToCategories,
          assignedAttributeValuesForY,
          assignedCategoriesForY
        );
        fetchAssignedProductsForY();
        setisOpenUnAssignedProductsOfY(false);
      } else {
        toast.error(result);
      }
    } else {
      toast.error("Cannot Choose More Than " + discountQuantityAmountY + " Y");
    }
  }
  //#endregion

  useEffect(() => {
    if (ID != 0) {
      fetchPriceRule();

      fecthUnAssignedCountries();
      fetchAssignedCountries();
      fecthUnAssignedGroups();
      fetchAssignedGroups();
      fetchAssignedCategoriesFoX();
      fecthUnAssignedCategoriesForX();
      fetchAssignedAttributeValuesForX();
      fecthUnAssignedAttributeValuesForX();
      fetchAssignedProductsForX();
      fetchUnAssignedProductsForX(
        XIsAppliedToAttributes,
        XIsAppliedToCategories,
        assignedAttributeValuesForX,
        assignedCategoriesForX
      );

      fetchAssignedCategoriesFoY();
      fecthUnAssignedCategoriesForY();
      fetchAssignedAttributeValuesForY();
      fecthUnAssignedAttributeValuesForY();
      fetchAssignedProductsForY();
      fetchUnAssignedProductsForY(
        YIsAppliedToAttributes,
        YIsAppliedToCategories,
        assignedAttributeValuesForY,
        assignedCategoriesForY
      );
    }
  }, [ID]);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>{ID == 0 ? "Add" : "Update"} Price Rule{" "}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/PriceRules">Cart Price Rules <AiOutlineRight /></Link></li>
              <li>Manage Cart Price Rule</li>
            </ul>
          </div>
        </div>
        <div className="card mb-5">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <h3>
                  {name} Price Rule{" "}
                </h3>

              </div>
              <div className="col-6 text-end">
                <button
                  className="btn btn-primary "
                  onClick={HandleSubmit}
                >
                  {" "}
                  Save
                </button>{" "}
                <button
                  className="btn btn-secondary ms-2"
                  onClick={() => navigate("/PriceRules")}
                >
                  Cancel
                </button>{" "}

              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-6">
                    <label>
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="CouponName"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    ></input>
                  </div>

                  <div className="col-6">
                    <label>
                      Priority <span className="required">*</span>{" "}
                    </label>
                    <input
                      type="number"
                      id="CouponPriority"
                      className="form-control"
                      placeholder="Price"
                      min="0"
                      value={priority}
                      onChange={(e) => handlePriority(e)}
                      required
                    ></input>
                  </div>
                </div>

                <div className="form-group row mt-4">
                  <div className="col-3">
                    <label>
                      Start Date <span className="required">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      id="StartDate"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required={true}
                    ></input>
                  </div>

                  <div className="col-3">
                    <label>
                      End Date <span className="required">*</span>{" "}
                    </label>
                    <input
                      type="datetime-local"
                      id="ExpiryDate"
                      className="form-control"
                      value={expiryDate}
                      onChange={(e) => handleExpiryDate(e)}
                      required={true}
                    ></input>
                  </div>

                  <div className="form-check col-2  mt-4">
                    <label className="form-check-label" htmlFor="IsPublished">
                      Published
                    </label>
                    <input
                      type="checkbox"
                      id="IsPublished"
                      className="form-check-input ml-4"
                      value={isPublished}
                      checked={isPublished}
                      onChange={(e) => setIsPublished(e.target.checked)}
                    />
                  </div>
                  <div className="form-check col-2  mt-4">
                    <label
                      className="form-check-label"
                      htmlFor="discardSubsequentRules"
                    >
                      Discard Subsequent Rules
                    </label>
                    <input
                      type="checkbox"
                      id="discardSubsequentRules"
                      className="form-check-input ml-4"
                      value={discardSubsequentRules}
                      checked={discardSubsequentRules}
                      onChange={(e) =>
                        setdiscardSubsequentRules(e.target.checked)
                      }
                    />
                  </div>
                  <div className="form-check col-2  mt-4">
                    <label className="form-check-label" htmlFor="freeShipping">
                      Free Shipping
                    </label>
                    <input
                      type="checkbox"
                      id="discardSubsequentRules"
                      className="form-check-input ml-4"
                      value={freeShipping}
                      checked={freeShipping}
                      onChange={(e) => setFreeShipping(e.target.checked)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-check  col-4  mt-4">
                      <label className="form-check-label" htmlFor="IsCoupon">
                        Coupon
                      </label>
                      <input
                        type="checkbox"
                        id="IsCoupon"
                        className="form-check-input ml-2"
                        value={isCoupon}
                        checked={isCoupon}
                        onChange={(e) => handleIsCoupon(e)}
                      />
                    </div>

                    {isCoupon ? (
                      <div className="row">
                        <div className="col-3 mt-4">
                          <label>
                            Coupon Code <span className="required">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            id="CouponCode"
                            className="form-control"
                            placeholder="Code"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            required
                          ></input>
                        </div>
                        <div className="col-3 mt-4">
                          <label>
                            Limit <span className="required">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              id="UserLimit"
                              className="form-control"
                              placeholder="Limit"
                              min="0"
                              value={limit}
                              onChange={(e) => handleLimit(e)}
                              required
                            ></input>
                            <span className="input-group-text">/ coupon</span>
                          </div>
                        </div>
                        <div className="col-3 mt-4">
                          <label>
                            Usage <span className="required">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              id="UsageTries"
                              className="form-control"
                              placeholder="Usage"
                              min="0"
                              value={usage}
                              onChange={(e) => handleUsage(e)}
                              required
                            ></input>
                            <span className="input-group-text">/ customer</span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row">
                      <div className="col-6 mt-4">
                        <label>
                          {" "}
                          Discount Quantity Step (Buy X){" "}
                          <span className="required">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={discountQuantityStepX}
                          min={0}
                          onChange={(e) => handleDiscountQuantityX(e)}
                        />
                      </div>
                      <div className="col-6 mt-4">
                        <label>
                          {" "}
                          Discount Quantity Step (Get Y){" "}
                          <span className="required">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={discountQuantityAmountY}
                          min={0}
                          onChange={(e) => handleDiscountAmountY(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 mt-4">
                        <label>
                          {" "}
                          Maximum Quantity Discount{" "}
                          <span className="required">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={maximumQuantityDiscount}
                          min={0}
                          onChange={(e) => handleMaximumQuantity(e)}
                        />
                      </div>

                      <div className="col-6 mt-4">
                        <label>
                          Discount Amount <span className="required">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            id="DiscountAmount"
                            className="form-control"
                            placeholder="Limit"
                            min="0"
                            max={100}
                            value={discountAmount}
                            onChange={(e) => handleDiscountAmount(e)}
                            required
                          ></input>
                          <span className="input-group-text"> %</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {ID != 0 ? (
                    <div className="col-12">
                      <div className="form-group row mt-4">
                        <div className="row">
                          <label>Applied To : </label>
                        </div>
                        <div className="row mt-3">
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToCountries"
                            >
                              Country
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToCountries"
                              className="form-check-input"
                              value={isAppliedToCountries}
                              checked={isAppliedToCountries}
                              onChange={(e) =>
                                setIsAppliedToCountries(e.target.checked)
                              }
                            />
                          </div>
                          <div className="form-check col-2 pl-38">
                            <label
                              className="form-check-label"
                              htmlFor="IsAppliedToGroups"
                            >
                              Group
                            </label>
                            <input
                              type="checkbox"
                              id="IsAppliedToGroups"
                              className="form-check-input"
                              value={isAppliedToGroups}
                              checked={isAppliedToGroups}
                              onChange={(e) =>
                                setIsAppliedToGroups(e.target.checked)
                              }
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <label>X Is Applied To : </label>
                        </div>
                        <div className="row mt-3">
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="XIsAppliedToCategories"
                            >
                              Category
                            </label>
                            <input
                              type="checkbox"
                              id="XIsAppliedToCategories"
                              className="form-check-input"
                              value={XIsAppliedToCategories}
                              checked={XIsAppliedToCategories}
                              onChange={(e) => handleXIsAppliedToCategories(e)}
                            />
                          </div>
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="XIsAppliedToAttributes"
                            >
                              Attribute Value
                            </label>
                            <input
                              type="checkbox"
                              id="XIsAppliedToAttributes"
                              className="form-check-input"
                              value={XIsAppliedToAttributes}
                              checked={XIsAppliedToAttributes}
                              onChange={(e) =>
                                handleXIsAppliedToAttributeValues(e)
                              }
                            />
                          </div>
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="XIsAppliedToProducts"
                            >
                              Product
                            </label>
                            <input
                              type="checkbox"
                              id="XIsAppliedToProducts"
                              className="form-check-input"
                              value={XIsAppliedToProducts}
                              checked={XIsAppliedToProducts}
                              onChange={(e) => handleXIsAppliedToProducts(e)}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <label>Y Is Applied To : </label>
                        </div>
                        <div className="row mt-3">
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="YIsAppliedToCategories"
                            >
                              Category
                            </label>
                            <input
                              type="checkbox"
                              id="YIsAppliedToCategories"
                              className="form-check-input"
                              value={YIsAppliedToCategories}
                              checked={YIsAppliedToCategories}
                              onChange={(e) => handleYIsAppliedToCategories(e)}
                            />
                          </div>
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="YIsAppliedToAttributes"
                            >
                              Attribute Value
                            </label>
                            <input
                              type="checkbox"
                              id="YIsAppliedToAttributes"
                              className="form-check-input"
                              value={YIsAppliedToAttributes}
                              checked={YIsAppliedToAttributes}
                              onChange={(e) => handleAppliedToAttributesForY(e)}
                            />
                          </div>
                          <div className="form-check col-2 pl-38 ">
                            <label
                              className="form-check-label"
                              htmlFor="YIsAppliedToProducts"
                            >
                              Product
                            </label>
                            <input
                              type="checkbox"
                              id="YIsAppliedToProducts"
                              className="form-check-input"
                              value={YIsAppliedToProducts}
                              checked={YIsAppliedToProducts}
                              onChange={(e) => {
                                setYIsAppliedToProducts(e.target.checked);
                                fetchUnAssignedProductsForY(
                                  YIsAppliedToAttributes,
                                  YIsAppliedToCategories,
                                  assignedAttributeValuesForY,
                                  assignedCategoriesForY
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAppliedToCountries ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Countries</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setIsOpenUnassignedCountries(true)}
                  >
                    Assign Country
                  </button>
                  <Modal
                    show={isOpenUnassignedCountries}
                    onHide={() => setIsOpenUnassignedCountries(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="row mb-3">
                                <div className="col-8"></div>
                                <div className="col-4 text-end tblSearchBar position-relative">
                                  <span>
                                    <MdManageSearch />
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Search any field in table"
                                    className="form-control form-control-sm"
                                    onChange={(e) => handleSearchUnAssignedCountries(e)}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="row">
                              <div className="portalTable">
                                <DataTable
                                  columns={unassignedCountriesColumns}
                                  data={filteredUnAssignedCountries}
                                  pagination
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() => setIsOpenUnassignedCountries(0)}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              className="ml-20"
                              onClick={AssignAllCountries}
                            >
                              Assign All
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedCountries(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedCountriesColumns}
                  data={filteredAssignedCountries}
                  pagination
                />
              </div>
            </div>
          </div>
        ) : null}

        {/* Assigned Groups */}
        {isAppliedToGroups ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Groups</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setIsOpenUnassignedGroups(true)}
                  >
                    Assign Groups
                  </button>
                  <Modal
                    show={isOpenUnassignedGroups}
                    onHide={() => setIsOpenUnassignedGroups(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col-8"></div>
                              <div className="col-4 text-end tblSearchBar position-relative">
                                <span>
                                  <MdManageSearch />
                                </span>
                                <input
                                  type="text"
                                  placeholder="Search any field in table"
                                  className="form-control form-control-sm"
                                  onChange={(e) => handleSearchUnAssignedGroups(e)}
                                ></input>
                              </div>
                            </div>
                            <div className="portalTable">
                              <DataTable
                                columns={unassignedGroupsColumns}
                                data={filteredUnAssignedGroups}
                                pagination
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() => setIsOpenUnassignedGroups(false)}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              className="ml-20"
                              onClick={AssignAllGroups}
                            >
                              Assign All
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedGroups(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedGroupsColumns}
                  data={filteredAssignedGroups}
                  pagination
                />
              </div>
            </div>
          </div>
        ) : null}

        {XIsAppliedToCategories ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Categories Of X</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setisOpenUnAssignedCategoriesOfX(true)}
                  >
                    Assign Category
                  </button>
                  <Modal
                    show={isOpenUnAssignedCategoriesOfX}
                    onHide={() => setisOpenUnAssignedCategoriesOfX(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="row mb-3">
                                <div className="col-8"></div>
                                <div className="col-4 text-end tblSearchBar position-relative">
                                  <span>
                                    <MdManageSearch />
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Search any field in table"
                                    className="form-control form-control-sm"
                                    onChange={(e) => handleSearchUnAssignedCategoriesForX(e)}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="portalTable">
                                <DataTable
                                  columns={unassignedCategoriesForXColumns}
                                  data={filteredUnAssignedCategoriesForX}
                                  pagination
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() => setisOpenUnAssignedCategoriesOfX(0)}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedCategoriesForX(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedCategoriesForXColumns}
                  data={filteredAssignedCategoriesForX}
                  pagination
                />
              </div>
            </div>
          </div>
        ) : null}

        {XIsAppliedToAttributes ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Attribute Values Of X</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setisOpenUnAssignedAttributeValuesOfX(true)}
                  >
                    Assign Attribute Value
                  </button>
                  <Modal
                    show={isOpenUnAssignedAttributeValuesOfX}
                    onHide={() => setisOpenUnAssignedAttributeValuesOfX(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col-8"></div>
                              <div className="col-4 text-end tblSearchBar position-relative">
                                <span>
                                  <MdManageSearch />
                                </span>
                                <input
                                  type="text"
                                  placeholder="Search any field in table"
                                  className="form-control form-control-sm"
                                  onChange={(e) => handleSearchUnAssignedAttributeValuesForX(e)}
                                ></input>
                              </div>
                            </div>
                            <div className="portalTable">
                              <DataTable
                                columns={unassignedAttributeValuesForXColumns}
                                data={filteredUnAssignedAttributeValuesForX}
                                pagination
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() =>
                                setisOpenUnAssignedAttributeValuesOfX(false)
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedAttributeValuesForX(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedAttributeValuesForXColumns}
                  data={filteredAssignedAttributeValuesForX}
                  pagination
                />
              </div>
            </div>
          </div>
        ) : null}

        {XIsAppliedToProducts ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Products Of X</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setisOpenUnAssignedProductsOfX(true);
                      fetchUnAssignedProductsForX(
                        XIsAppliedToAttributes,
                        XIsAppliedToCategories,
                        assignedAttributeValuesForX,
                        assignedCategoriesForX
                      );
                    }}
                  >
                    Assign Product
                  </button>
                  <Modal
                    show={isOpenUnAssignedProductsOfX}
                    onHide={() => setisOpenUnAssignedProductsOfX(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col-8"></div>
                              <div className="col-4 text-end tblSearchBar position-relative">
                                <span>
                                  <MdManageSearch />
                                </span>
                                <input
                                  type="text"
                                  placeholder="Search any field in table"
                                  className="form-control form-control-sm"
                                  onChange={(e) => handleSearchUnAssignedProductsForX(e)}
                                ></input>
                              </div>
                            </div>
                            <div className="portalTable">
                              <DataTable
                                columns={unassignedProductsForXColumns}
                                data={filteredUnAssignedProductsForX}
                                pagination
                                expandableRows
                                expandableRowsComponent={unassignedExpandableRowsForX}
                                expandableRowDisabled={(row) => row.disabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() =>
                                setisOpenUnAssignedProductsOfX(false)
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedProductsForX(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedProductsForXColumns}
                  data={filteredAssignedProductsForX}
                  pagination
                  expandableRows
                  expandableRowsComponent={assignedExpandableRowsForX}
                  expandableRowDisabled={(row) => row.disabled}
                />
              </div>
            </div>
          </div>
        ) : null}

        {YIsAppliedToCategories ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Categories Of Y</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setisOpenUnAssignedCategoriesOfY(true)}
                  >
                    Assign Category
                  </button>
                  <Modal
                    show={isOpenUnAssignedCategoriesOfY}
                    onHide={() => setisOpenUnAssignedCategoriesOfY(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="row mb-3">
                                <div className="col-8"></div>
                                <div className="col-4 text-end tblSearchBar position-relative">
                                  <span>
                                    <MdManageSearch />
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Search any field in table"
                                    className="form-control form-control-sm"
                                    onChange={(e) => handleSearchUnAssignedCategoriesForY(e)}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="portalTable">
                                <DataTable
                                  columns={unassignedCategoriesForYColumns}
                                  data={filteredUnAssignedCategoriesForY}
                                  pagination
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() => setisOpenUnAssignedCategoriesOfY(0)}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedCategoriesForY(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedCategoriesForYColumns}
                  data={filteredAssignedCategoriesForY}
                  pagination
                />
              </div>
            </div>
          </div>
        ) : null}

        {YIsAppliedToAttributes ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Attribute Values Of Y</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setisOpenUnAssignedAttributeValuesOfY(true)}
                  >
                    Assign Attribute Value
                  </button>
                  <Modal
                    show={isOpenUnAssignedAttributeValuesOfY}
                    onHide={() => setisOpenUnAssignedAttributeValuesOfY(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col-8"></div>
                              <div className="col-4 text-end tblSearchBar position-relative">
                                <span>
                                  <MdManageSearch />
                                </span>
                                <input
                                  type="text"
                                  placeholder="Search any field in table"
                                  className="form-control form-control-sm"
                                  onChange={(e) => handleSearchUnAssignedAttributeValuesForY(e)}
                                ></input>
                              </div>
                            </div>
                            <div className="portalTable">
                              <DataTable
                                columns={unassignedAttributeValuesForYColumns}
                                data={filteredUnAssignedAttributeValuesForY}
                                pagination
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() =>
                                setisOpenUnAssignedAttributeValuesOfY(false)
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedAttributeValuesForY(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedAttributeValuesForYColumns}
                  data={filteredAssignedAttributeValuesForY}
                  pagination
                />
              </div>
            </div>
          </div>
        ) : null}

        {YIsAppliedToProducts ? (
          <div className="card mb-5">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="card-title">Assigned Products Of Y</h5>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setisOpenUnAssignedProductsOfY(true);
                      fetchUnAssignedProductsForY(
                        YIsAppliedToAttributes,
                        YIsAppliedToCategories,
                        assignedAttributeValuesForY,
                        assignedCategoriesForY
                      );
                    }}
                  >
                    Assign Product
                  </button>
                  <Modal
                    show={isOpenUnAssignedProductsOfY}
                    onHide={() => setisOpenUnAssignedProductsOfY(false)}
                    fullscreen={fullscreen}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col-8"></div>
                              <div className="col-4 text-end tblSearchBar position-relative">
                                <span>
                                  <MdManageSearch />
                                </span>
                                <input
                                  type="text"
                                  placeholder="Search any field in table"
                                  className="form-control form-control-sm"
                                  onChange={(e) => handleSearchUnAssignedProductsForY(e)}
                                ></input>
                              </div>
                            </div>
                            <div className="portalTable">
                              <DataTable
                                columns={unassignedProductsForYColumns}
                                data={filteredUnAssignedProductsForY}
                                pagination
                                expandableRows
                                expandableRowsComponent={unassignedExpandableRowsForY}
                                expandableRowDisabled={(row) => row.disabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button
                              variant="secondary"
                              onClick={() =>
                                setisOpenUnAssignedProductsOfY(false)
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-3">
                <div className="col-8"></div>
                <div className="col-4 text-end tblSearchBar position-relative">
                  <span>
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search any field in table"
                    className="form-control form-control-sm"
                    onChange={(e) => handleSearchAssignedProductsForY(e)}
                  ></input>
                </div>
              </div>
              <div className="portalTable">
                <DataTable
                  columns={assignedProductsForYColumns}
                  data={filteredAssignedProductsForY}
                  pagination
                  expandableRows
                  expandableRowsComponent={assignedExpandableRowsForY}
                  expandableRowDisabled={(row) => row.disabled}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ManageCartPriceRule;
