
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";
import DataTable from "react-data-table-component";
import { MdOutlineAssignmentReturned, MdOutlineDelete, MdManageSearch } from 'react-icons/md'
import { AiOutlineRight } from 'react-icons/ai'

const ManageWidget = () => {
  let { WidgetID } = useParams();
  let navigate = useNavigate();
  const [name, setName] = useState("")
  const [allCategories, setAllCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [assignedAttributeValues, setAssignedAttributeValues] = useState([]);
  const [filteredAssignedAttributeValues, setFilteredAssignedAttributeValues] = useState([]);
  const [unassignedAttributeValues, setUnassignedAttributeValues] = useState([]);
  const [filteredUnassignedAttributeValues, setFilteredUnassignedAttributeValues] = useState([]);
  const [isOpenUnAssignedAttributeValues, setisOpenUnAssignedAttributeValues] = useState(false)



  const unassignedAttributeValuesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value  ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: " Attribute  ",
      selector: (row) => row.Attribute1,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button className="btn btn-outline-secondary btn-sm" onClick={() => AssignAttributeValue(row)}>
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedAttributeValuesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: " Attribute  ",
      selector: (row) => row.Attribute1,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignAttributeValue(row.AttributeValueId)}
        >
          <MdOutlineDelete />
        </button>
      ),
    },
  ];


  async function AssignAttributeValue(row) {
    let response = await fetch(
      Variables.API_URL +
      "Widgets/AssignAttributeValueToWidget",
      {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeValueId: row.AttributeValueId,
          WidgetId: WidgetID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fecthUnAssignedAttributeValues();
      fetchAssignedAttributeValues();

      setisOpenUnAssignedAttributeValues(false);
    } else {
      toast.error(result);
    }
  }

  async function UnAssignAttributeValue(AttributeValueId) {
    let response = await fetch(
      Variables.API_URL +
      "Widgets/UnassignAttributeValueToWidget",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeValueId: AttributeValueId,
          WidgetId: WidgetID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedAttributeValues();
      fecthUnAssignedAttributeValues();
    } else {
      toast.error(result);
    }
  }
  const fecthUnAssignedAttributeValues = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "Widgets/GetUnAssignedAttributeValues/" +
      WidgetID
    );
    result = await response.json();
    if (response.status === 200) {

      setUnassignedAttributeValues(result);
      setFilteredUnassignedAttributeValues(result);
    }
  };
  const fetchAssignedAttributeValues = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL +
      "Widgets/GetAssignedAttributeValues/" +
      WidgetID
    );
    result = await response.json();
    setAssignedAttributeValues(result);
    setFilteredAssignedAttributeValues(result);

  };

  function handleSearchAssignedAttributeValues(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedAttributeValues.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) !== -1;
    });
    setFilteredAssignedAttributeValues(result);
  }
  function handleSearchUnAssignedAttributeValues(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedAttributeValues.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) !== -1;
    });
    setFilteredUnassignedAttributeValues(result);
  }
  function IsValid() {
    if (name === "") {
      toast.error("Widget Name Required");
      document.getElementById("name").focus();
      return false;
    }
    if (selectedCategories.length === 0) {
      toast.error("Please assign a category")
      return false;
    }
    return true
  }


  function HandleSubmit(e) {
    debugger;
    e.preventDefault();
    if (IsValid()) {
      if (WidgetID === 0) {
        const addwiget = async () => {
          let response = await fetch(Variables.API_URL + "Widgets/AddWidget", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Title: name,
              IsPublished: false
            }),
          });
          let result = await response.json();
          if (response.status === 200) {

            const URL = Variables.API_URL + "Widgets/AssignCategoryToWidget/" + result;
            fetch(URL, {
              method: 'Post',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(
                selectedCategories.map((item) => ({
                  WidgetId: result,
                  CategoryId: item.CategoryId,
                }))
              ),
            }).then(response => {
              if (response.ok) {
                toast.success("Added Successfully")
                navigate("/Widgets/" + result)
              }
              else {
                toast.error(response);
              }
            })


          }
          else {
            toast.error(result);
          }
        }
        setTimeout(() => {
          (async () => await addwiget())();
        }, 100);
      }
      else {

        const URL = Variables.API_URL + "Widgets/UpdateWidget/" + WidgetID;
        fetch(URL, {
          method: 'Put',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Title: name,

          })
        }).then(response => {
          if (response.ok) {

            const URL = Variables.API_URL + "Widgets/AssignCategoryToWidget/" + WidgetID;
            fetch(URL, {
              method: 'Post',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(
                selectedCategories.map((item) => ({
                  WidgetId: WidgetID,
                  CategoryId: item.CategoryId,
                }))
              ),
            }).then(response => {
              if (response.ok) {
                toast.success("Added Successfully")
                navigate("/Widgets")
              }
              else {
                toast.error(response);
              }
            })


          }
          else {
            toast.error(response);
          }
        })



      }
    }
  }
  async function GetWidget(id) {
    const URL = Variables.API_URL + "Widgets/GetWidget/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setName(listItems.Title)
    setAllCategories(listItems.Categories)
  }
  async function GetCategories() {
    const URL = Variables.API_URL + "Category/GetCategories";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllCategories(listItems)
  }

  async function GetAssignedCategories(id) {
    const URL = Variables.API_URL + "Widgets/GetAssignedCategories/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSelectedCategories(listItems)
  }


  function handleCategories(e) {
    setSelectedCategories(e)
  }


  useEffect(() => {
    if (WidgetID !== 0) {
      GetWidget(WidgetID);
      GetAssignedCategories(WidgetID);
      fecthUnAssignedAttributeValues();
      fetchAssignedAttributeValues();
    }
    GetCategories()
  }, [])


  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{WidgetID === '0' ? 'Add' : 'Manage ' + name} Widget</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Widgets">Widgets <AiOutlineRight /></Link></li>
              <li>Manage Widget</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 text-end">
                    <NavLink to='/Widgets' className='btn btn-secondary'>Cancel</NavLink>
                    <button type="submit" className="btn btn-primary me-2" onClick={HandleSubmit}>Save</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <label className="mb-2">Widget Title <span className="required">*</span></label>
                        <input className="form-control" id="name" onChange={(e) => setName(e.target.value)} value={name !== '' ? name : ""} />
                      </div>
                      <div className="col-lg-12 mt-3">
                        <label className="mb-2">Categories</label>
                        <Multiselect
                          options={allCategories}
                          selectedValues={
                            selectedCategories
                          }
                          dis
                          displayValue="CategoryName"
                          onSelect={handleCategories}
                          onRemove={handleCategories}
                          placeholder="Select Categories"
                        />
                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
            <br />
            <br />
            {WidgetID !== 0 &&
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title">Assigned Attribute Values</h5>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => setisOpenUnAssignedAttributeValues(true)}
                      >
                        Assign Attribute Value
                      </button>
                      <Modal
                        dialogClassName="w-max"
                        show={isOpenUnAssignedAttributeValues}
                        onHide={() => setisOpenUnAssignedAttributeValues(false)}

                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Assign Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="row mb-3">
                                  <div className="col-8"></div>
                                  <div className="col-4 text-right tblSearchBar position-relative">
                                    <span>
                                      <MdManageSearch />
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Search any field in table"
                                      className="form-control form-control-sm"
                                      onChange={(e) => handleSearchUnAssignedAttributeValues(e)}
                                    ></input>
                                  </div>
                                </div>
                                <div className="portalTable">
                                  <DataTable
                                    columns={unassignedAttributeValuesColumns}
                                    data={filteredUnassignedAttributeValues}
                                    pagination
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 text-right">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setisOpenUnAssignedAttributeValues(false)
                                  }
                                >
                                  Close
                                </Button>

                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8"></div>
                    <div className="col-4 text-right tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search any field in table"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearchAssignedAttributeValues(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="portalTable">
                    <DataTable
                      columns={assignedAttributeValuesColumns}
                      data={filteredAssignedAttributeValues}
                      pagination
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </div >
  )
};

export default ManageWidget;
