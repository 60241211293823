import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Variables } from "../../Variations/Variations";
import { BiStore, BiEdit, BiShowAlt, BiHide, BiTrash } from "react-icons/bi";
import { FaStoreSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Util from "../../Classes/Util";
import { AiOutlineRight, AiOutlineClear } from "react-icons/ai";
import { MdManageSearch } from "react-icons/md";
import axios from "axios";

const ListProducts = () => {
  const [listProducts, setProducts] = useState();
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  let images = [];
  const [isOpenDeleteAll, setIsOpenDeleteAll] = useState(false);
  const [isOpenSkuSearch, setIsOpenSkuSearch] = useState(false);
  const [searchbysku, setSearchbysku] = useState("");
  function handleProducts(e, ProductId) {
    var updatedList = [...checkedProducts];

    if (e.target.checked) {
      updatedList = [...updatedList, { ProductId: ProductId }];
    } else {
      updatedList = updatedList.filter((item) => item.ProductId !== ProductId);
    }
    setCheckedProducts(updatedList);
    if (updatedList.length !== 0) {
      setShow(true);
    } else {
      setShow(false);
    }
    updatedList = [];
  }
  const handleInStock = async () => {
    let response = await fetch(Variables.API_URL + "Products/UpdateToInStock", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
      body: JSON.stringify(
        checkedProducts.map((item) => ({
          ProductId: item.ProductId,
        }))
      ),
    });
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      setShow(false);
      setCheckedProducts([]);
      setCheckAll(false);
    } else {
      toast.error("Please Check With Administrator");
    }
  };
  const handleOutOfStock = async () => {
    let response = await fetch(
      Variables.API_URL + "Products/UpdateToOutOfStock",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
        body: JSON.stringify(
          checkedProducts.map((item) => ({
            ProductId: item.ProductId,
          }))
        ),
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
    } else {
      toast.error("Please Check With Administrator");
    }
    setShow(false);
    setCheckedProducts([]);
    setCheckAll(false);
  };
  const handleOnBackorder = async () => {
    let response = await fetch(
      Variables.API_URL + "Products/UpdateToBakcorder",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
        body: JSON.stringify(
          checkedProducts.map((item) => ({
            ProductId: item.ProductId,
          }))
        ),
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
    } else {
      toast.error("Please Check With Administrator");
    }
    setShow(false);
    setCheckedProducts([]);
    setCheckAll(false);
  };

  const handleDeleteProducts = async () => {
    let response = await fetch(Variables.API_URL + "Products/DeleteProducts", {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
      body: JSON.stringify(
        checkedProducts.map((item) => ({
          ProductId: item.ProductId,
        }))
      ),
    });
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
    } else {
      toast.error("Please Check With Administrator");
    }
    setShow(false);
    setCheckedProducts([]);
    setCheckAll(false);
  };
  const handlePublishAll = async () => {
    let response = await fetch(
      Variables.API_URL + "Products/TogglePublishAllProducts",
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
        body: JSON.stringify(
          checkedProducts.map((item) => ({
            ProductId: item.ProductId,
          }))
        ),
      }
    );
    let result = await response.json();
    if (response.ok) {
      GetProducts();
      setShow(false);
      setCheckedProducts([]);

      setCheckAll(false);
      toast.success(result);
    } else {
      toast.error("Please Check With Administrator");
    }
  };

  const handleUnPublishAll = async () => {
    let response = await fetch(
      Variables.API_URL + "Products/ToggleUnPublishAllProducts",
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
        body: JSON.stringify(
          checkedProducts.map((item) => ({
            ProductId: item.ProductId,
          }))
        ),
      }
    );
    let result = await response.json();
    if (response.ok) {
      GetProducts();
      setShow(false);
      setCheckedProducts([]);

      setCheckAll(false);
      toast.success(result);
    } else {
      toast.error("Please Check With Administrator");
    }
  };

  function handleAllProducts(e) {
    setCheckAll(e.target.checked);
    var array = [];
    var obj = {};

    for (var i = 0; i < filteredList.length; i++) {
      obj = { ProductId: filteredList[i].ProductId };
      array.push(obj);
      if (e.target.checked) {
        setCheckedProducts(array);
        setShow(true);
      } else {
        setCheckedProducts([]);
        setShow(false);
      }
    }
  }

  const columns = [
    {
      name: (
        <div className="form-check" style={{ width: "15px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            onChange={(e) => handleAllProducts(e)}
            value={checkAll}
            checked={checkAll}
          />
        </div>
      ),
      selector: (row) => (
        <div style={{ width: "15px" }}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              value={row.ProductId}
              onChange={(e) => handleProducts(e, row.ProductId)}
              checked={
                checkedProducts.filter(
                  (value) => value.ProductId === row.ProductId
                ).length !== 0
                  ? checkedProducts.filter(
                      (value) => value.ProductId === row.ProductId
                    )[0].ProductId
                  : 0
              }
            />
          </div>
        </div>
      ),
    },
    {
      name: "ID",
      selector: (row) => <label> {row.ProductId}</label>,
    },
    {
      name: "Image",
      selector: (row) => (
        <>
          {row.ProductTypeId === 2 ? (
            <img
              src={
                Variables.API_PUBLISHED +
                images
                  .filter((child) => child.ProductId === row.ProductId)
                  .map((c) => c.Image)
              }
              style={{ width: "100px", padding: "5px" }}
              alt="SIS"
            />
          ) : (
            <img
              src={Variables.API_PUBLISHED + row.ProductImage}
              style={{ width: "100px", padding: "5px" }}
              alt="SIS"
            />
          )}
        </>
      ),
    },
    {
      name: (
        <>
          <label> Product Name </label>
          {/* <input
            type="text"
            placeholder="Search By Product Name"
            onChange={(e) => handleSearchByProductName(e)}
          /> */}
        </>
      ),
      selector: (row) => row.ProductName,
    },
    {
      name: (
        <>
          <label> SKU</label>
          {/* <input
            type="text"
            placeholder="Search By SKU"
            onChange={(e) => handleSearchBySku(e)}
          /> */}
        </>
      ),
      selector: (row) => row.Sku,
    },
    {
      name: "Short Description ",
      selector: (row) => row.SmallDescription,
    },
    {
      name: "Variations ",
      selector: (row) => row.VariationCount,
    },

    {
      name: "Options",
      selector: (row) => (
        <div>
          {permissions.PublishProduct === true ? (
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={() => TooglePublishProduct(row.ProductId)}
            >
              {row.IsPublished ? <BiHide /> : <BiShowAlt />}{" "}
            </button>
          ) : null}
          {permissions.ManageProduct === true ? (
            <NavLink
              to={"/Products/" + row.ProductId}
              className="btn btn-outline-warning btn-sm ms-2"
            >
              <BiEdit />
            </NavLink>
          ) : null}
          {/* {permissions.DeleteProduct === true ? (
            <button
              className="btn btn-outline-danger btn-sm ms-2"
              onClick={() => setIsOpenDelete(row.ProductId)}
            >
              <BiTrash />
            </button>
          ) : null}
          <Modal
            show={row.ProductId === isOpenDelete}
            onHide={() => setIsOpenDelete(0)}
          >
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the product named <b>{row.ProductName}</b>?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteProduct(row.ProductId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setIsOpenDelete(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal> */}
        </div>
      ),
    },
  ];
  const variationColumns = [
    {
      name: "ID",
      selector: (row) => row.ProductVariationId,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => (
        <img
          src={Variables.API_PUBLISHED + row.Image}
          style={{ width: "100px", padding: "5px" }}
          alt="SIS"
        />
      ),
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "Sku",
      selector: (row) => row.Sku,
    },
    {
      name: "Color",
      selector: (row) => row.Color,
    },
    {
      name: "Size",
      selector: (row) => row.Size,
    },
    {
      name: "Sale Price ",
      selector: (row) => row.SalePrice,
    },
    {
      name: "Regular Price ",
      selector: (row) => row.RegularPrice,
    },
  ];

  async function fetchProductVariations(e, row) {
    if (e === true) {
      const URL =
        Variables.API_URL +
        "Products/GetProductVariationsForDataTable/" +
        row.ProductId;
      const response = await fetch(URL, {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      });
      if (!response.ok) throw Error("Did Not Receive expected data");
      const listItems = await response.json();
      var array = [...listProducts];
      array.forEach((element, i) => {
        if (element.ProductId === row.ProductId) {
          delete array[i].Variations;
          array[i].Variations = listItems;
        }
      });
      setFilteredList(array);
      setProducts(array);
    }
  }
  const ExpandedComponent = ({ data }) => (
    <DataTable
      className="productList"
      columns={variationColumns}
      data={data.Variations}
    />
  );

  // async function DeleteProduct(id) {
  //   let response = await fetch(
  //     Variables.API_URL + "Products/DeleteProduct/" + id,
  //     {
  //       method: "Delete",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": Variables.API_URL,
  //       },
  //     }
  //   );
  //   let result = await response.json();
  //   if (response.status === 200) {
  //     toast.success(result);
  //     GetProducts();
  //   } else {
  //     toast.error("Please check with administrator");
  //   }
  // }

  const ExportExcel = () => {
    axios.post(Variables.API_URL + "ExportExcel").then((response) => {
      window.open(Variables.Excel_URL + response.data);
    });
  };

  const handleSearchByProductName = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = listProducts;
    } else {
      result = listProducts.filter((child) =>
        child.ProductName.toLowerCase().includes(value)
      );
      if (result.length === 0) {
        result = listProducts;
      }
    }

    setFilteredList(result);
  };

  const handleSearchBySku = async () => {
    let value = searchbysku.toLowerCase();
    let result = [];
    if (value === "") {
      result = listProducts;
    } else {
      let response = await fetch(
        Variables.API_URL + "Products/SearchBySku/" + value,
        {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
        }
      );
      result = await response.json();
    }
    setIsOpenSkuSearch(false);
    setFilteredList(result);
  };

  async function TooglePublishProduct(id) {
    let response = await fetch(
      Variables.API_URL + "Products/TooglePublishProduct/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetProducts();
    } else {
      toast.error(result);
    }
  }

  async function GetProducts() {
    const URL = Variables.API_URL + "Products/GetAllProducts";
    const response = await fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
    });
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setProducts(listItems);
    setFilteredList(listItems);
  }

  //#region Use Effect
  useEffect(() => {
    GetProducts();
  }, []);

  //#endregion Use Effect
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Products</h2>
            <ul className="portalPath">
              <li>
                Home <AiOutlineRight />
              </li>
              <li>List Products</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-lg-6">
                    <h3>List Products</h3>
                  </div>

                  <div
                    className="col-lg-6 text-right"
                  >
                    {permissions.ManageProduct === true ? (
                      <NavLink to="/Products/0" className="btn btn-primary">
                        Add Product
                      </NavLink>
                    ) : null}
                  </div>
                </div>
              </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-12 text-end">
                    {permissions.Products === true ? (
                      <button
                        onClick={() => ExportExcel()}
                        className="btn btn-primary"
                      >
                        Export Excel
                      </button>
                    ) : null}
                    {permissions.ManageProduct === true ? (
                      <NavLink to="/Products/0" className="btn btn-primary">
                        Add Product
                      </NavLink>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-4 tblSearchBar position-relative">
                    <span>
                      <MdManageSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search By Product Name"
                      className="form-control form-control-sm"
                      onChange={(e) => handleSearchByProductName(e)}
                    ></input>
                  </div>
                  <div className="col-4"></div>
                  <div className="col-2 text-end tblSearchBar position-relative">
                    <span>
                      <MdManageSearch />
                    </span>
                    <button
                      placeholder="Search By SKU"
                      className="form-control form-control-sm"
                      onClick={() => setIsOpenSkuSearch(true)}
                    >
                      Search by sku
                    </button>

                    <Modal
                      show={isOpenSkuSearch}
                      onHide={() => {
                        setIsOpenSkuSearch(0);
                        setSearchbysku("");
                      }}
                    >
                      <Modal.Header>
                        <Modal.Title>Search By Sku</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container">
                          <div className="row">
                            <input
                              placeholder="Search By SKU"
                              className="form-control form-control-sm"
                              onChange={(e) => setSearchbysku(e.target.value)}
                            ></input>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="container">
                          <div className="row">
                            <div className="col-8"></div>
                            <div className="col-2">
                              <Button
                                variant="primary"
                                className="form-control"
                                onClick={() => handleSearchBySku()}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="col-2 text-end tblSearchBar position-relative">
                    <span>
                      <AiOutlineClear />
                    </span>
                    <button
                      placeholder="Search By SKU"
                      className="form-control form-control-sm"
                      onClick={() => setFilteredList(listProducts)}
                    >
                      Clear Search
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row" style={{ margin: "10px" }}>
                      {show === true ? (
                        <>
                          {permissions.SetInStock && (
                            <div className="col-2">
                              <label>
                                <button
                                  className="btn btn-primary"
                                  onClick={handleInStock}
                                >
                                  {" "}
                                  In Stock <BiStore />
                                </button>{" "}
                              </label>
                            </div>
                          )}
                          {permissions.SetOutOfStock && (
                            <div className="col-2">
                              <label>
                                {" "}
                                <button
                                  className="btn btn-primary"
                                  onClick={handleOutOfStock}
                                >
                                  {" "}
                                  Out of Stock <FaStoreSlash />
                                </button>{" "}
                              </label>
                            </div>
                          )}
                          {permissions.SetOnBackorder && (
                            <div className="col-2">
                              <label>
                                <button
                                  className="btn btn-primary"
                                  onClick={handleOnBackorder}
                                >
                                  On Backorder <BiStore />
                                </button>{" "}
                              </label>
                            </div>
                          )}

                          {permissions.PublishProduct && (
                            <>
                              <div className="col-2">
                                <label>
                                  <button
                                    className="btn btn-primary"
                                    onClick={handlePublishAll}
                                  >
                                    {" "}
                                    Publish <BiEdit />
                                  </button>{" "}
                                </label>
                              </div>
                              <div className="col-2">
                                {" "}
                                <label>
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleUnPublishAll}
                                  >
                                    {" "}
                                    UnPublish <BiEdit />
                                  </button>{" "}
                                </label>
                              </div>
                            </>
                          )}
                          <div className="col-2">
                            {permissions.DeleteProduct === true ? (
                              <label>
                                {" "}
                                <button
                                  className="btn btn-danger"
                                  onClick={() => setIsOpenDeleteAll(true)}
                                >
                                  Delete <BiTrash />
                                </button>{" "}
                              </label>
                            ) : null}
                            <Modal
                              show={isOpenDeleteAll}
                              onHide={() => setIsOpenDeleteAll(0)}
                            >
                              <Modal.Header>
                                <Modal.Title>Delete Request</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="container">
                                  <div className="row">
                                    <p>
                                      Are you sure you want to
                                      <b> Delete </b> all selected{" "}
                                      <b> Products</b> ?
                                    </p>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-8"></div>
                                    <div className="col-2">
                                      <Button
                                        variant="primary"
                                        className="form-control"
                                        onClick={() => handleDeleteProducts()}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                    <div className="col-2">
                                      <Button
                                        variant="secondary"
                                        className="form-control"
                                        onClick={() => setIsOpenDeleteAll(0)}
                                      >
                                        No
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 productList">
                    <div className="portalTable">
                      <DataTable
                        columns={columns}
                        data={filteredList}
                        pagination
                        expandableRows={true}
                        expandableRowsComponent={ExpandedComponent}
                        onRowExpandToggled={(e, row) =>
                          fetchProductVariations(e, row)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};
export default ListProducts;
