import React, { useState } from "react";
import { Input, Button } from "reactstrap";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../Variations/Variations";
import { useNavigate, Link } from "react-router-dom";
import Util from "../Classes/Util";
import logo from '../Images/pinkLogo.png'

async function loginUser(credentials) {
  const URL = Variables.API_URL + "User/UserLogin";
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });
}

function Login({ setToken, isAuthenticated, setIsAuthenticated }) {

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);

  const navigate = useNavigate();

  //handle email change
  const handleEmail = (e) => {
    const { name, value } = e.target;
    if (validator.isEmail(value)) {
      setEmailFormatError(false);
    }
    if (value === "") {
      setEmailRequiredError(true);
      setEmailFormatError(false);
    } else {
      setEmailRequiredError(false);
    }
    setEmail(value);
  };

  //handle password change
  const handlePassword = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setPasswordRequiredError(true);
    } else {
      setPasswordRequiredError(false);
    }
    setPassword(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (email == null || email == "") {
      setEmailRequiredError(true);
      hasError = true;
    }
    // else if (!validator.isEmail(email)) {
    //   setEmailFormatError(true);
    //   hasError = true;
    // }
    if (password == null || password == "") {
      setPasswordRequiredError(true);
      hasError = true;
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  //   function onRecaptchaChange() {
  //     setisVerified(true);
  //   }

  ////////////////////////////////////////////////////////////////

  const Login = async (e) => {
    // debugger
    var valid = IsValid();
    if (valid === true) {
      //fetch
      let response = await fetch(Variables.API_URL + "User/UserLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
        body: JSON.stringify({
          Email: email,
          Password: password,
        }),
      });
      let result = await response.json();
      //case of success
      if (response.status === 200) {
        localStorage.setItem("Email", Util.encrypt(result.Email));
        localStorage.setItem("LoginToken", Util.encrypt(result.LoginToken));
        localStorage.setItem("Permissions", Util.encrypt(result.Permissions));
        localStorage.setItem("UserName", Util.encrypt(result.UserName));
        localStorage.setItem("UserId", Util.encrypt(result.UserId));

        setIsAuthenticated(true);
        navigate("/")
      } else if (response.status === 401) {
        toast.error(result);
      } else if (response.status === 402) {
        localStorage.setItem("Email", Util.encrypt(email));
        localStorage.setItem("Password", Util.encrypt(password));
        navigate("/ResetPassword");
      } else {
        toast.error(result);
      }
    }
  };

  return (
    <div className="container-fluid LoginBackground">
      <div className="row">
        <div className="col-12">
          <div className="loginBox emounAya">
            <div className="row">
              <div className="col-12 text-center">
                <img src={logo} alt="SIS Brand" className="mb-2 logo" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="mb-4"> Login </h1>
              </div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12">
                  <input
                    id="Email"
                    name="Email"
                    placeholder="Email"
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={handleEmail}
                  />

                  {emailFormatError && (
                    <p className="color-red text-end">
                      Invalid Email/UserName !!
                    </p>
                  )}
                  {emailRequiredError && (
                    <p className="color-red text-end">
                      Email/username Required
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    id="Password"
                    name="Password"
                    placeholder="Password"
                    type="password"
                    className="form-control   "
                    value={password}
                    onChange={handlePassword}
                  />
                  {passwordRequiredError && (
                    <p className="color-red text-end">
                      Password Required
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-end">
                  <Link to="/ForgetPassword" >
                    Forget Password?
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit"
                    className="btn btn-primary d-block text-center text-uppercase mb-2 rounded-pill shadow-sm"
                    onClick={() => Login()}
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Login;
