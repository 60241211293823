import React, { useState, useEffect } from "react";
import { Variables } from "../../Variations/Variations";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { AiOutlineRight } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const ManageSizeChart = () => {
  var CryptoJS = require("crypto-js");
  const [attribute, setAttribute] = useState("");
  const [attributeId, setAttributeId] = useState(0);
  const [BrandName, setBrandName] = useState("");
  const [sizes, setSizes] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [isAppliedToCategories, setIsAppliedToCategories] = useState(false);
  const [isAppliedToAttributeValues, setIsAppliedToAttributeValues] =
    useState(false);
  const [assignedCategories, setAssignedCategories] = useState([]);
  const [assignedAttributeValues, setAssignedAttributeValues] = useState([]);
  const url = useParams();
  const navigate = useNavigate();
  const [brandId, setBrandId] = useState(0);
  const [notes, setNotes] = useState("");
  const [sizingAndFitGuide,setSizingAndFitGuide]=useState("");
  const MeasurementChange = (e, sindex, mindex) => {

    let SizeID = sizes[sindex].AttributeValueId;
    let MeasurementID = measurements[mindex].MeasurementId;
    var newSelection = [...selectedSizes];
    const Index = newSelection.findIndex((chart) => {
      return (
        chart.SizeId === SizeID &&
        chart.MeasurementId === MeasurementID
      );
    });



    if (Index === -1) {
      newSelection.push({
        SizeId: SizeID,
        MeasurementId: MeasurementID,
        SizeChartValue: e.target.value,
      });
    } else {
      if (e.target.value !== "") {
        newSelection[Index].SizeId = SizeID;
        newSelection[Index].MeasurementId = MeasurementID;
        newSelection[Index].SizeChartValue = e.target.value;
      }
      else {
        newSelection.splice(Index, 1)
      }
    }
    setSelectedSizes(newSelection);
  };
  const InsertSizeChart = async () => {
    if (url.brandid !== 0) {
      if (isAppliedToCategories && assignedCategories.length === 0) {
        toast.error("Categories must be Assigned");
      } else if (
        isAppliedToAttributeValues &&
        assignedAttributeValues.length === 0
      ) {
        toast.error("Attribute must be Assigned");
      } else if (
        selectedSizes.length < measurements.length
      ) {

        toast.error("Fill At least One Measurement Value");
      } else {
        var sizeCharts = [];
        var sizeChartValues = [];

     if (isAppliedToAttributeValues) {
          assignedAttributeValues.forEach((attribute) => {
            sizeCharts.push({
              categoryId: 1,
              AttributeValueId: attribute.value,
              isAppliedToAttribute: true,
            });
          });
        } else {
          sizeCharts.push({
            categoryId: 1,
            AttributeValueId: 1,
            isAppliedToAttribute: false,
          });
        }

        selectedSizes.forEach((size) => {
          sizeChartValues.push({
            sizeId: size.SizeId,
            measurementId: parseInt(size.MeasurementId),
            sizeChartValue: size.SizeChartValue,
          });
        });

        let response = await fetch(
          Variables.API_URL + "SizeChart/UpdateRelatedSizeChart",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.API_URL,
            },
            body: JSON.stringify({
                brandId: url.brandid,
                notes: notes,
                isUniversal: false,
                sizechartValues: sizeChartValues,
                sizeCharts: sizeCharts,
                SizingAndFitGuide:sizingAndFitGuide
              
              
            }),
          }
        );
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          response = await response.json();
          toast.success("Successfully added");
          navigate("/size-chart");
        }
      }
    } else {
      toast.error("Brand is required");
    }
  };
  const UpdateSizeChart = async () => {
    if (url.brandid !== 0) {
     if (
        isAppliedToAttributeValues &&
        assignedAttributeValues.length === 0
      ) {
        toast.error("Attribute must be Assigned");
      } else if (
        selectedSizes.length < measurements.length
      ) {

        toast.error("Fill At least One Measurement Value");
      } else {
        var sizeCharts = [];
        var sizeChartValues = [];

  if (isAppliedToAttributeValues) {
          assignedAttributeValues.forEach((attribute) => {
            sizeCharts.push({
              CategoryId: 1,
              AttributeValueId: attribute.value,
              isAppliedToAttribute: true,
            });
          });
        } else {
          sizeCharts.push({
            CategoryId: 1,
            AttributeValueId: 1,
            isAppliedToAttribute: false,
          });
        }

        selectedSizes.forEach((size) => {
          sizeChartValues.push({
            sizeId: size.SizeId,
            measurementId: parseInt(size.MeasurementId),
            sizeChartValue: size.SizeChartValue,
          });
        });

        let response = await fetch(
          Variables.API_URL + "SizeChart/UpdateRelatedSizeChart",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.API_URL,
            },
            body: JSON.stringify({
              BrandId: url.brandid,
              IsUniversal: false,
              SizeCharts: sizeCharts,
              SizechartValues: sizeChartValues,
              Notes: notes,
              SizingAndFitGuide:sizingAndFitGuide
            }),
          }
        );
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          response = await response.json();
          toast.success("Successfully updated");
          navigate("/size-chart");
        }
      }
    } else {
      toast.error("Brand is required")
    }
  }
  const GetSizeChart = async () => {
    let response, result;
    if (parseInt(url.sizechartid) !== 0) {
      response = await fetch(
        Variables.API_URL +
        "SizeChart/GetSizeChartDetailsById/" +
        url.sizechartid
      );
      result = await response.json();
      setAttribute(result.ValueName);
      setAttributeId(result.AttributeValueId);
      setSelectedSizes(result.SizeChartValues);
      setIsAppliedToAttributeValues(result.IsAppliedToAttribute)
      if(result.IsAppliedToAttribute){
        let array = [];
        result.AttributeValue.forEach((attribute) => {
          attribute.AttributeValueModel.forEach((value) => {
            array.push({
              AttributeValueID: value.AttributeValueID,
              ValueName: attribute.AttributeName + " - " + value.ValueName,
            });
          });
        });
        array = array.map(({ AttributeValueID: value, ValueName: label }) => ({
          value,
          label,
        }));
        setAssignedAttributeValues(array);
      }
      setBrandId(result.BrandId);
      setNotes(result.Notes);
      setSizingAndFitGuide(result.SizingAndFitGuide);
    }

    response = await fetch(
      Variables.API_URL + "Brand/GetBrand/" + url.brandid
    );
    result = await response.json();
    setBrandName(result.Brand1);

    response = await fetch(
      Variables.API_URL +
      "SizeChart/GetSpecificAssignedSizes/Brand/" +
      url.brandid
    );
    result = await response.json();
    setSizes(result);

    response = await fetch(
      Variables.API_URL +
      "SizeChart/GetSpecificAssignedMeasurements/Brand/" +
      url.brandid
    );
    result = await response.json();
    setMeasurements(result);


    response = await fetch(
      Variables.API_URL + "SizeChart/GetAttributesWithValues"
    );
    result = await response.json();
    let array = [];
    result.forEach((attribute) => {
      attribute.AttributeValueModel.forEach((value) => {
        array.push({
          AttributeValueID: value.AttributeValueID,
          ValueName: attribute.AttributeName + " - " + value.ValueName,
        });
      });
    });
    array = array.map(({ AttributeValueID: value, ValueName: label }) => ({
      value,
      label,
    }));
    setAttributeValues(array);
  };

  useEffect(() => {
    GetSizeChart();
  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Size Chart: {BrandName}{" "}
              {attribute !== "" && " - " + attribute}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to={
                parseInt(url.sizechartid) === 0
                  ? "/universal-size-chart/category"
                  : "/size-chart"
              }>Size Chart <AiOutlineRight /></Link></li>
              <li>Manage Size Chart</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h5 className="card-title">
                      {BrandName}{" "}
                      {attribute !== "" && " - " + attribute}
                    </h5>
                  </div>
                  <div className="col-6 text-right">
                    <NavLink
                      className="btn btn-secondary"
                      to={
                        parseInt(url.sizechartid) === 0
                          ? "/universal-size-chart/category"
                          : "/size-chart"
                      }
                    >
                      Back
                    </NavLink>
                    <Button
                      variant="primary"
                      className="ms-2"
                      onClick={
                        parseInt(url.sizechartid) === 0
                          ? InsertSizeChart
                          : UpdateSizeChart
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>

              <div className="card-body">
                {measurements.length > 0 && sizes.length > 0 ? (
                  <div className="pe-4 ps-4">
                   
         
                    <div className="form-group row">
                      <div className="form-check col-3 pl-38">
                        <label
                          className="form-check-label"
                          htmlFor="IsAppliedToCategories"
                        >
                          Applied To Attribute Values
                        </label>
                        <input
                          type="checkbox"
                          id="IsAppliedToAttributeValues"
                          className="form-check-input"
                          defaultValue={isAppliedToAttributeValues}
                          checked={isAppliedToAttributeValues}
                          onChange={(e) => {
                            setIsAppliedToAttributeValues(
                              e.target.value === "true" ? false : true
                            );
                            setAssignedAttributeValues([]);
                          }}
                        />
                      </div>
                      <div className="col-3">
                        {isAppliedToAttributeValues && (
                          <Select
                            isMulti
                            value={assignedAttributeValues}
                            options={attributeValues}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#FAFAFA",
                                primary: "#092a29",
                              },
                            })}
                            name="AttributeValue"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => setAssignedAttributeValues(e)}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <table className="size-table">
                        <thead>
                          <tr>
                            <th>Size</th>
                            {measurements.map((measurement) => {
                              return (
                                <th
                                  className="table-header"
                                  key={measurement.MeasurementId}
                                >
                                  {measurement.MeasurementValue}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        {sizes.map((size, sindex) => {
                          return (
                            <tr key={sindex}>
                              <th className="cell-width">
                                {size.AttributeValueName}
                              </th>
                              {measurements.map((measurement, mindex) => {
                                return (
                                  <th key={mindex}>
                                    {selectedSizes.some(
                                      (selected) =>
                                        selected.SizeId ===
                                        size.AttributeValueId &&
                                        selected.MeasurementId ===
                                        measurement.MeasurementId
                                    ) ? (
                                      <input
                                        type="text"
                                        id="sizechart"
                                        className="form-control searchWrapper"
                                        required={true}
                                        value={
                                          selectedSizes.filter(
                                            (selected) =>
                                              selected.SizeId ===
                                              size.AttributeValueId &&
                                              selected.MeasurementId ===
                                              measurement.MeasurementId
                                          )[0].SizeChartValue
                                        }
                                        onChange={(e) =>
                                          MeasurementChange(e, sindex, mindex)
                                        }
                                      ></input>
                                    ) : (
                                      <input
                                        type="text"
                                        id="sizechart"
                                        className="form-control searchWrapper"
                                        required={true}
                                        value=""
                                        onChange={(e) =>
                                          MeasurementChange(e, sindex, mindex)
                                        }
                                      ></input>
                                    )}
                                  </th>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <label className="mb-2"> Notes </label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <label className="mb-2"> Sizing And Fit Guide </label>
                        <CKEditor
                                                    editor={ClassicEditor}
                                                    data={sizingAndFitGuide}
                                                    value={sizingAndFitGuide}
                                                    onChange={(event, editor) => {
                                                      const data =
                                                        editor.getData();
                                                      setSizingAndFitGuide(data);
                                                    }}
                                                  />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      {" "}
                      There is no assigned measurements or sizes to this
                      category{" "}
                      <NavLink
                        to={"/Brand/" + url.brandid}
                        className="icon"
                      >
                        {" "}
                        Click here{" "}
                      </NavLink>
                    </div>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSizeChart;
